<template>
  <div>
    <section class="wrapper login-content common-height common-content">
      <div class="wrapper title-block">
        <h2 class="common-title">
          DXサービス
        </h2>
      </div>
      <div class="owner-btns-block" v-if="isOwner || isManager || isFacility">
        <ul class="dxsservice-btns-list">
          <li>
            <router-link to="/dx-services/dx-care-home-edit">
              <div class="icon-img box-left">
                <picture>
                  <source
                    :srcset="require('../assets/img/dx-service/carehomes.avif')"
                    type="image/avif"
                  />
                  <source
                    :srcset="require('../assets/img/dx-service/carehomes.webp')"
                    type="image/webp"
                  />
                  <img
                    :src="require('../assets/img/dx-service/carehomes.png')"
                    alt="施設情報"
                  />
                </picture>
              </div>
            </router-link>
          </li>
          <li>
            <router-link to="/dx-services/dx-redidents-list">
              <div class="icon-img box-right">
                <picture>
                  <source
                    :srcset="require('../assets/img/dx-service/residents.avif')"
                    type="image/avif"
                  />
                  <source
                    :srcset="require('../assets/img/dx-service/residents.webp')"
                    type="image/webp"
                  />
                  <img
                    :src="require('../assets/img/dx-service/residents.png')"
                    alt="入居者情報"
                  />
                </picture>
              </div>
            </router-link>
          </li>
        </ul>
        <div v-if="isOwner" class="dx-for-owner">
          <v-btn
            to="/dx-services/owner-dx-services/"
            block
            color="#009933"
            elevation="3"
            x-large
            >オーナー様用</v-btn
          >
        </div>
        <div v-if="isManager" class="dx-for-owner">
          <v-btn
            to="/dx-services/owner-dx-services/"
            block
            color="#009933"
            elevation="3"
            x-large
            >エリアマネージャー様用</v-btn
          >
        </div>
        <div v-if="isFacility" class="dx-for-owner">
          <v-btn
            to="/dx-services/owner-dx-services/"
            block
            color="#009933"
            elevation="3"
            x-large
            >施設管理者様用</v-btn
          >
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Common from '@/plugin/Common'

export default {
  mixins: [Common],
  name: 'DxService',
  data: function() {
    return {
      isOwner: false,
      isManager: false,
      isFacility: false
    }
  },
  created() {
    try {
      if (this.$store.getters.isAuthenticated) {
        const roles = this.$store.state.user['custom:role'].split(',')
        for (let r of roles) {
          var role = r.split(':')
          switch (role[0]) {
            case 'R01':
              this.isOwner = true
              break
            case 'R10':
              this.isOwner = true
              break
            case 'R11':
              this.isManager = true
              break
            case 'R12':
              this.isFacility = true
              break
            default:
          }
        }
      }
    } catch (error) {
      console.error(error)
    }
    setTimeout(this.loadChatbotGeneral, 1000)
  }
}
</script>

<style lang="scss"></style>
