import Vue from 'vue'
import VueRouter from 'vue-router'
import cognito from '../cognito'
import store from '../store'
import MAndA from '../views/OwnerService/MAndA'
import NonMemberMAndA from '../views/OwnerService/NonMemberMAndA'
import MedicareMall from '../views/MedicareMall'
import SDGs from '../views/SDGs'
import IntroduceResidentService from '../views/IntroduceResidentService'
import MoveinResidentConsultList from '../views/IntroduceServices/MoveinResidentConsultList'
import VacantInfoRequest from '../views/IntroduceServices/VacantInfoRequest'
import DxService from '../views/DxService'
import DxCareHomeEdit from '../views/dxservices/DxCareHomeEdit'
import DxResidentsList from '../views/dxservices/DxResidentsList'
import DxRedidentsEdit from '../views/dxservices/DxRedidentsEdit'
import DxServiceForOwner from '../views/dxservices/DxServiceForOwner'
import MyPage from '../views/MyPage'
import MyData from '../views/MyPage/MyData'
import NotFound from '../views/NotFound'
import ComingSoon from '../views/ComingSoon'
import NewsList from '../views/NewsList'
import NewsDetail from '../views/NewsDetail'
import NewsList2 from '../views/NewsList2'
import NewsDetail2 from '../views/NewsDetail2'
import PrivacyPolicy from '../views/PrivacyPolicy'
import RecommendedSystemRequirements from '../views/RecommendedSystemRequirements'
import Disclaimer from '../views/Disclaimer'
import Agreement from '../views/Agreement'
import TradeLaw from '../views/TradeLaw'

Vue.use(VueRouter)

const signout = (to, from, next) => {
  cognito.signout()
  next({
    ...(screen.width <= 500 ? { path: '/mobile' } : { path: '/' })
  })
}

const routes = [
  {
    path: '/outlet_login',
    name: 'OutletLogin',
    beforeEnter() {
      window.location = process.env.VUE_APP_OUTLET_LOGIN_URL
    }
  },
  {
    path: '/meetingmall_login',
    name: 'MeetingmallLogin',
    beforeEnter() {
      window.location = process.env.VUE_APP_MEETINGMALL_LOGIN_URL
    }
  },
  {
    path: '/shop_login',
    name: 'ShopLogin',
    beforeEnter() {
      window.location = process.env.VUE_APP_SHOP_LOGIN_URL
    }
  },
  {
    path: '/medicare-mall/',
    name: 'MedicareMall',
    component: MedicareMall,
    meta: {
      notRequiredAuth: true
    }
  },
  {
    path: '/m-and-a/',
    name: 'MAndA',
    component: MAndA,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: '/non-member-m-and-a/',
    name: 'NonMemberMAndA',
    component: NonMemberMAndA,
    meta: {
      notRequiredAuth: true
    }
  },
  {
    path: '/sdgs/',
    name: 'SDGs',
    component: SDGs,
    meta: {
      notRequiredAuth: true
    }
  },
  {
    path: '/introduce-resident-service/',
    name: 'IntroduceResidentService',
    component: IntroduceResidentService,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: '/introduce-services/movein-resident-consult',
    name: 'MoveinResidentConsultList',
    component: MoveinResidentConsultList,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: '/introduce-services/vacant-info-request',
    name: 'VacantInfoRequest',
    component: VacantInfoRequest,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: '/dx-services',
    name: 'DxService',
    component: DxService,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: '/dx-services/dx-care-home-edit',
    name: 'DxCareHomeEdit',
    component: DxCareHomeEdit,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: '/dx-services/dx-redidents-list',
    name: 'DxResidentsList',
    component: DxResidentsList,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: '/dx-services/dx-redidents-edit/:residentid',
    name: 'DxRedidentsEdit',
    component: DxRedidentsEdit,
    props: (routes) => ({
      residentid: Number(routes.params.residentid)
    }),
    meta: {
      requiredAuth: true
    }
  },
  {
    path: '/dx-services/owner-dx-services/',
    name: 'DxServiceForOwner',
    component: DxServiceForOwner,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: '/mypage',
    name: 'MyPage',
    component: MyPage,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: '/mypage/mydata',
    name: 'MyData',
    component: MyData,
    meta: {
      requiredAuth: true
    }
  },
  {
    path: '/comingsoon',
    name: 'ComingSoon',
    component: ComingSoon,
    meta: {
      notRequiredAuth: true
    }
  },
  {
    path: '/privacy-policy/',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
    meta: {
      notRequiredAuth: true
    }
  },
  {
    path: '/recommended-system-requirements/',
    name: 'RecommendedSystemRequirements',
    component: RecommendedSystemRequirements,
    meta: {
      notRequiredAuth: true
    }
  },
  {
    path: '/disclaimer/',
    name: 'Disclaimer',
    component: Disclaimer,
    meta: {
      notRequiredAuth: true
    }
  },
  {
    path: '/agreement/',
    name: 'Agreement',
    component: Agreement,
    meta: {
      notRequiredAuth: true
    }
  },
  {
    path: '/tradelaw',
    name: 'TradeLaw',
    component: TradeLaw,
    meta: {
      notRequiredAuth: true
    }
  },
  {
    path: '/signout',
    beforeEnter: signout
  },
  {
    path: '*',
    component: NotFound
  },
  {
    path: '/news-list/',
    name: 'NewsList',
    component: NewsList,
    meta: {
      notRequiredAuth: true
    }
  },
  {
    path: '/news-detail/:id',
    name: 'NewsDetail',
    component: NewsDetail,
    props: (routes) => ({
      id: Number(routes.params.id)
    }),
    meta: {
      notRequiredAuth: true
    }
  },
  {
    path: '/notice-list/',
    name: 'NoticeList',
    component: NewsList2,
    meta: {
      notRequiredAuth: true
    }
  },
  {
    path: '/notice-detail/:id',
    name: 'NoticeDetail',
    component: NewsDetail2,
    props: (routes) => ({
      id: Number(routes.params.id)
    }),
    meta: {
      notRequiredAuth: true
    }
  },

  // new pc routes
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/New/Home.vue'),
    meta: {
      requiredAuth: false
    }
  },

  // new auth routes
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/auth/Register.vue'),
    meta: {
      footer: false,
      header: false,
      redirect: false,
      notRequiredAuth: true
    }
  },
  {
    path: '/complete',
    name: 'Complete',
    component: () => import('@/views/auth/Complete.vue'),
    meta: {
      footer: false,
      header: false,
      redirect: false,
      notRequiredAuth: true
    }
  },
  {
    path: '/validation-complete',
    name: 'ValidationComplete',
    component: () => import('@/views/auth/ValidationComplete.vue'),
    meta: {
      footer: false,
      header: false,
      redirect: false,
      notRequiredAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      footer: false,
      header: false,
      redirect: false,
      notRequiredAuth: true
    }
  },
  {
    path: '/forgot-pass',
    name: 'ForgotPass',
    component: () => import('@/views/auth/ForgotPass.vue'),
    meta: {
      footer: false,
      header: false,
      redirect: false,
      notRequiredAuth: true
    }
  },
  {
    path: '/reset-pass',
    name: 'ResetPass',
    component: () => import('@/views/auth/ResetPass.vue'),
    meta: {
      footer: false,
      header: false,
      redirect: false,
      notRequiredAuth: true
    }
  },

  // mobile routes
  {
    path: '/mobile',
    name: 'Mobile',
    component: () => import('@/mobile/views/index.vue'),
    meta: {
      footer: false,
      header: false
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    /* if (savedPosition) {
      return savedPosition
    } */
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth'
      }
    }
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiredAuth)) {
    if (!store.getters.loggedIn) {
      next({
        path: '/login',
        query: {
          redirect: to.fullPath,
          message: true
        }
      })
      return // 早期リターンして処理を終えます
    }
  }
  next() // ナビゲーションを続行します
})

router.beforeResolve(async (to, from, next) => {
  // setting footer state
  if (to.matched.some((record) => record.meta.footer === false)) {
    store.commit('setFooter', false)
  } else {
    store.commit('setFooter', true)
  }

  // setting header state
  if (to.matched.some((record) => record.meta.header === false)) {
    store.commit('setAppHeader', false)
  } else {
    store.commit('setAppHeader', true)
  }

  // setting desktop to mobile redirect state
  if (to.matched.some((record) => record.meta.redirect === false)) {
    store.commit('setRedirect', false)
  } else if (
    to.matched.some((record) => !record.meta.hasOwnProperty.call('redirect'))
  ) {
    store.commit('setRedirect', true)
  }

  // Get signin session.
  cognito
    .isAuthenticated()
    .then((session) => {
      // eslint-disable-line
      const token = session.idToken.jwtToken
      // get attribute.
      cognito
        .getAttribute()
        .then((result) => {
          var user = {}
          for (var v of result) {
            user[v.getName()] = v.getValue()
          }
          user['token'] = token
          // セッションにDynamoDBの情報がある場合はカスタム属性のみ書き換え
          if (
            session.idToken.payload['custom:address_city'] &&
            user['custom:address_city'] !=
              session.idToken.payload['custom:address_city']
          ) {
            user['custom:address_city'] =
              session.idToken.payload['custom:address_city']
          }
          if (
            session.idToken.payload['custom:address_prefecture'] &&
            user['custom:address_prefecture'] !=
              session.idToken.payload['custom:address_prefecture']
          ) {
            user['custom:address_prefecture'] =
              session.idToken.payload['custom:address_prefecture']
          }
          if (
            session.idToken.payload['custom:address_street'] &&
            user['custom:address_street'] !=
              session.idToken.payload['custom:address_street']
          ) {
            user['custom:address_street'] =
              session.idToken.payload['custom:address_street']
          }
          if (
            session.idToken.payload['custom:age'] &&
            user['custom:age'] != session.idToken.payload['custom:age']
          ) {
            user['custom:age'] = session.idToken.payload['custom:age']
          }
          if (
            session.idToken.payload['custom:agencycode'] &&
            user['custom:agencycode'] !=
              session.idToken.payload['custom:agencycode']
          ) {
            user['custom:agencycode'] =
              session.idToken.payload['custom:agencycode']
          }
          if (
            session.idToken.payload['custom:company_name'] &&
            user['custom:company_name'] !=
              session.idToken.payload['custom:company_name']
          ) {
            user['custom:company_name'] =
              session.idToken.payload['custom:company_name']
          }
          if (
            session.idToken.payload['custom:contract_imm'] &&
            user['custom:contract_imm'] !=
              session.idToken.payload['custom:contract_imm']
          ) {
            user['custom:contract_imm'] =
              session.idToken.payload['custom:contract_imm']
          }
          if (
            session.idToken.payload['custom:contract_shop'] &&
            user['custom:contract_shop'] !=
              session.idToken.payload['custom:contract_shop']
          ) {
            user['custom:contract_shop'] =
              session.idToken.payload['custom:contract_shop']
          }
          if (
            session.idToken.payload['custom:facility_name'] &&
            user['custom:facility_name'] !=
              session.idToken.payload['custom:facility_name']
          ) {
            user['custom:facility_name'] =
              session.idToken.payload['custom:facility_name']
          }
          if (
            session.idToken.payload['custom:family_name_kana'] &&
            user['custom:family_name_kana'] !=
              session.idToken.payload['custom:family_name_kana']
          ) {
            user['custom:family_name_kana'] =
              session.idToken.payload['custom:family_name_kana']
          }
          if (
            session.idToken.payload['custom:facility_type'] &&
            user['custom:facility_type'] !=
              session.idToken.payload['custom:facility_type']
          ) {
            user['custom:facility_type'] =
              session.idToken.payload['custom:facility_type']
          }
          if (
            session.idToken.payload['custom:fax'] &&
            user['custom:fax'] != session.idToken.payload['custom:fax']
          ) {
            user['custom:fax'] = session.idToken.payload['custom:fax']
          }
          if (
            session.idToken.payload['custom:given_name_kana'] &&
            user['custom:given_name_kana'] !=
              session.idToken.payload['custom:given_name_kana']
          ) {
            user['custom:given_name_kana'] =
              session.idToken.payload['custom:given_name_kana']
          }
          if (
            session.idToken.payload['custom:job_name'] &&
            user['custom:job_name'] !=
              session.idToken.payload['custom:job_name']
          ) {
            user['custom:job_name'] = session.idToken.payload['custom:job_name']
          }
          if (
            session.idToken.payload['custom:main_industry'] &&
            user['custom:main_industry'] !=
              session.idToken.payload['custom:main_industry']
          ) {
            user['custom:main_industry'] =
              session.idToken.payload['custom:main_industry']
          }
          if (
            session.idToken.payload['custom:parent_agencycode'] &&
            user['custom:parent_agencycode'] !=
              session.idToken.payload['custom:parent_agencycode']
          ) {
            user['custom:parent_agencycode'] =
              session.idToken.payload['custom:parent_agencycode']
          }
          if (
            session.idToken.payload['custom:postal_code'] &&
            user['custom:postal_code'] !=
              session.idToken.payload['custom:postal_code']
          ) {
            user['custom:postal_code'] =
              session.idToken.payload['custom:postal_code']
          }
          if (
            session.idToken.payload['custom:role'] &&
            user['custom:role'] != session.idToken.payload['custom:role']
          ) {
            user['custom:role'] = session.idToken.payload['custom:role']
          }
          if (
            session.idToken.payload['custom:status'] &&
            user['custom:status'] != session.idToken.payload['custom:status']
          ) {
            user['custom:status'] = session.idToken.payload['custom:status']
          }
          store.commit('setUser', user)
        })
        .catch((error) => {
          store.commit('setUser', null)
          console.log(error)
          signout(to, from, next)
        })
      if (to.matched.some((record) => record.meta.notRequiredAuth)) {
        next()
      }
    })
    .catch((error) => {
      // eslint-disable-line
      store.commit('setUser', null)
      if (to.matched.some((record) => record.meta.requiredAuth)) {
        next({
          path: '/login',
          query: {
            redirect: to.fullPath
          }
        })
      }
    })
  next()
})

export default router
