<script>
const postal_code = require('jp-postalcode-lookup')

//TODO: 2023/06/30 disable bot
const disableChatbot = true

export default {
  methods: {
    commonHeight() {
      // commonで高さを揃えるjs
      const commons = document.getElementsByClassName('common-height')
      if (commons == undefined) {
        return
      }
      const fElement = document.getElementById('footer')
      const hElement = document.getElementById('header')
      if (fElement == undefined || hElement == undefined) {
        return
      }
      const h = window.innerHeight
      const fH = fElement.clientHeight
      const hH = hElement.clientHeight
      for (let i = 0; i < commons.length; i++) {
        commons[i].setAttribute('style', `min-height: ${h - hH - fH}px`)
      }
    },
    loadChatbotGeneral() {
      if (disableChatbot) {
        return
      }
      var chatPanelExists = !!document.getElementById('ai_chat_panel')
      var chatTagExists = !!document.getElementById('orange_bot_chat_icon')
      var scriptEl1 = document.createElement('script')
      var scriptEl2 = document.createElement('script')
      var publicScript = 'var ai_chat_account_id = 186'
      var memberScript = 'var ai_chat_account_id = 188'
      scriptEl2.setAttribute('id', 'orange_bot_chat_icon')
      scriptEl2.setAttribute('src', 'https://www.orangebot.jp/js/chat_ai.js')
      if (this.$store.getters.isAuthenticated) {
        scriptEl1.innerHTML = memberScript
      } else {
        scriptEl1.innerHTML = publicScript
      }
      if (!chatPanelExists && !chatTagExists) {
        document.body.appendChild(scriptEl1)
        document.body.appendChild(scriptEl2)
      } else if (!chatPanelExists && chatTagExists) {
        // ボット用ID未指定の時
      } else if (chatPanelExists && !chatTagExists) {
        // パネルだけある時
        document.getElementById('ai_chat_panel').remove()
      } else {
        // 両方あるとき
        var scriptTags = document.body.getElementsByTagName('script')
        var strings = ''
        var chatScriptTag = document.getElementById('orange_bot_chat_icon')
        var chatPanelTag = document.getElementById('ai_chat_panel')
        if (this.$store.getters.isAuthenticated) {
          // ログイン時
          for (let tag of scriptTags) {
            if (tag.hasAttribute('src')) {
              if (!tag.hasAttribute('id')) {
                continue
              }
            }
            strings = tag.innerHTML
            if (strings == publicScript) {
              // 未ログイン用のままの時
              tag.remove()
              chatScriptTag.remove()
              chatPanelTag.remove()
              document.body.appendChild(scriptEl1)
              document.body.appendChild(scriptEl2)
            }
          }
        } else {
          // ログアウト時
          for (let tag of scriptTags) {
            if (tag.hasAttribute('src')) {
              if (!tag.hasAttribute('id')) {
                continue
              }
            }
            strings = tag.innerHTML
            if (strings == memberScript) {
              tag.remove()
              chatScriptTag.remove()
              chatPanelTag.remove()
              document.body.appendChild(scriptEl1)
              document.body.appendChild(scriptEl2)
            }
          }
        }
      }
    },
    isEmpty(strObj) {
      if (strObj == undefined || strObj == null || strObj == '') {
        return true
      }
      return false
    },
    sleep(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms)
      })
    },
    getAddress(zipCode) {
      postal_code.get(zipCode, (address) => {
        if (address) {
          this.register.prefecture = address.prefecture
          this.register.city = address.city
          this.register.street = address.area
        }
      })
    },
    async getUserName() {
      if (!this.register.username) {
        alert('ユーザーIDを入力してください')
        return
      }
      this.userNotExists = null
      const username = this.register.username
      const apiEndpoint = process.env.VUE_APP_GETUSERNAME_API_URL
      try {
        const userNameParams = { username: username }
        const response = await this.$axios.post(apiEndpoint, userNameParams)
        if (response.data.userNotExists === true) {
          this.userNotExists = true
        } else if (response.data.userNotExists === false) {
          this.userNotExists = false
        } else {
          this.userNotExists = null
        }
      } catch (error) {
        console.error('Error checking username:', error)
        this.userNotExists = null
      }
    },
    isMobile() {
      const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
      //console.log('isMobile:' + isMobile)
      return isMobile
    },
    handleMobileTouch(event, callbackFunction) {
      /*
      console.log(
        'event:' +
          event +
          ' event:' +
          JSON.stringify(event) +
          ' event.type:' +
          event.type
      )
      console.log(event)
      */
      if (this.isMobile() && event.type === 'click') {
        //console.log('handleMobileTouch:click')
        // 実際のアクションをここで実行します。
        callbackFunction(event)
        event.preventDefault()
      } else if (!this.isMobile() && event.type === 'touchend') {
        //console.log('handleMobileTouch:touchend')
        event.preventDefault()
      } else {
        //console.log('handleMobileTouch:execute')
        // 実際のアクションをここで実行します。
        callbackFunction(event)
      }
    }
  }
}
</script>
