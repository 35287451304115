<template>
  <div :style="style" />
</template>

<script>
export default {
  name: 'HSpace',
  props: {
    space: {
      type: Number,
      default: 0
    }
  },

  computed: {
    style() {
      return {
        marginTop: `${this.space}px`,
        marginBottom: `${this.space}px`
      }
    }
  }
}
</script>
