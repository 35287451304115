<template>
  <v-app>
    <AppBar v-if="$store.state.isAppHeader" />
    <v-main>
      <router-view></router-view>
    </v-main>
    <AppFooter v-if="$store.state.isFooter" />
  </v-app>
</template>

<script>
export default {
  name: 'App',
  components: {
    AppBar: () => import('@/components/common/AppBar/index.vue'),
    Footer: () => import('@/mobile/cells/MobileFooter/index.vue'),
    AppFooter: () => import('@/components/common/AppFooter/index.vue')
  },
  data() {
    return {
      routeChanged: false
    }
  },
  metaInfo() {
    return {
      meta: [
        {
          vmid: 'viewport',
          name: 'viewport',
          content: ''
        }
      ]
    }
  },
  created() {
    setTimeout(() => {
      if (
        screen.width <= 500 &&
        !this.routeChanged &&
        this.$store.state.isRedirect
      ) {
        console.log(
          'screen.width',
          screen.width,
          ' redirect',
          this.$store.state.isRedirect
        )
        this.routeChanged = true
        this.$router.push({ path: '/mobile' })
      } else {
        console.log(
          'screen.width',
          screen.width,
          ' redirect',
          this.$store.state.isRedirect
        )
      }
    }, 600)
  }
}
</script>

<style lang="scss"></style>
