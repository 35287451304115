<template>
  <div>
    <v-app>
      <section
        class="dxservice-wrapper login-content common-height common-content"
      >
        <div class="wrapper title-block">
          <h2 class="common-title">
            入居者情報一覧
          </h2>
        </div>
        <div class="dxservices-block">
          <v-text-field
            v-model="search"
            label="任意検索"
            v-bind:appendIcon="icons.mdiMagnify"
          ></v-text-field>
          <v-btn @click="clickNew()" dark color="blue darken-3">新規</v-btn>
          <v-data-table
            :headers="residentsListHeaders"
            :items="residentsList"
            :items-per-page.sync="itemsPerPage"
            :page="page"
            :search="search"
            class="dx-residents-table"
            hide-default-footer
            no-results-text="入居者情報はありません"
            no-data-text="入居者情報はありません"
          >
            <template v-slot:item.action="{ item }">
              <v-btn @click="clickEdit(item)">編集</v-btn>
            </template>

            <template v-slot:footer>
              <v-row class="mt-2" align="center" justify="center">
                <span class="grey--text">表示件数</span>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      dark
                      text
                      color="primary"
                      class="ml-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ itemsPerPage }}
                      <v-icon>{{ icons.mdiChevronDown }}</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(number, index) in itemsPerPageArray"
                      :key="index"
                      @click="updateItemsPerPage(number)"
                    >
                      <v-list-item-title>{{ number }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <v-spacer></v-spacer>

                <span class="mr-4 grey--text">
                  {{ page }} / {{ numberOfPages }} ページ
                </span>
                <v-btn
                  fab
                  dark
                  color="blue darken-3"
                  class="mr-1"
                  @click="formerPage"
                >
                  <v-icon>{{ icons.mdiChevronLeft }}</v-icon>
                </v-btn>
                <v-btn
                  fab
                  dark
                  color="blue darken-3"
                  class="ml-1"
                  @click="nextPage"
                >
                  <v-icon>{{ icons.mdiChevronRight }}</v-icon>
                </v-btn>
              </v-row>
            </template>
          </v-data-table>
          <br />
          <router-link to="/dx-services">
            <v-btn color="primary">
              <v-icon left dark>
                {{ icons.mdiArrowLeftCircle }}
              </v-icon>
              戻る
            </v-btn>
          </router-link>
        </div>
      </section>
    </v-app>
  </div>
</template>

<script>
import Common from '@/plugin/Common'
import * as apiclient from '@/apiclient'

import {
  mdiDownloadCircle,
  mdiCloudUpload,
  mdiCheckUnderlineCircle,
  mdiCloseCircle,
  mdiExitToApp,
  mdiArrowLeftCircle,
  mdiMagnify,
  mdiArrowCollapseLeft,
  mdiArrowCollapseRight,
  mdiMinus,
  mdiPlus,
  mdiChevronDown,
  mdiChevronLeft,
  mdiChevronRight
} from '@mdi/js'

export default {
  mixins: [Common],
  name: 'DxResidentsList',
  created() {
    setTimeout(this.loadChatbotGeneral, 1000)
    this.getResidentsList()
  },
  data: function() {
    return {
      icons: {
        mdiCheckUnderlineCircle,
        mdiDownloadCircle,
        mdiCloudUpload,
        mdiCloseCircle,
        mdiExitToApp,
        mdiArrowLeftCircle,
        mdiMagnify,
        mdiArrowCollapseLeft,
        mdiArrowCollapseRight,
        mdiMinus,
        mdiPlus,
        mdiChevronDown,
        mdiChevronLeft,
        mdiChevronRight
      },
      search: '',
      itemsPerPageArray: [5, 20, 50, 100],
      filter: {},
      sortDesc: false,
      page: 1,
      itemsPerPage: 5,
      sortBy: 'name',
      footeProps: {
        showFirstLastPage: true,
        'items-per-page-options': [10, 20, 30, 40, 50]
      },
      residentsListHeaders: [
        { text: '施設名', value: 'care_home_name', sortable: false },
        { text: '入居者id', value: 'resident_id', sortable: true },
        { text: '氏名', value: 'complete_name', sortable: true },
        { text: '状況', value: 'status', sortable: true },
        { text: '収入区分', value: 'imcome_category', sortable: true },
        { text: '介護度', value: 'care_level', sortable: true },
        { text: '入居日（契約日）', value: 'move_in_date', sortable: true },
        { text: '退去日', value: 'move_out_date', sortable: true },
        { text: '編集', value: 'action', sortable: false }
      ],
      residentsList: []
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    numberOfPages() {
      return Math.ceil(this.residentsList.length / this.itemsPerPage)
    }
  },
  methods: {
    clickEdit(item) {
      this.$router.push({
        name: 'DxRedidentsEdit',
        params: { residentid: item.resident_id }
      })
    },
    clickNew() {
      this.$router.push({
        name: 'DxRedidentsEdit',
        params: { residentid: 0 }
      })
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1
    },
    updateItemsPerPage(number) {
      this.page = 1
      this.itemsPerPage = number
    },
    getResidentsList: function() {
      try {
        var params = {}
        apiclient.TsunaguwaDxCarehomesApi.getResidentsList((responseData) => {
          this.residentsList = responseData.payload.residents_list
          this.residentsList.forEach(
            (element) =>
              (element.complete_name =
                element.family_name + ' ' + element.first_name)
          )
        }, params)
      } catch (error) {
        throw error.response
      }
    }
  }
}
</script>

<style lang="scss"></style>
