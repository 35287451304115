export default {
  //Aws関連設定
  AWSConfig: {
    Region: process.env.VUE_APP_AWS_REGION,
    UserPoolId: process.env.VUE_APP_USER_POOL_ID,
    ClientId: process.env.VUE_APP_CLIENT_ID,
    IdentityPoolId: process.env.VUE_APP_IDENTITY_POOL_ID,
    CognitoCookieDomain:
      process.env.VUE_APP_TSW_CONFIG_USERINFO_COOKIE_DOMAIN === 'localhost'
        ? process.env.VUE_APP_TSW_CONFIG_USERINFO_COOKIE_DOMAIN
        : 'www' + process.env.VUE_APP_TSW_CONFIG_USERINFO_COOKIE_DOMAIN
  },
  //共通ツナグワ設定
  TsunaguwaConfig: {
    Common: {
      SecretKey: process.env.VUE_APP_TSW_CONFIG_SECRET_KEY
    },
    Cookies: {
      Secure: new Boolean(
        process.env.VUE_APP_TSW_CONFIG_COOKIE_SECURE
      ).valueOf()
    },
    //ユーザ情報クッキー設定
    UserInfoCookie: {
      Key: process.env.VUE_APP_TSW_CONFIG_USERINFO_COOKIE_KEY,
      Domain: process.env.VUE_APP_TSW_CONFIG_USERINFO_COOKIE_DOMAIN
    }
  }
}
