"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Tsunaguwa Api
 * Tsunaguwa Api
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: info@talllyho.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DxCarehomesApi = exports.DxCarehomesApiFactory = exports.DxCarehomesApiFp = exports.DxCarehomesApiAxiosParamCreator = exports.CommonApi = exports.CommonApiFactory = exports.CommonApiFp = exports.CommonApiAxiosParamCreator = exports.AuthApi = exports.AuthApiFactory = exports.AuthApiFp = exports.AuthApiAxiosParamCreator = exports.AdApi = exports.AdApiFactory = exports.AdApiFp = exports.AdApiAxiosParamCreator = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("./common");
// @ts-ignore
var base_1 = require("./base");
/**
 * AdApi - axios parameter creator
 * @export
 */
exports.AdApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary 広告リスト取得
         * @param {AdInfoRequest} [adInfoRequest] 広告リスト取得リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdList: function (adInfoRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/ad/list";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(adInfoRequest, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary 広告クリック情報登録
         * @param {AdClickRequest} [adClickRequest] 広告クリック登録リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerAdClick: function (adClickRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/ad/click";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(adClickRequest, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * AdApi - functional programming interface
 * @export
 */
exports.AdApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.AdApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary 広告リスト取得
         * @param {AdInfoRequest} [adInfoRequest] 広告リスト取得リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdList: function (adInfoRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getAdList(adInfoRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary 広告クリック情報登録
         * @param {AdClickRequest} [adClickRequest] 広告クリック登録リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerAdClick: function (adClickRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.registerAdClick(adClickRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * AdApi - factory interface
 * @export
 */
exports.AdApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.AdApiFp(configuration);
    return {
        /**
         *
         * @summary 広告リスト取得
         * @param {AdInfoRequest} [adInfoRequest] 広告リスト取得リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAdList: function (adInfoRequest, options) {
            return localVarFp.getAdList(adInfoRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary 広告クリック情報登録
         * @param {AdClickRequest} [adClickRequest] 広告クリック登録リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerAdClick: function (adClickRequest, options) {
            return localVarFp.registerAdClick(adClickRequest, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * AdApi - object-oriented interface
 * @export
 * @class AdApi
 * @extends {BaseAPI}
 */
var AdApi = /** @class */ (function (_super) {
    __extends(AdApi, _super);
    function AdApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary 広告リスト取得
     * @param {AdInfoRequest} [adInfoRequest] 広告リスト取得リクエスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdApi
     */
    AdApi.prototype.getAdList = function (adInfoRequest, options) {
        var _this = this;
        return exports.AdApiFp(this.configuration).getAdList(adInfoRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary 広告クリック情報登録
     * @param {AdClickRequest} [adClickRequest] 広告クリック登録リクエスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdApi
     */
    AdApi.prototype.registerAdClick = function (adClickRequest, options) {
        var _this = this;
        return exports.AdApiFp(this.configuration).registerAdClick(adClickRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return AdApi;
}(base_1.BaseAPI));
exports.AdApi = AdApi;
/**
 * AuthApi - axios parameter creator
 * @export
 */
exports.AuthApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary ログイン情報取得
         * @param {TsunaguwaLoginInfoRequest} tsunaguwaLoginInfoRequest ログイン情報取得リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoginInfo: function (tsunaguwaLoginInfoRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'tsunaguwaLoginInfoRequest' is not null or undefined
                    common_1.assertParamExists('getLoginInfo', 'tsunaguwaLoginInfoRequest', tsunaguwaLoginInfoRequest);
                    localVarPath = "/auth";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(tsunaguwaLoginInfoRequest, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * AuthApi - functional programming interface
 * @export
 */
exports.AuthApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.AuthApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary ログイン情報取得
         * @param {TsunaguwaLoginInfoRequest} tsunaguwaLoginInfoRequest ログイン情報取得リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoginInfo: function (tsunaguwaLoginInfoRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getLoginInfo(tsunaguwaLoginInfoRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * AuthApi - factory interface
 * @export
 */
exports.AuthApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.AuthApiFp(configuration);
    return {
        /**
         *
         * @summary ログイン情報取得
         * @param {TsunaguwaLoginInfoRequest} tsunaguwaLoginInfoRequest ログイン情報取得リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoginInfo: function (tsunaguwaLoginInfoRequest, options) {
            return localVarFp.getLoginInfo(tsunaguwaLoginInfoRequest, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
var AuthApi = /** @class */ (function (_super) {
    __extends(AuthApi, _super);
    function AuthApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary ログイン情報取得
     * @param {TsunaguwaLoginInfoRequest} tsunaguwaLoginInfoRequest ログイン情報取得リクエスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    AuthApi.prototype.getLoginInfo = function (tsunaguwaLoginInfoRequest, options) {
        var _this = this;
        return exports.AuthApiFp(this.configuration).getLoginInfo(tsunaguwaLoginInfoRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return AuthApi;
}(base_1.BaseAPI));
exports.AuthApi = AuthApi;
/**
 * CommonApi - axios parameter creator
 * @export
 */
exports.CommonApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary 市区町村取得
         * @param {CommonCitiesRequest} [commonCitiesRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCities: function (commonCitiesRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/common/cities";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(commonCitiesRequest, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary misc取得
         * @param {CommonMiscRequest} [commonMiscRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMisc: function (commonMiscRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/common/misc";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(commonMiscRequest, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary 都道府県取得
         * @param {CommonPrefecturesRequest} [commonPrefecturesRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrefectures: function (commonPrefecturesRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/common/prefectures";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(commonPrefecturesRequest, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * CommonApi - functional programming interface
 * @export
 */
exports.CommonApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.CommonApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary 市区町村取得
         * @param {CommonCitiesRequest} [commonCitiesRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCities: function (commonCitiesRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getCities(commonCitiesRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary misc取得
         * @param {CommonMiscRequest} [commonMiscRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMisc: function (commonMiscRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getMisc(commonMiscRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary 都道府県取得
         * @param {CommonPrefecturesRequest} [commonPrefecturesRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrefectures: function (commonPrefecturesRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getPrefectures(commonPrefecturesRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * CommonApi - factory interface
 * @export
 */
exports.CommonApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.CommonApiFp(configuration);
    return {
        /**
         *
         * @summary 市区町村取得
         * @param {CommonCitiesRequest} [commonCitiesRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCities: function (commonCitiesRequest, options) {
            return localVarFp.getCities(commonCitiesRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary misc取得
         * @param {CommonMiscRequest} [commonMiscRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMisc: function (commonMiscRequest, options) {
            return localVarFp.getMisc(commonMiscRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary 都道府県取得
         * @param {CommonPrefecturesRequest} [commonPrefecturesRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPrefectures: function (commonPrefecturesRequest, options) {
            return localVarFp.getPrefectures(commonPrefecturesRequest, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * CommonApi - object-oriented interface
 * @export
 * @class CommonApi
 * @extends {BaseAPI}
 */
var CommonApi = /** @class */ (function (_super) {
    __extends(CommonApi, _super);
    function CommonApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary 市区町村取得
     * @param {CommonCitiesRequest} [commonCitiesRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    CommonApi.prototype.getCities = function (commonCitiesRequest, options) {
        var _this = this;
        return exports.CommonApiFp(this.configuration).getCities(commonCitiesRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary misc取得
     * @param {CommonMiscRequest} [commonMiscRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    CommonApi.prototype.getMisc = function (commonMiscRequest, options) {
        var _this = this;
        return exports.CommonApiFp(this.configuration).getMisc(commonMiscRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary 都道府県取得
     * @param {CommonPrefecturesRequest} [commonPrefecturesRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    CommonApi.prototype.getPrefectures = function (commonPrefecturesRequest, options) {
        var _this = this;
        return exports.CommonApiFp(this.configuration).getPrefectures(commonPrefecturesRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return CommonApi;
}(base_1.BaseAPI));
exports.CommonApi = CommonApi;
/**
 * DxCarehomesApi - axios parameter creator
 * @export
 */
exports.DxCarehomesApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary 施設情報取得
         * @param {DxCarehomeCarehomeGetInfoRequest} [dxCarehomeCarehomeGetInfoRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCarehome: function (dxCarehomeCarehomeGetInfoRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/dx-carehomes/carehome/getInfo";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(dxCarehomeCarehomeGetInfoRequest, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary 施設合計数取得
         * @param {DxCarehomeSummaryInfoRequest} [dxCarehomeSummaryInfoRequest] 施設数取得リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCarehomesSummary: function (dxCarehomeSummaryInfoRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/dx-carehomes/summary";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(dxCarehomeSummaryInfoRequest, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary 入居者情報取得
         * @param {DxCarehomeResidentsGetInfoRequest} [dxCarehomeResidentsGetInfoRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResident: function (dxCarehomeResidentsGetInfoRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/dx-carehomes/residents/getInfo";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(dxCarehomeResidentsGetInfoRequest, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary 入居者情報取得
         * @param {DxCarehomeResidentsGetInfoFilesRequest} [dxCarehomeResidentsGetInfoFilesRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResidentInfoFiles: function (dxCarehomeResidentsGetInfoFilesRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/dx-carehomes/residents/getInfoFiles";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(dxCarehomeResidentsGetInfoFilesRequest, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary 入居者一覧取得
         * @param {DxCarehomeResidentsListRequest} dxCarehomeResidentsListRequest 施設入居者一覧取得リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResidentsList: function (dxCarehomeResidentsListRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'dxCarehomeResidentsListRequest' is not null or undefined
                    common_1.assertParamExists('getResidentsList', 'dxCarehomeResidentsListRequest', dxCarehomeResidentsListRequest);
                    localVarPath = "/dx-carehomes/residents/list";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(dxCarehomeResidentsListRequest, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary 入居者集計情報取得
         * @param {DxCarehomeResidentsSummaryInfoRequest} dxCarehomeResidentsSummaryInfoRequest 入居者集計情報取得リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResidentsSummary: function (dxCarehomeResidentsSummaryInfoRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'dxCarehomeResidentsSummaryInfoRequest' is not null or undefined
                    common_1.assertParamExists('getResidentsSummary', 'dxCarehomeResidentsSummaryInfoRequest', dxCarehomeResidentsSummaryInfoRequest);
                    localVarPath = "/dx-carehomes/residents/summary";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(dxCarehomeResidentsSummaryInfoRequest, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary 施設部屋情報取得
         * @param {DxCarehomeRoomsInfoRequest} dxCarehomeRoomsInfoRequest 施設部屋情報取得リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRooms: function (dxCarehomeRoomsInfoRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'dxCarehomeRoomsInfoRequest' is not null or undefined
                    common_1.assertParamExists('getRooms', 'dxCarehomeRoomsInfoRequest', dxCarehomeRoomsInfoRequest);
                    localVarPath = "/dx-carehomes/rooms";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(dxCarehomeRoomsInfoRequest, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary 入居者情報登録
         * @param {DxCarehomeResidentsRegisterRequest} [dxCarehomeResidentsRegisterRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registResident: function (dxCarehomeResidentsRegisterRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/dx-carehomes/residents/register";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(dxCarehomeResidentsRegisterRequest, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary 施設情報更新
         * @param {DxCarehomeCarehomeUpdateRequest} [dxCarehomeCarehomeUpdateRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCarehome: function (dxCarehomeCarehomeUpdateRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/dx-carehomes/carehome/update";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(dxCarehomeCarehomeUpdateRequest, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary 入居者情報更新
         * @param {DxCarehomeResidentsUpdateRequest} [dxCarehomeResidentsUpdateRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateResident: function (dxCarehomeResidentsUpdateRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/dx-carehomes/residents/update";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(dxCarehomeResidentsUpdateRequest, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary 相談者情報アップロード
         * @param {string} [infoType] ファイル情報種別
         * @param {string} [careHomeId] 施設ID
         * @param {string} [residentId] 相談者ID
         * @param {string} [categoryCode] カテゴリコード
         * @param {any} [file] 相談者情報ファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadResidentFileInfo: function (infoType, careHomeId, residentId, categoryCode, file, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/dx-carehomes/residents/uploadfile";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
                    if (infoType !== undefined) {
                        localVarQueryParameter['info_type'] = infoType;
                    }
                    if (careHomeId !== undefined) {
                        localVarQueryParameter['care_home_id'] = careHomeId;
                    }
                    if (residentId !== undefined) {
                        localVarQueryParameter['resident_id'] = residentId;
                    }
                    if (categoryCode !== undefined) {
                        localVarQueryParameter['category_code'] = categoryCode;
                    }
                    if (file !== undefined) {
                        localVarFormParams.append('file', file);
                    }
                    localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = localVarFormParams;
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * DxCarehomesApi - functional programming interface
 * @export
 */
exports.DxCarehomesApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.DxCarehomesApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary 施設情報取得
         * @param {DxCarehomeCarehomeGetInfoRequest} [dxCarehomeCarehomeGetInfoRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCarehome: function (dxCarehomeCarehomeGetInfoRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getCarehome(dxCarehomeCarehomeGetInfoRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary 施設合計数取得
         * @param {DxCarehomeSummaryInfoRequest} [dxCarehomeSummaryInfoRequest] 施設数取得リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCarehomesSummary: function (dxCarehomeSummaryInfoRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getCarehomesSummary(dxCarehomeSummaryInfoRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary 入居者情報取得
         * @param {DxCarehomeResidentsGetInfoRequest} [dxCarehomeResidentsGetInfoRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResident: function (dxCarehomeResidentsGetInfoRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getResident(dxCarehomeResidentsGetInfoRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary 入居者情報取得
         * @param {DxCarehomeResidentsGetInfoFilesRequest} [dxCarehomeResidentsGetInfoFilesRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResidentInfoFiles: function (dxCarehomeResidentsGetInfoFilesRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getResidentInfoFiles(dxCarehomeResidentsGetInfoFilesRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary 入居者一覧取得
         * @param {DxCarehomeResidentsListRequest} dxCarehomeResidentsListRequest 施設入居者一覧取得リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResidentsList: function (dxCarehomeResidentsListRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getResidentsList(dxCarehomeResidentsListRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary 入居者集計情報取得
         * @param {DxCarehomeResidentsSummaryInfoRequest} dxCarehomeResidentsSummaryInfoRequest 入居者集計情報取得リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResidentsSummary: function (dxCarehomeResidentsSummaryInfoRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getResidentsSummary(dxCarehomeResidentsSummaryInfoRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary 施設部屋情報取得
         * @param {DxCarehomeRoomsInfoRequest} dxCarehomeRoomsInfoRequest 施設部屋情報取得リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRooms: function (dxCarehomeRoomsInfoRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getRooms(dxCarehomeRoomsInfoRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary 入居者情報登録
         * @param {DxCarehomeResidentsRegisterRequest} [dxCarehomeResidentsRegisterRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registResident: function (dxCarehomeResidentsRegisterRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.registResident(dxCarehomeResidentsRegisterRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary 施設情報更新
         * @param {DxCarehomeCarehomeUpdateRequest} [dxCarehomeCarehomeUpdateRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCarehome: function (dxCarehomeCarehomeUpdateRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateCarehome(dxCarehomeCarehomeUpdateRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary 入居者情報更新
         * @param {DxCarehomeResidentsUpdateRequest} [dxCarehomeResidentsUpdateRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateResident: function (dxCarehomeResidentsUpdateRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateResident(dxCarehomeResidentsUpdateRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary 相談者情報アップロード
         * @param {string} [infoType] ファイル情報種別
         * @param {string} [careHomeId] 施設ID
         * @param {string} [residentId] 相談者ID
         * @param {string} [categoryCode] カテゴリコード
         * @param {any} [file] 相談者情報ファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadResidentFileInfo: function (infoType, careHomeId, residentId, categoryCode, file, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.uploadResidentFileInfo(infoType, careHomeId, residentId, categoryCode, file, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * DxCarehomesApi - factory interface
 * @export
 */
exports.DxCarehomesApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.DxCarehomesApiFp(configuration);
    return {
        /**
         *
         * @summary 施設情報取得
         * @param {DxCarehomeCarehomeGetInfoRequest} [dxCarehomeCarehomeGetInfoRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCarehome: function (dxCarehomeCarehomeGetInfoRequest, options) {
            return localVarFp.getCarehome(dxCarehomeCarehomeGetInfoRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary 施設合計数取得
         * @param {DxCarehomeSummaryInfoRequest} [dxCarehomeSummaryInfoRequest] 施設数取得リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCarehomesSummary: function (dxCarehomeSummaryInfoRequest, options) {
            return localVarFp.getCarehomesSummary(dxCarehomeSummaryInfoRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary 入居者情報取得
         * @param {DxCarehomeResidentsGetInfoRequest} [dxCarehomeResidentsGetInfoRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResident: function (dxCarehomeResidentsGetInfoRequest, options) {
            return localVarFp.getResident(dxCarehomeResidentsGetInfoRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary 入居者情報取得
         * @param {DxCarehomeResidentsGetInfoFilesRequest} [dxCarehomeResidentsGetInfoFilesRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResidentInfoFiles: function (dxCarehomeResidentsGetInfoFilesRequest, options) {
            return localVarFp.getResidentInfoFiles(dxCarehomeResidentsGetInfoFilesRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary 入居者一覧取得
         * @param {DxCarehomeResidentsListRequest} dxCarehomeResidentsListRequest 施設入居者一覧取得リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResidentsList: function (dxCarehomeResidentsListRequest, options) {
            return localVarFp.getResidentsList(dxCarehomeResidentsListRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary 入居者集計情報取得
         * @param {DxCarehomeResidentsSummaryInfoRequest} dxCarehomeResidentsSummaryInfoRequest 入居者集計情報取得リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getResidentsSummary: function (dxCarehomeResidentsSummaryInfoRequest, options) {
            return localVarFp.getResidentsSummary(dxCarehomeResidentsSummaryInfoRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary 施設部屋情報取得
         * @param {DxCarehomeRoomsInfoRequest} dxCarehomeRoomsInfoRequest 施設部屋情報取得リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRooms: function (dxCarehomeRoomsInfoRequest, options) {
            return localVarFp.getRooms(dxCarehomeRoomsInfoRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary 入居者情報登録
         * @param {DxCarehomeResidentsRegisterRequest} [dxCarehomeResidentsRegisterRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registResident: function (dxCarehomeResidentsRegisterRequest, options) {
            return localVarFp.registResident(dxCarehomeResidentsRegisterRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary 施設情報更新
         * @param {DxCarehomeCarehomeUpdateRequest} [dxCarehomeCarehomeUpdateRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCarehome: function (dxCarehomeCarehomeUpdateRequest, options) {
            return localVarFp.updateCarehome(dxCarehomeCarehomeUpdateRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary 入居者情報更新
         * @param {DxCarehomeResidentsUpdateRequest} [dxCarehomeResidentsUpdateRequest]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateResident: function (dxCarehomeResidentsUpdateRequest, options) {
            return localVarFp.updateResident(dxCarehomeResidentsUpdateRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary 相談者情報アップロード
         * @param {string} [infoType] ファイル情報種別
         * @param {string} [careHomeId] 施設ID
         * @param {string} [residentId] 相談者ID
         * @param {string} [categoryCode] カテゴリコード
         * @param {any} [file] 相談者情報ファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadResidentFileInfo: function (infoType, careHomeId, residentId, categoryCode, file, options) {
            return localVarFp.uploadResidentFileInfo(infoType, careHomeId, residentId, categoryCode, file, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * DxCarehomesApi - object-oriented interface
 * @export
 * @class DxCarehomesApi
 * @extends {BaseAPI}
 */
var DxCarehomesApi = /** @class */ (function (_super) {
    __extends(DxCarehomesApi, _super);
    function DxCarehomesApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary 施設情報取得
     * @param {DxCarehomeCarehomeGetInfoRequest} [dxCarehomeCarehomeGetInfoRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DxCarehomesApi
     */
    DxCarehomesApi.prototype.getCarehome = function (dxCarehomeCarehomeGetInfoRequest, options) {
        var _this = this;
        return exports.DxCarehomesApiFp(this.configuration).getCarehome(dxCarehomeCarehomeGetInfoRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary 施設合計数取得
     * @param {DxCarehomeSummaryInfoRequest} [dxCarehomeSummaryInfoRequest] 施設数取得リクエスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DxCarehomesApi
     */
    DxCarehomesApi.prototype.getCarehomesSummary = function (dxCarehomeSummaryInfoRequest, options) {
        var _this = this;
        return exports.DxCarehomesApiFp(this.configuration).getCarehomesSummary(dxCarehomeSummaryInfoRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary 入居者情報取得
     * @param {DxCarehomeResidentsGetInfoRequest} [dxCarehomeResidentsGetInfoRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DxCarehomesApi
     */
    DxCarehomesApi.prototype.getResident = function (dxCarehomeResidentsGetInfoRequest, options) {
        var _this = this;
        return exports.DxCarehomesApiFp(this.configuration).getResident(dxCarehomeResidentsGetInfoRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary 入居者情報取得
     * @param {DxCarehomeResidentsGetInfoFilesRequest} [dxCarehomeResidentsGetInfoFilesRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DxCarehomesApi
     */
    DxCarehomesApi.prototype.getResidentInfoFiles = function (dxCarehomeResidentsGetInfoFilesRequest, options) {
        var _this = this;
        return exports.DxCarehomesApiFp(this.configuration).getResidentInfoFiles(dxCarehomeResidentsGetInfoFilesRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary 入居者一覧取得
     * @param {DxCarehomeResidentsListRequest} dxCarehomeResidentsListRequest 施設入居者一覧取得リクエスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DxCarehomesApi
     */
    DxCarehomesApi.prototype.getResidentsList = function (dxCarehomeResidentsListRequest, options) {
        var _this = this;
        return exports.DxCarehomesApiFp(this.configuration).getResidentsList(dxCarehomeResidentsListRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary 入居者集計情報取得
     * @param {DxCarehomeResidentsSummaryInfoRequest} dxCarehomeResidentsSummaryInfoRequest 入居者集計情報取得リクエスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DxCarehomesApi
     */
    DxCarehomesApi.prototype.getResidentsSummary = function (dxCarehomeResidentsSummaryInfoRequest, options) {
        var _this = this;
        return exports.DxCarehomesApiFp(this.configuration).getResidentsSummary(dxCarehomeResidentsSummaryInfoRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary 施設部屋情報取得
     * @param {DxCarehomeRoomsInfoRequest} dxCarehomeRoomsInfoRequest 施設部屋情報取得リクエスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DxCarehomesApi
     */
    DxCarehomesApi.prototype.getRooms = function (dxCarehomeRoomsInfoRequest, options) {
        var _this = this;
        return exports.DxCarehomesApiFp(this.configuration).getRooms(dxCarehomeRoomsInfoRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary 入居者情報登録
     * @param {DxCarehomeResidentsRegisterRequest} [dxCarehomeResidentsRegisterRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DxCarehomesApi
     */
    DxCarehomesApi.prototype.registResident = function (dxCarehomeResidentsRegisterRequest, options) {
        var _this = this;
        return exports.DxCarehomesApiFp(this.configuration).registResident(dxCarehomeResidentsRegisterRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary 施設情報更新
     * @param {DxCarehomeCarehomeUpdateRequest} [dxCarehomeCarehomeUpdateRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DxCarehomesApi
     */
    DxCarehomesApi.prototype.updateCarehome = function (dxCarehomeCarehomeUpdateRequest, options) {
        var _this = this;
        return exports.DxCarehomesApiFp(this.configuration).updateCarehome(dxCarehomeCarehomeUpdateRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary 入居者情報更新
     * @param {DxCarehomeResidentsUpdateRequest} [dxCarehomeResidentsUpdateRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DxCarehomesApi
     */
    DxCarehomesApi.prototype.updateResident = function (dxCarehomeResidentsUpdateRequest, options) {
        var _this = this;
        return exports.DxCarehomesApiFp(this.configuration).updateResident(dxCarehomeResidentsUpdateRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary 相談者情報アップロード
     * @param {string} [infoType] ファイル情報種別
     * @param {string} [careHomeId] 施設ID
     * @param {string} [residentId] 相談者ID
     * @param {string} [categoryCode] カテゴリコード
     * @param {any} [file] 相談者情報ファイル
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DxCarehomesApi
     */
    DxCarehomesApi.prototype.uploadResidentFileInfo = function (infoType, careHomeId, residentId, categoryCode, file, options) {
        var _this = this;
        return exports.DxCarehomesApiFp(this.configuration).uploadResidentFileInfo(infoType, careHomeId, residentId, categoryCode, file, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return DxCarehomesApi;
}(base_1.BaseAPI));
exports.DxCarehomesApi = DxCarehomesApi;
