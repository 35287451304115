import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import MobileStore from '@/mobile/store/index.js'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',

  state: {
    user: null,
    newComers: {},
    newsPosts: {},
    noticePosts: {},
    recommendProducts: {},
    isFooter: true,
    isAppHeader: true,
    isRouteChange: false,
    isRedirect: false,
    // register form data
    register: {}
  },
  getters: {
    // ユーザー
    user: (state) => state.user,
    isAuthenticated(state) {
      return !!state.user
    },
    // ログイン状態
    loggedIn: (state) => {
      return Boolean(state.user)
    },
    //導入いただきありがとうございます
    newComerList(state) {
      return state.newComers
    },
    //介護・医療ニュース
    newsPostList(state) {
      return state.newsPosts
    },
    //お知らせ
    noticePostList(state) {
      return state.noticePosts
    },
    //アウトレットおすすめ
    recommendProducts(state) {
      return state.recommendProducts
    }
  },
  mutations: {
    // ユーザー情報保存
    setUser(state, user) {
      state.user = user
    },
    newComers(state, payload) {
      state.newComers = payload
    },
    //シンプルにブログリストを受け取る
    newsPosts(state, payload) {
      state.newsPosts = payload
    },
    noticePosts(state, payload) {
      state.noticePosts = payload
    },
    //アウトレットのおすすめ商品リストを受け取る
    recommendProducts(state, payload) {
      state.recommendProducts = payload
    },
    setFooter(state, payload) {
      state.isFooter = payload
    },
    setAppHeader(state, payload) {
      state.isAppHeader = payload
    },
    setRouteChange(state, payload) {
      state.isRouteChange = payload
    },
    setRedirect(state, payload) {
      state.isRedirect = payload
    },
    setRegister(state, payload) {
      state.registerFormData = payload
    }
  },
  actions: {
    newComersAction({ commit }, payload) {
      commit('newComers', payload)
    },
    newsPostsAction({ commit }, payload) {
      commit('newsPosts', payload)
    },
    noticePostsAction({ commit }, payload) {
      commit('noticePosts', payload.slice(0, 5))
    },
    recommendProductsAction({ commit }, payload) {
      commit('recommendProducts', payload)
    }
  },
  modules: {
    MobileStore
  },
  plugins: [
    createPersistedState({
      key: process.env.VUE_APP_SESSIONSTORAGE_USER_KEY,
      paths: ['user'],
      storage: window.sessionStorage
    })
  ]
})
