import {
  Configuration,
  // 認証API
  AuthApi,
  // 相談者機能API
  ConsultsApi,
  ConsultsMoveinResidentNewRequest,
  ConsultsMoveinResidentNewResponse,
  ConsultsSendResidentConsultRequest,
  ConsultsSendResidentConsultResponse,
  ConsultsSendIntermediateConsultRequest,
  ConsultsSendIntermediateConsultResponse,
  ConsultsSendMoveoutConsultRequest,
  ConsultsSendMoveoutConsultResponse,
  ConsultsMoveinResidentListRequest,
  ConsultsMoveinResidentListResponse,
  ConsultsMoveinResidentUpdateRequest,
  ConsultsMoveinResidentUpdateResponse,
  IMatchApiNormalResponse,
  UploadFileInfoResponse
} from 'imatch-api-client'
import store from '../store'
import { Tools, ToolsConfiguration } from 'tsunaguwa-tools'
import config from './../config'

const userData: any = store.state.user
const apiBaseUrl = process.env.VUE_APP_IMATCH_API_URL
const imatchApiConfig: Configuration = new Configuration()
imatchApiConfig.basePath = apiBaseUrl
const tswToolsConfig = new ToolsConfiguration()
tswToolsConfig.tswConf = config.TsunaguwaConfig
const tswTools = new Tools(tswToolsConfig)

export class IMatchAuthApi {
  constructor() {}
  static authApi: AuthApi = new AuthApi(imatchApiConfig)
}

export class IMatchConsultsApi {
  constructor() {}
  static consultsApi: ConsultsApi = new ConsultsApi(imatchApiConfig)

  static uploadFileInfo(params: any, fileName: any, callback: Function) {
    this.uploadFileInfoAsync(params, fileName)
      .then((responseData: UploadFileInfoResponse) => {
        callback.call(this, responseData)
      })
      .catch((err) => {
        console.error(err)
      })
  }
  static async uploadFileInfoAsync(params: any, fileName?: any) {
    const response = await this.consultsApi.uploadFileInfo(
      params.infoType,
      params.careHomeId,
      params.entryId,
      fileName,
      ImatchApiUtil.getCommonOptions()
    )
    return response.data
  }

  static getNewMoveinResident(params: any, callback: Function) {
    this.getNewMoveinResidentAsync(params)
      .then((responseData: ConsultsMoveinResidentNewResponse) => {
        callback.call(this, responseData)
      })
      .catch((err) => {
        console.error(err)
      })
  }
  static async getNewMoveinResidentAsync(params: any) {
    const request: ConsultsMoveinResidentNewRequest = params
    const response = await this.consultsApi.getNewMoveinResident(
      request,
      ImatchApiUtil.getCommonOptions()
    )
    return response.data
  }

  static registResidentConsult(params: any, callback: Function) {
    this.registResidentConsultAsync(params)
      .then((responseData: ConsultsSendResidentConsultResponse) => {
        callback.call(this, responseData)
      })
      .catch((err) => {
        console.error(err)
      })
  }
  static async registResidentConsultAsync(params: any) {
    const request: ConsultsSendResidentConsultRequest = params
    const response = await this.consultsApi.registResidentConsult(
      request,
      ImatchApiUtil.getCommonOptions()
    )
    return response.data
  }

  static sendIntermediateConsult(params: any, callback: Function) {
    this.sendIntermediateConsultAsync(params)
      .then((responseData: ConsultsSendIntermediateConsultResponse) => {
        callback.call(this, responseData)
      })
      .catch((err) => {
        console.error(err)
      })
  }
  static async sendIntermediateConsultAsync(params: any) {
    const request: ConsultsSendIntermediateConsultRequest = params
    const response = await this.consultsApi.sendIntermediateConsult(
      request,
      ImatchApiUtil.getCommonOptions()
    )
    return response.data
  }

  static sendMoveoutConsult(params: any, callback: Function) {
    this.sendMoveoutConsultAsync(params)
      .then((responseData: ConsultsSendMoveoutConsultResponse) => {
        callback.call(this, responseData)
      })
      .catch((err) => {
        console.error(err)
      })
  }
  static async sendMoveoutConsultAsync(params: any) {
    const request: ConsultsSendMoveoutConsultRequest = params
    const response = await this.consultsApi.sendMoveoutConsult(
      request,
      ImatchApiUtil.getCommonOptions()
    )
    return response.data
  }

  static getMoveinResidentList(params: any, callback: Function) {
    this.getMoveinResidentListAsync(params)
      .then((responseData: ConsultsMoveinResidentListResponse) => {
        callback.call(this, responseData)
      })
      .catch((err) => {
        console.error(err)
      })
  }
  static async getMoveinResidentListAsync(params: any) {
    const request: ConsultsMoveinResidentListRequest = params
    const response = await this.consultsApi.getMoveinResidentList(
      request,
      ImatchApiUtil.getCommonOptions()
    )
    return response.data
  }

  static updateMoveinResident(params: any, callback: Function) {
    this.updateMoveinResidentAsync(params)
      .then((responseData: ConsultsMoveinResidentUpdateResponse) => {
        callback.call(this, responseData)
      })
      .catch((err) => {
        console.error(err)
      })
  }
  static async updateMoveinResidentAsync(params: any) {
    const request: ConsultsMoveinResidentUpdateRequest = params
    const response = await this.consultsApi.updateMoveinResident(
      request,
      ImatchApiUtil.getCommonOptions()
    )
    return response.data
  }
}

class ImatchApiUtil {
  static getCommonOptions() {
    const key = config.TsunaguwaConfig.UserInfoCookie.Key
    let commonOptions = {
      headers: {
        [key]: tswTools.getTswCookie()
      }
    }
    return commonOptions
  }
}
