const MobileStore = {
  state: {
    isDrawer: false
  },

  getters: {},

  mutations: {
    TOGGLE_DRAWER(state) {
      state.isDrawer = !state.isDrawer
    }
  }
}

export default MobileStore
