<template>
  <div>
    <section class="wrapper login-content common-height common-content">
      <div class="wrapper title-block">
        <h2 class="common-title">
          メディケアモール
        </h2>
      </div>
      <div class="owner-btns-block">
        <ul class="medicare-btns-list">
          <li>
            <a
              :href="clinicmall"
              v-if="
                is_authenticated &&
                  (isOwner ||
                    isManager ||
                    isFacilityAdmin ||
                    isSystemAdmin ||
                    isPublisher ||
                    isPersonal ||
                    isPartner)
              "
            >
              <div class="icon-img">
                <picture>
                  <source
                    srcset="../assets/img/new/medicare/visit.avif"
                    type="image/avif"
                  />
                  <source
                    srcset="../assets/img/new/medicare/visit.webp"
                    type="image/webp"
                  />
                  <img
                    src="../assets/img/new/medicare/visit.png"
                    alt="訪問サービス"
                  />
                </picture>
              </div>
              <div class="name">訪問サービス</div>
            </a>
            <a
              href="https://medicaremall-lp.tsunaguwa-kaigo.com/"
              target="_blank"
              v-else
            >
              <div class="icon-img">
                <picture>
                  <source
                    srcset="../assets/img/new/medicare/visit.avif"
                    type="image/avif"
                  />
                  <source
                    srcset="../assets/img/new/medicare/visit.webp"
                    type="image/webp"
                  />
                  <img
                    src="../assets/img/new/medicare/visit.png"
                    alt="訪問サービス"
                  />
                </picture>
              </div>
              <div class="name">訪問サービス</div>
            </a>
          </li>
          <li>
            <a
              :href="pharmacymall"
              v-if="
                is_authenticated &&
                  (isOwner ||
                    isManager ||
                    isFacilityAdmin ||
                    isSystemAdmin ||
                    isPublisher ||
                    isPersonal ||
                    isPartner)
              "
            >
              <div class="icon-img">
                <picture>
                  <source
                    srcset="../assets/img/new/medicare/phermacy.avif"
                    type="image/avif"
                  />
                  <source
                    srcset="../assets/img/new/medicare/phermacy.webp"
                    type="image/webp"
                  />
                  <img
                    src="../assets/img/new/medicare/phermacy.png"
                    alt="ファーマシーモール"
                  />
                </picture>
              </div>
              <div class="name">調剤薬局</div>
            </a>
            <a
              href="https://medicaremall-lp.tsunaguwa-kaigo.com/"
              target="_blank"
              v-else
            >
              <div class="icon-img">
                <picture>
                  <source
                    srcset="../assets/img/new/medicare/phermacy.avif"
                    type="image/avif"
                  />
                  <source
                    srcset="../assets/img/new/medicare/phermacy.webp"
                    type="image/webp"
                  />
                  <img
                    src="../assets/img/new/medicare/phermacy.png"
                    alt="ファーマシーモール"
                  />
                </picture>
              </div>
              <div class="name">調剤薬局</div>
            </a>
          </li>
          <li>
            <a
              :href="caremanagement"
              v-if="
                is_authenticated &&
                  (isOwner ||
                    isManager ||
                    isFacilityAdmin ||
                    isSystemAdmin ||
                    isPublisher ||
                    isPersonal ||
                    isPartner)
              "
            >
              <div class="icon-img">
                <picture>
                  <source
                    srcset="../assets/img/new/medicare/caremanegement.avif"
                    type="image/avif"
                  />
                  <source
                    srcset="../assets/img/new/medicare/caremanegement.webp"
                    type="image/webp"
                  />
                  <img
                    src="../assets/img/new/medicare/caremanegement.png"
                    alt="ケアマネジメントサービス"
                  />
                </picture>
              </div>
              <div class="name">ケアマネージャー</div>
            </a>
            <a
              href="https://medicaremall-lp.tsunaguwa-kaigo.com/"
              target="_blank"
              v-else
            >
              <div class="icon-img">
                <picture>
                  <source
                    srcset="../assets/img/new/medicare/caremanegement.avif"
                    type="image/avif"
                  />
                  <source
                    srcset="../assets/img/new/medicare/caremanegement.webp"
                    type="image/webp"
                  />
                  <img
                    src="../assets/img/new/medicare/caremanegement.png"
                    alt="ケアマネジメントサービス"
                  />
                </picture>
              </div>
              <div class="name">ケアマネージャー</div>
            </a>
          </li>
          <li>
            <a
              :href="dayservice"
              v-if="
                is_authenticated &&
                  (isOwner ||
                    isManager ||
                    isFacilityAdmin ||
                    isSystemAdmin ||
                    isPublisher ||
                    isPersonal ||
                    isPartner)
              "
            >
              <div class="icon-img">
                <picture>
                  <source
                    srcset="../assets/img/new/medicare/dayservice.avif"
                    type="image/avif"
                  />
                  <source
                    srcset="../assets/img/new/medicare/dayservice.webp"
                    type="image/webp"
                  />
                  <img
                    src="../assets/img/new/medicare/dayservice.png"
                    alt="デイサービス"
                  />
                </picture>
              </div>
              <div class="name">デイサービス</div>
            </a>
            <a
              href="https://medicaremall-lp.tsunaguwa-kaigo.com/"
              target="_blank"
              v-else
            >
              <div class="icon-img">
                <picture>
                  <source
                    srcset="../assets/img/new/medicare/dayservice.avif"
                    type="image/avif"
                  />
                  <source
                    srcset="../assets/img/new/medicare/dayservice.webp"
                    type="image/webp"
                  />
                  <img
                    src="../assets/img/new/medicare/dayservice.png"
                    alt="デイサービス"
                  />
                </picture>
              </div>
              <div class="name">デイサービス</div>
            </a>
          </li>
          <li>
            <a
              :href="taxidispatchservice"
              v-if="
                is_authenticated &&
                  (isOwner ||
                    isManager ||
                    isFacilityAdmin ||
                    isSystemAdmin ||
                    isPublisher ||
                    isPersonal ||
                    isPartner)
              "
            >
              <div class="icon-img">
                <picture>
                  <source
                    srcset="../assets/img/new/medicare/taxi.avif"
                    type="image/avif"
                  />
                  <source
                    srcset="../assets/img/new/medicare/taxi.webp"
                    type="image/webp"
                  />
                  <img
                    src="../assets/img/new/medicare/taxi.png"
                    alt="介護タクシー配車サービス"
                  />
                </picture>
              </div>
              <div class="name">介護タクシー</div>
            </a>
            <a
              href="https://medicaremall-lp.tsunaguwa-kaigo.com/"
              target="_blank"
              v-else
            >
              <div class="icon-img">
                <picture>
                  <source
                    srcset="../assets/img/new/medicare/taxi.avif"
                    type="image/avif"
                  />
                  <source
                    srcset="../assets/img/new/medicare/taxi.webp"
                    type="image/webp"
                  />
                  <img
                    src="../assets/img/new/medicare/taxi.png"
                    alt="介護タクシー配車サービス"
                  />
                </picture>
              </div>
              <div class="name">介護タクシー</div>
            </a>
          </li>
          <li>
            <a
              :href="endingservice"
              v-if="
                isOwner ||
                  isManager ||
                  isFacilityAdmin ||
                  isSystemAdmin ||
                  isAdvertiser ||
                  isServicer ||
                  isPersonal ||
                  isPartner
              "
            >
              <div class="icon-img">
                <picture>
                  <source
                    srcset="../assets/img/new/medicare/ending.avif"
                    type="image/avif"
                  />
                  <source
                    srcset="../assets/img/new/medicare/ending.webp"
                    type="image/webp"
                  />
                  <img
                    src="../assets/img/new/medicare/ending.png"
                    alt="エンディングサービス"
                  />
                </picture>
              </div>
              <div class="name">終活サービス</div>
            </a>
            <a
              href="https://medicaremall-lp.tsunaguwa-kaigo.com/"
              target="_blank"
              v-else
            >
              <div class="icon-img">
                <picture>
                  <source
                    srcset="../assets/img/new/medicare/ending.avif"
                    type="image/avif"
                  />
                  <source
                    srcset="../assets/img/new/medicare/ending.webp"
                    type="image/webp"
                  />
                  <img
                    src="../assets/img/new/medicare/ending.png"
                    alt="エンディングサービス"
                  />
                </picture>
              </div>
              <div class="name">終活サービス</div>
            </a>
          </li>
          <li>
            <a
              :href="noninsuranceservice"
              v-if="
                isOwner ||
                  isManager ||
                  isFacilityAdmin ||
                  isSystemAdmin ||
                  isAdvertiser ||
                  isServicer ||
                  isPersonal ||
                  isPartner
              "
            >
              <div class="icon-img">
                <picture>
                  <source
                    srcset="../assets/img/new/medicare/noninsurance.avif"
                    type="image/avif"
                  />
                  <source
                    srcset="../assets/img/new/medicare/noninsurance.webp"
                    type="image/webp"
                  />
                  <img
                    src="../assets/img/new/medicare/noninsurance.png"
                    alt="保険外サービス"
                  />
                </picture>
              </div>
              <div class="name">保険外サービス</div>
            </a>
            <a
              href="https://medicaremall-lp.tsunaguwa-kaigo.com/"
              target="_blank"
              v-else
            >
              <div class="icon-img">
                <picture>
                  <source
                    srcset="../assets/img/new/medicare/noninsurance.avif"
                    type="image/avif"
                  />
                  <source
                    srcset="../assets/img/new/medicare/noninsurance.webp"
                    type="image/webp"
                  />
                  <img
                    src="../assets/img/new/medicare/noninsurance.png"
                    alt="保険外サービス"
                  />
                </picture>
              </div>
              <div class="name">保険外サービス</div>
            </a>
          </li>
          <li>
            <router-link
              to="/comingsoon"
              v-if="
                is_authenticated &&
                  (isOwner ||
                    isManager ||
                    isFacilityAdmin ||
                    isSystemAdmin ||
                    isPublisher ||
                    isPersonal ||
                    isPartner)
              "
            >
              <div class="icon-img">
                <picture>
                  <source
                    srcset="../assets/img/new/medicare/searchfacility.avif"
                    type="image/avif"
                  />
                  <source
                    srcset="../assets/img/new/medicare/searchfacility.webp"
                    type="image/webp"
                  />
                  <img
                    src="../assets/img/new/medicare/searchfacility.png"
                    alt="介護施設を探す"
                  />
                </picture>
              </div>
              <div class="name">介護・障害・児童施設</div>
            </router-link>
            <a
              href="https://medicaremall-lp.tsunaguwa-kaigo.com/"
              target="_blank"
              v-else
            >
              <div class="icon-img">
                <picture>
                  <source
                    srcset="../assets/img/new/medicare/searchfacility.avif"
                    type="image/avif"
                  />
                  <source
                    srcset="../assets/img/new/medicare/searchfacility.webp"
                    type="image/webp"
                  />
                  <img
                    src="../assets/img/new/medicare/searchfacility.png"
                    alt="介護施設を探す"
                  />
                </picture>
              </div>
              <div class="name">介護・障害・児童施設</div>
            </a>
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
import Common from '@/plugin/Common'

export default {
  name: 'MedicareMall',
  mixins: [Common],
  data: function() {
    return {
      isSystemAdmin: false,
      isPartner: false,
      isAdvertiser: false,
      isServicer: false,
      isPublisher: false,
      isOwner: false,
      isManager: false,
      isFacilityAdmin: false,
      isPersonal: false,
      isAdAdmin: false
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    clinicmall() {
      return process.env.VUE_APP_MEDICAREMALL_BASE_URL + '/clinic'
    },
    pharmacymall() {
      return process.env.VUE_APP_MEDICAREMALL_BASE_URL + '/pharmacy'
    },
    hospitalmall() {
      return process.env.VUE_APP_MEDICAREMALL_BASE_URL + '/hospital'
    },
    caremanagement() {
      return process.env.VUE_APP_MEDICAREMALL_BASE_URL + '/caremanagement'
    },
    dayservice() {
      return process.env.VUE_APP_MEDICAREMALL_BASE_URL + '/daycare'
    },
    taxidispatchservice() {
      return process.env.VUE_APP_MEDICAREMALL_BASE_URL + '/taxidispatchservice'
    },
    endingservice() {
      return process.env.VUE_APP_MEDICAREMALL_BASE_URL + '/endingservice'
    },
    noninsuranceservice() {
      return process.env.VUE_APP_MEDICAREMALL_BASE_URL + '/noninsuranceservice'
    },
    is_authenticated() {
      if (this.$store.getters.isAuthenticated) {
        return true
      } else {
        return false
      }
    }
  },
  created() {
    try {
      if (this.$store.getters.isAuthenticated) {
        const roles = this.$store.state.user['custom:role'].split(',')
        for (let r of roles) {
          var role = r.split(':')
          switch (role[0]) {
            case 'R01':
              this.isSystemAdmin = true
              break
            case 'R02':
              this.isPartner = true
              break
            case 'R07':
              this.isAdvertiser = true
              break
            case 'R08':
              this.isServicer = true
              break
            case 'R09':
              this.isPublisher = true
              break
            case 'R10':
              this.isOwner = true
              break
            case 'R11':
              this.isManager = true
              break
            case 'R12':
              this.isFacilityAdmin = true
              break
            case 'R14':
              this.isPersonal = true
              break
            case 'R19':
              this.isAdAdmin = true
              break
            default:
          }
        }
      }
    } catch (error) {
      console.error(error)
    }
    setTimeout(this.loadChatbotGeneral, 1000)
  }
}
</script>

<style lang="scss"></style>
