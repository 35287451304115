<template>
  <div>
    <section class="wrapper login-content common-height common-content">
      <div class="wrapper title-block">
        <picture>
          <source srcset="../assets/img/etc/junbichuu.avif" type="image/avif" />
          <source srcset="../assets/img/etc/junbichuu.webp" type="image/webp" />
          <img src="../assets/img/etc/junbichuu.jpg" alt="準備中" />
        </picture>
      </div>
      <div class="banner-btns-block">
        <a href="#" onclick="javascript:window.history.back(-1);return false;">
          <v-btn color="#F7B57F">
            <v-icon left dark>
              {{ icons.mdiArrowLeftCircle }}
            </v-icon>
            戻る
          </v-btn>
        </a>
      </div>
    </section>
  </div>
</template>

<script>
import Common from '@/plugin/Common'
import { mdiArrowLeftCircle } from '@mdi/js'

export default {
  mixins: [Common],
  name: 'ComingSoon',
  data: function() {
    return {
      icons: {
        mdiArrowLeftCircle
      }
    }
  },
  created() {
    setTimeout(this.commonHeight, 10)
    // ロード時、リサイズ時にcommon-heightの高さを調整する
    window.addEventListener('load', this.commonHeight)
    window.addEventListener('resize', this.commonHeight)
    setTimeout(this.loadChatbotGeneral, 1000)
  }
}
</script>

<style lang="scss"></style>
