<template>
  <div>
    <v-app>
      <section class="dxservice-wrapper common-height common-content">
        <div class="wrapper title-block">
          <h2 class="common-title">
            施設情報
          </h2>
        </div>
        <div class="dxservices-block">
          <v-container class="grey lighten-4">
            <v-row no-gutters>
              <v-col cols="6">
                <v-card class="pa-1" outlined tile>
                  <v-select
                    :items="careHomesItems"
                    v-model="selectCareHome"
                    item-text="care_home_name"
                    item-value="care_home_id"
                    label="施設id"
                    no-data-text="施設が登録されていません"
                    dense
                    outlined
                    hide-details
                    @change="getCareHomeInfo"
                  ></v-select>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-row no-gutters>
                  <v-col>
                    <v-card class="pa-1" outlined tile>
                      登録日
                    </v-card>
                  </v-col>
                  <v-col>
                    <v-card class="pa-1" outlined tile>
                      最終更新日
                    </v-card>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col>
                    <v-card class="pa-1" outlined tile>
                      {{ careHomeInfo.create_date }}
                    </v-card>
                  </v-col>
                  <v-col>
                    <v-card class="pa-1" outlined tile>
                      {{ careHomeInfo.update_date }}
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                ■基本情報
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="2">
                <v-card class="pa-1" outlined tile>
                  施設名
                </v-card>
              </v-col>
              <v-col cols="10">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="careHomeInfo.care_home_name"
                    required
                    outlined
                    hide-details
                    dense
                    max="99"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="2">
                <v-card class="pa-1" outlined tile>
                  施設名（かな）
                </v-card>
              </v-col>
              <v-col cols="10">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="careHomeInfo.care_home_name_kana"
                    required
                    outlined
                    hide-details
                    dense
                    max="99"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="2">
                <v-card class="pa-1" outlined tile>
                  事業主
                </v-card>
              </v-col>
              <v-col cols="10">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="careHomeInfo.corporation"
                    required
                    outlined
                    hide-details
                    dense
                    max="99"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="2">
                <v-card class="pa-1" outlined tile>
                  住所
                </v-card>
              </v-col>
              <v-col cols="2">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="careHomeInfo.zip_code"
                    required
                    outlined
                    hide-details
                    dense
                    label="郵便番号"
                    max="8"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card class="pa-1" outlined tile>
                  <v-select
                    :items="prefecturesItems"
                    v-model="careHomeInfo.prefecture_code"
                    item-text="label"
                    item-value="value"
                    label="都道府県"
                    dense
                    outlined
                    hide-details
                    @change="getCities"
                  ></v-select>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card class="pa-1" outlined tile>
                  <v-select
                    :items="citiesItems"
                    v-model="careHomeInfo.city_code"
                    item-text="label"
                    item-value="value"
                    label="市区町村"
                    dense
                    outlined
                    hide-details
                  ></v-select>
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="2"> </v-col>
              <v-col cols="10">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="careHomeInfo.address1"
                    required
                    outlined
                    dense
                    hide-details
                    label="町名・番地"
                    max="50"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="2"> </v-col>
              <v-col cols="10">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="careHomeInfo.address2"
                    required
                    outlined
                    dense
                    hide-details
                    label="建物名・部屋番号"
                    max="50"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="2"> </v-col>
              <v-col cols="5">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="careHomeInfo.tel"
                    required
                    outlined
                    dense
                    hide-details
                    label="電話番号"
                    max="50"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
              <v-col cols="5">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="careHomeInfo.fax"
                    required
                    outlined
                    dense
                    hide-details
                    label="FAX番号"
                    max="50"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="2">
                <v-card class="pa-1" outlined tile>
                  代表 <br />
                  メールアドレス
                </v-card>
              </v-col>
              <v-col cols="10">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="careHomeInfo.mail_address"
                    required
                    outlined
                    dense
                    hide-details
                    max="99"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                ■居室情報
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="2">
                <v-card class="pa-1" outlined tile>
                  居室数
                </v-card>
              </v-col>
              <v-col cols="10">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="careHomeInfo.total_rooms"
                    required
                    outlined
                    dense
                    hide-details
                    max="99"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                ■空室情報
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="2">
                <v-card class="pa-1" outlined tile>
                  空室数
                </v-card>
              </v-col>
              <v-col cols="10">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="careHomeInfo.vacancies_available"
                    required
                    outlined
                    dense
                    hide-details
                    max="99"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                ■入院情報
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="2">
                <v-card class="pa-1" outlined tile>
                  入院者数
                </v-card>
              </v-col>
              <v-col cols="10">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="careHomeInfo.inpatient_count"
                    outlined
                    dense
                    hide-details
                    max="99"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                ■備考
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <v-card class="pa-1" outlined tile>
                  <v-textarea
                    v-model="careHomeInfo.memo"
                    label="備考"
                    persistent-hint
                    outlined
                    counter="255"
                  ></v-textarea>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
          <br />
          <CommonDialog
            name="更新"
            title="施設情報更新"
            message="施設情報を更新しますか？"
            color="#94c43c"
            class-name="request-btn"
            v-on:acceptAction="updateCarehomeInfo"
          ></CommonDialog>
          <router-link to="/dx-services">
            <v-btn color="primary">
              <v-icon left dark>
                {{ icons.mdiArrowLeftCircle }}
              </v-icon>
              戻る
            </v-btn>
          </router-link>
          <br />
          <br />
        </div>
      </section>
    </v-app>
  </div>
</template>

<script>
import Common from '@/plugin/Common'
import * as apiclient from '@/apiclient'
import CommonDialog from '@/components/common/CommonDialog'
import { mdiArrowLeftCircle } from '@mdi/js'

export default {
  mixins: [Common],
  name: 'DxCareHomeEdit',
  components: {
    CommonDialog
  },
  created() {
    setTimeout(this.loadChatbotGeneral, 1000)
    this.getLoginInfo()
  },
  data: function() {
    return {
      careHomesItems: [],
      selectCareHome: '0',
      careHomeInfo: {
        care_home_id: '',
        care_home_name: '',
        care_home_name_kana: '',
        corporation: '',
        zip_code: '',
        prefecture_code: '',
        city_code: '',
        address1: '',
        address2: '',
        latitude: '',
        longitude: '',
        tel: '',
        fax: '',
        mail_address: '',
        memo: '',
        total_rooms: '',
        vacancies_available: '',
        create_date: '',
        update_date: ''
      },
      prefecturesItems: [],
      citiesItems: [],
      icons: {
        mdiArrowLeftCircle
      },
      isAllLevelsChecked: false
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  },
  methods: {
    getLoginInfo: function() {
      try {
        apiclient.TsunaguwaAuthApi.getLoginInfo((responseData) => {
          this.careHomesItems = responseData.payload.user_care_homes
          if (this.careHomesItems.length > 0) {
            this.selectCareHome = this.careHomesItems[0].care_home_id
            this.getCareHomeInfo()
          }
        })
      } catch (error) {
        throw error.response
      }
    },
    getCareHomeInfo: function() {
      try {
        if (this.isEmpty(this.selectCareHome)) {
          return
        }
        var params = {
          care_home_id: this.selectCareHome
        }
        apiclient.TsunaguwaDxCarehomesApi.getCarehome((responseData) => {
          this.careHomeInfo = responseData.payload
          this.getPrefectures()
        }, params)
      } catch (error) {
        throw error.response
      }
    },
    getPrefectures: function() {
      try {
        apiclient.TsunaguwaCommonApi.getPrefectures((responseData) => {
          this.prefecturesItems = responseData.payload.items
          this.getCities()
        })
      } catch (error) {
        throw error.response
      }
    },
    getCities: function() {
      try {
        if (this.isEmpty(this.careHomeInfo.prefecture_code)) {
          return
        }
        var prefectureCode = this.careHomeInfo.prefecture_code
        apiclient.TsunaguwaCommonApi.getCities((responseData) => {
          this.citiesItems = responseData.payload.items
        }, prefectureCode)
      } catch (error) {
        throw error.response
      }
    },
    updateCarehomeInfo: function() {
      try {
        if (this.isEmpty(this.selectCareHome) || this.selectCareHome == '0') {
          alert(
            'DXサービスの利用には施設情報の事前登録が必要です。管理者にお問い合わせください。'
          )
          return
        }
        var params = {
          carehome: this.careHomeInfo
        }
        apiclient.TsunaguwaDxCarehomesApi.updateCarehome((responseData) => {
          if (responseData != null) {
            alert('施設施設情報を更新しました。')
          } else {
            alert('更新に失敗しました。')
          }
        }, params)
      } catch (error) {
        throw error.response
      }
    }
  }
}
</script>

<style lang="scss"></style>
