<template>
  <div>
    <section class="wrapper login-content common-height common-content">
      <div class="wrapper title-block">
        <h2 class="common-title">
          マイページ
        </h2>
      </div>
      <div class="mypage-btns-block">
        <ul class="mypage-btns-list">
          <li>
            <router-link to="/mypage/mydata">
              <div class="submenu">登録情報の編集</div>
              <div class="description">
                メールアドレス、氏名・住所などの登録情報を設定できます。
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
import Common from '@/plugin/Common'

export default {
  mixins: [Common],
  name: 'MyPage',
  created() {
    setTimeout(this.loadChatbotGeneral, 1000)
  }
}
</script>

<style lang="scss"></style>
