<template>
  <div>
    <section class="wrapper login-content common-height common-content">
      <div class="news-block">
        <div class="page-hierarchy">
          <router-link to="/">ホーム</router-link>
          <span class="arrow"></span>
          <span>お知らせ</span>
        </div>
        <ul class="news-list mb">
          <li v-for="newsPost in newsPostList" :key="newsPost.id">
            <router-link
              :to="{ name: 'NoticeDetail', params: { id: newsPost.id } }"
            >
              <span class="news-date">{{ newsPost.date | moment }}</span>
              <span class="news-title">{{ newsPost.title.rendered }}</span>
            </router-link>
          </li>
        </ul>
        <ul class="pagination">
          <li>
            <p
              class="previous pointer"
              @click="nextPageAction(previousPage)"
              v-if="previousPage > 0"
            >
              前へ
            </p>
          </li>
          <li v-for="page in pageArray" :key="page.page">
            <p
              class="page-num"
              :class="page.current"
              v-if="page.current === 'active'"
            >
              {{ page.page }}
            </p>
            <p
              class="page-num pointer"
              @click="nextPageAction(page.page)"
              :class="page.current"
              v-else
            >
              {{ page.page }}
            </p>
          </li>
          <li v-if="totalPage !== currentPage">
            <p class="next pointer" @click="nextPageAction(nextPage)">次へ</p>
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CommonFilters from '../plugin/CommonFilters'
import Common from '@/plugin/Common'

require('@/plugin/vue-awesome-swiper')

export default {
  name: 'NewsList',
  mixins: [CommonFilters, Common],
  computed: {
    ...mapGetters(['newsPostList'])
  },
  methods: {
    ...mapActions(['newsPostsAction']),
    pageArraySet: function() {
      this.pageArray = []
      for (let i = 1; i <= this.totalPage; i++) {
        if (this.currentPage === i) {
          this.pageArray.push({
            page: i,
            current: 'active'
          })
        } else {
          this.pageArray.push({
            page: i,
            current: 'false'
          })
        }
      }
    },
    nextPageAction: function(page) {
      this.$axios
        .get(process.env.VUE_APP_NOTIFICATION_API_URL + '?page=' + page)
        .then((response) => {
          this.newsPostsAction(response.data)
          this.currentPage = page
          this.nextPage = page + 1
          this.previousPage = page - 1
          this.pageArraySet()
        })
    }
  },
  created() {
    this.$axios
      .get(process.env.VUE_APP_NOTIFICATION_API_URL)
      .then((response) => {
        this.newsPostsAction(response.data)
        this.totalPage = Number(response.headers['x-wp-totalpages'])
        this.pageArraySet()
      })
    // ロード時、リサイズ時にcommon-heightの高さを調整する
    setTimeout(this.commonHeight, 10)
    window.addEventListener('resize', this.commonHeight)
    setTimeout(this.loadChatbotGeneral, 1000)
  },
  data: function() {
    return {
      totalPage: null,
      pageArray: [],
      currentPage: 1,
      previousPage: 0,
      nextPage: 2
    }
  }
}
</script>

<style lang="scss"></style>
