import Cookies from 'js-cookie';
import { Buffer } from 'buffer';
var zlib = require('zlib');
var crypto = require('crypto');
var algorithm = 'aes-256-ctr';
var IV_LENGTH = 16;
Cookies.get();
// @ts-ignore
window.Buffer = Buffer;
var Tools = /** @class */ (function () {
    function Tools(param) {
        this.tswUiTkCookieStorage = {
            // REQUIRED - Cookie domain (only required if cookieStorage is provided)
            domain: '',
            // OPTIONAL - Cookie path
            path: '/',
            // OPTIONAL - Cookie expiration in days
            expires: 30,
            // OPTIONAL - Cookie secure flag
            // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
            secure: false
        };
        this.tswConf = param.tswConf;
        this.encryptionKey = Buffer.from(this.tswConf.Common.SecretKey, 'base64');
        this.tswUiTkCookieStorage.domain = this.tswConf.UserInfoCookie.Domain;
        this.tswUiTkCookieStorage.secure = this.tswConf.Cookies.Secure;
    }
    Tools.prototype.setTswCookie = function (idToken) {
        var tkenc = this.encrypt(idToken);
        var tswCookie = Cookies.set(this.tswConf.UserInfoCookie.Key, tkenc, this.tswUiTkCookieStorage);
        // console.log('[Cookie saved] ' + tswCookie)
    };
    Tools.prototype.removeTswCookie = function () {
        console.log('[Cookie removed]' + this.tswConf.UserInfoCookie.Key);
        Cookies.remove(this.tswConf.UserInfoCookie.Key, this.tswUiTkCookieStorage);
    };
    Tools.prototype.getTswCookie = function () {
        return Cookies.get(this.tswConf.UserInfoCookie.Key);
    };
    Tools.prototype.getTswIdToken = function () {
        var tswUiTkCookie = this.getTswCookie();
        var tswUiTkCookieDecrypted = this.decrypt(tswUiTkCookie || '');
        return tswUiTkCookieDecrypted;
    };
    Tools.prototype.getUserInfo = function () {
        var userInfo = this.decodeJwt(this.getTswIdToken());
        // console.log('[getUserInfo] ' + userInfo)
        return userInfo;
    };
    Tools.prototype.decodeJwt = function (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        return decodeURIComponent(escape(window.atob(base64)));
    };
    Tools.prototype.encrypt = function (text) {
        var iv = crypto.randomBytes(IV_LENGTH);
        var cipher = crypto.createCipheriv(algorithm, Buffer.from(this.encryptionKey), iv);
        var encrypted = cipher.update(text);
        encrypted = Buffer.concat([encrypted, cipher.final()]);
        var encryptedStr = iv.toString('hex') + ':' + encrypted.toString('hex');
        encryptedStr = this.zip(encryptedStr);
        // console.log('encryptedStr=' + encryptedStr)
        return encryptedStr;
    };
    Tools.prototype.decrypt = function (text) {
        var unzipedStr = this.unzip(text);
        var textParts = unzipedStr.split(':');
        var iv = Buffer.from(textParts.shift(), 'hex');
        var encryptedText = Buffer.from(textParts.join(':'), 'hex');
        var decipher = crypto.createDecipheriv(algorithm, Buffer.from(this.encryptionKey), iv);
        var decrypted = decipher.update(encryptedText);
        decrypted = Buffer.concat([decrypted, decipher.final()]);
        var encryptedStr = decrypted.toString();
        // console.log('decryptStr=' + encryptedStr)
        return encryptedStr;
    };
    Tools.prototype.zip = function (text) {
        var input = Buffer.from(text, 'utf8');
        return zlib.gzipSync(input).toString('base64');
    };
    Tools.prototype.unzip = function (text) {
        var input = Buffer.from(text, 'base64');
        return zlib.gunzipSync(input).toString('utf8');
    };
    return Tools;
}());
export { Tools };
