<template>
  <div>
    <section class="wrapper login-content common-height common-content">
      <div class="news-block">
        <div class="content-inner">
          <div class="title">
            利用規約
          </div>
          <div class="header"></div>
          <div class="text">
            この利用規約（以下「本規約」といいます）は、株式会社タリホー（以下「当社」といいます）が運営する介護・医療総合プラットフォームtsunaguwaのサービス（以下「本サービス」といいます）をご利用になるすべての方（以下「お客様」といいます）に共通して適用されます。<br />
            なお、アウトレットモール、商談モール、CHUMONをご利用いただく場合には、別途アウトレットモール利用規約、商談モール利用規約、CHUMON利用規約がそれぞれ適用されます。<br />
          </div>
          <div class="header">1. サービスの利用</div>
          <div class="text">
            本規約にご同意いただくことによって、本サービスをご利用いただくことができます。<br />
            オーナーサービスについては有料でのご利用となり、月額3,300円(税込)にてサービスをご利用いただくことができます。
            オーナーサービスの利用料のお支払いについては、<router-link
              to="/tradelaw"
              >オーナーサービスご利用にあたっての特別商取引法に基づく表記</router-link
            >に従うものとします。<br />
            なお、無料で提供しているサービスにつきましては、本規約にご同意いただく手続に代えて、実際にご利用いただくことで本規約にご同意いただいたものとみなします。<br />
          </div>
          <div class="header">2. サービス内容の保証および変更</div>
          <div class="text">
            当社は、本サービスの内容について、瑕疵（かし）や不具合がないことは保証しておりません。<br />
            また、当社は、お客様にあらかじめ通知することなく本サービスの内容や仕様を変更したり、提供を停止したり中止したりすることができるものとします。<br />
          </div>
          <div class="header">3. サービスの利用制限</div>
          <div class="text">
            当社は、本サービスのご利用を、IDを登録された方に限定したり、一定の年齢以上の方に限定したり、当社が定める本人確認などの手続を経て一定の要件を満たしたお客様のみに限定したりするなど、利用に際して条件を付すことができるものとします。<br />
            また、当社は反社会的勢力の構成員（過去に構成員であった方を含みます）およびその関係者の方や、本サービスを悪用したり、第三者に迷惑をかけたりするようなお客様に対してはご利用をお断りしています。<br />
          </div>
          <div class="header">4. IDの登録情報</div>
          <div class="text">
            IDを登録していただく場合、<br />
            （1）真実かつ正確な情報を登録していただくこと、<br />
            （2）登録内容が最新となるようお客様ご自身で適宜修正（本規約制定時は修正する手段が実現できていないため、修正依頼を当社へ申請）していただくことがお客様の義務となります。<br />
          </div>
          <div class="header">5. IDおよびパスワード等に関するお客様の責任</div>
          <div class="text">
            お客様を特定する所定の認証方法（IDとパスワードの組み合わせや携帯電話事業者から送信される携帯電話番号ごとに一意に付与される符号の、登録情報との一致確認による認証を含みますが、これらに限りません）によりログインされた場合には、当社は、当該お客様ご自身によるご利用であるとみなします。
            本サービスの利用や商品の購入などによって料金や代金（本サービスのご利用にかかる代金、利用料、会費その他名目は問いません。<br />
            また当社が第三者から回収を受託したお客様の債務を含みます。以下「代金」といいます）が発生した場合には、当該お客様に課金いたします。<br />
          </div>
          <div class="header">6. サービス利用にあたっての順守事項</div>
          <div class="text">
            本サービスのご利用に際しては以下に定める行為（それらを誘発する行為や準備行為も含みます）を禁止いたします。<br />
            (1)日本国またはご利用の際にお客様が所在する国・地域の法令に違反する行為<br />
            (2)社会規範・公序良俗に反するものや、他人の権利を侵害し、または他人の迷惑となるようなものを、投稿、掲載、開示、提供または送信（以下これらを総称して「投稿など」といいます）したりする行為<br />
            (3)ほかのお客様の使用するソフトウエア、ハードウエアなどの機能を破壊したり、妨害したりするようなプログラムなどの投稿などをする行為<br />
            (4)当社のサーバーまたはネットワークの機能を破壊したり、妨害したりする行為<br />
            (5)本サービス、当社の配信する広告、または、当社のサイト上で提供されているサービス、広告を妨害する行為<br />
            (6)個人情報などのほかのお客様に関する情報をお客様に無断で収集したり蓄積したりする行為<br />
            (7)本サービスを、提供の趣旨に照らして本来のサービス提供の目的とは異なる目的で利用する行為<br />
            (8)ほかのお客様のIDを使用してサービスを利用する行為<br />
            (9)手段のいかんを問わず他人からIDやパスワードを入手したり、他人にIDやパスワードを開示したり提供したりする行為<br />
            (10)本サービスに関連して、反社会的勢力に直接・間接に利益を提供する行為<br />
          </div>
          <div class="header">7. 本サービスなどの再利用の禁止</div>
          <div class="text">
            お客様が、本サービスやそれらを構成するデータを、当該サービスの提供目的を超えて利用した場合、当社は、それらの行為を差し止める権利ならびにそれらの行為によってお客様が得た利益相当額を請求する権利を有します。<br />
          </div>
          <div class="header">8. 当社に対する補償</div>
          <div class="text">
            お客様の行為が原因で生じたクレームなどに関連して当社に費用が発生した場合または当社が賠償金などの支払を行った場合、お客様は当社が支払った費用や賠償金など（当社が支払った弁護士費用を含みます）を負担するものとします。<br />
          </div>
          <div class="header">9. お客様のデータおよびコンテンツの取扱い</div>
          <div class="text">
            お客様が当社の管理するサーバーに保存しているデータについて、当社ではバックアップの義務を負わないものとし、お客様ご自身においてバックアップを行っていただくものとします。<br />
            なお、本サービスの保守や改良などの必要が生じた場合には、当社はお客様が当社の管理するサーバーに保存しているデータを、サービスの保守や改良などに必要な範囲で複製等することができるものとします。<br />
            また、電子掲示板など、不特定または多数のお客様がアクセスできるサービスに対してお客様が投稿などをしたコンテンツについては、お客様または当該コンテンツの著作権者に著作権が帰属します。<br />
            当該コンテンツについて、お客様は当社に対して、日本の国内外で無償かつ非独占的に利用（複製、上映、公衆送信、展示、頒布、譲渡、貸与、翻訳、翻案、出版を含みます）する権利を期限の定めなく許諾（サブライセンス権を含みます）したものとみなします。<br />
            なお、お客様は著作者人格権を行使しないものとします。<br />
          </div>
          <div class="header">10. 広告掲載について</div>
          <div class="text">
            当社は、本サービスに当社または当社に掲載依頼をした第三者の広告を掲載することができるものとします。<br />
          </div>
          <div class="header">
            11. 投稿などの削除、サービスの利用停止、ID削除について
          </div>
          <div class="text">
            当社は、本サービスを適正に運営するために、以下の場合にはあらかじめ通知することなく、データやコンテンツを削除したり、本サービスの全部または一部の利用をお断りしたり、お客様のIDを削除したりするといった措置を講じることができるものとします。<br />
            また、お客様が複数のIDを登録されている場合には、それらすべてのIDに対して措置がとられる場合があります。<br />
            (1)お客様が本規約に定められている事項に違反した場合、もしくはそのおそれがあると当社が判断した場合<br />
            (2)当社にお支払いいただく代金について支払の遅滞が生じた場合<br />
            (3)本サービスを利用する上で代金決済手段として指定されたクレジットカードや銀行口座の利用が停止された場合<br />
            (4)お客様が破産もしくは民事再生の手続の申立てを受け、またはお客様自らがそれらの申立てを行うなど、お客様の信用不安が発生したと当社が判断した場合<br />
            (5)IDが反社会的勢力またはその構成員や関係者によって登録または使用された場合、もしくはそのおそれがあると当社が判断した場合<br />
            (6)お客様が一定期間にわたってIDまたは本サービスを使用していない場合<br />
            (7)その他、お客様との信頼関係が失われた場合など、当社とお客様との契約関係の維持が困難であると当社が判断した場合<br />
          </div>
          <div class="header">12. 免責事項</div>
          <div class="text">
            当社の債務不履行責任は、当社の故意または重過失によらない場合には免責されるものとします。<br />
            なお、お客様との本規約に基づく本サービスのご利用に関する契約が消費者契約法に定める消費者契約に該当する場合、上記の免責は適用されないものとし、当社は、当社の故意・重過失に起因する場合を除き、通常生じうる損害の範囲内で、かつ、有料サービスにおいては代金額（継続的なサービスの場合は1か月分相当額）を上限として損害賠償責任を負うものとします。<br />
          </div>
          <div class="header">13. 利用規約の変更について</div>
          <div class="text">
            当社が必要と判断した場合には、本規約を変更することができるものとします。<br />
            この場合、当社は、当社のウェブサイトへの掲載その他の適切な方法にて、本規約を変更する旨および変更後の本規約の内容ならびにその効力発生日を周知するものとします。<br />
          </div>
          <div class="header">14. 通知または連絡</div>
          <div class="text">
            お客様が当社への連絡を希望される場合には、当社が設けた問い合わせページまたは当社が指定する電子メールアドレスあての電子メールによって行っていただくものとします。<br />
            当社は、お客様からのお問い合わせに対する回答を原則として電子メールのみで行います。<br />
          </div>
          <div class="header">15. 権利義務などの譲渡の禁止</div>
          <div class="text">
            お客様は、本規約に基づくすべての契約について、その契約上の地位およびこれにより生じる権利義務の全部または一部を、当社の書面による事前の承諾なく第三者に譲渡することはできません。<br />
          </div>
          <div class="header">16. 準拠法、裁判管轄</div>
          <div class="text">
            本規約の成立、効力発生、解釈にあたっては日本法を準拠法とします。<br />
            また、本サービス（掲載内容や広告などを含む）、ソフトウエアに起因または関連して当社とお客様との間で生じた紛争については東京地方裁判所を第一審の専属的合意管轄裁判所とします。<br />
          </div>
          <div class="header">17. 利用規約の適用制限について</div>
          <div class="text">
            本規約の規定がお客様との本規約に基づく契約に適用される関連法令に反するとされる場合、当該規定は、その限りにおいて、当該お客様との契約には適用されないものとします。<br />
            ただし、この場合でも、本規約のほかの規定の効力には影響しないものとします。<br />
          </div>
          <div class="header">
            附則
          </div>
          <div class="text">
            2022年6月1日 制定・施行<br />
            2022年7月29日 改訂
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Common from '@/plugin/Common'

export default {
  mixins: [Common],
  name: 'Agreement',
  created() {
    setTimeout(this.commonHeight, 10)
    // ロード時、リサイズ時にcommon-heightの高さを調整する
    window.addEventListener('load', this.commonHeight)
    window.addEventListener('resize', this.commonHeight)
    setTimeout(this.loadChatbotGeneral, 1000)
  }
}
</script>

<style lang="scss"></style>
