import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute,
  CookieStorage
} from 'amazon-cognito-identity-js'
import { Config, CognitoIdentityCredentials } from 'aws-sdk'
import { Tools, ToolsConfiguration } from 'tsunaguwa-tools'
import store from '../store'

import Cookies from 'js-cookie'
Cookies.get()

export default class Cognito {
  configure(config) {
    const commonCookie = {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      domain: '',
      // OPTIONAL - Cookie path
      path: '/',
      // OPTIONAL - Cookie expiration in days
      expires: 30,
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      secure: config.TsunaguwaConfig.Cookies.Secure
    }
    this.cookieStorage = {
      ...commonCookie,
      ...{
        domain: config.AWSConfig.CognitoCookieDomain
      }
    }
    const tswToolsConfig = new ToolsConfiguration()
    tswToolsConfig.tswConf = config.TsunaguwaConfig
    this.tswTools = new Tools(tswToolsConfig)
    if (config.AWSConfig.userPool) {
      this.userPool = config.AWSConfig.userPool
    } else {
      this.userPool = new CognitoUserPool({
        UserPoolId: config.AWSConfig.UserPoolId,
        ClientId: config.AWSConfig.ClientId,
        Storage: new CookieStorage(this.cookieStorage)
      })
    }
    Config.region = config.AWSConfig.Region
    Config.credentials = new CognitoIdentityCredentials({
      IdentityPoolId: config.AWSConfig.IdentityPoolId
    })
    this.options = config
    this.currentUser = false
  }

  static install = (Vue, options) => {
    Object.defineProperty(Vue.prototype, '$cognito', {
      get() {
        return this.$root._cognito
      }
    })

    Vue.mixin({
      beforeCreate() {
        if (this.$options.cognito) {
          this._cognito = this.$options.cognito
          this._cognito.configure(options)
        }
      }
    })
  }

  // サインアップ
  signUp(username, password) {
    const name = { Name: 'name', Value: username }
    const email = { Name: 'email', Value: username }
    const now = Math.floor(new Date().getTime() / 1000)
    const upatedAt = { Name: 'updated_at', Value: String(now) }

    const attributeList = []
    attributeList.push(new CognitoUserAttribute(name))
    attributeList.push(new CognitoUserAttribute(email))
    attributeList.push(new CognitoUserAttribute(upatedAt))

    return new Promise((resolve, reject) => {
      this.userPool.signUp(
        username,
        password,
        attributeList,
        null,
        (err, result) => {
          if (err) {
            reject(err)
          } else {
            resolve(result)
          }
        }
      )
    })
  }

  // サインアップ時のコード認証
  confirmation(username, confirmationCode) {
    const userData = {
      Username: username,
      Pool: this.userPool,
      Storage: new CookieStorage(this.cookieStorage)
    }
    const cognitoUser = new CognitoUser(userData)
    return new Promise((resolve, reject) => {
      cognitoUser.confirmRegistration(confirmationCode, true, (err, result) => {
        if (err) {
          reject(err)
        } else {
          resolve(result)
        }
      })
    })
  }

  // サインイン
  signin(username, password) {
    this.removeUserState()
    const userData = {
      Username: username,
      Pool: this.userPool,
      Storage: new CookieStorage(this.cookieStorage)
    }
    const cognitoUser = new CognitoUser(userData)
    const authenticationData = { Username: username, Password: password }
    const authenticationDetails = new AuthenticationDetails(authenticationData)
    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {
          var idToken = result.getIdToken().getJwtToken() // IDトークン
          var accessToken = result.getAccessToken().getJwtToken() // アクセストークン
          var refreshToken = result.getRefreshToken().getToken() // 更新トークン
          console.log('idToken : ' + idToken)
          console.log('accessToken : ' + accessToken)
          console.log('refreshToken : ' + refreshToken)
          console.log(JSON.stringify(result.idToken.payload))
          var user = result.idToken.payload
          this.setUserState(user, idToken)
          resolve(result)
        },
        onFailure: (err) => {
          reject(err)
        }
      })
    })
  }

  // サインアウト
  signout() {
    if (this.userPool.getCurrentUser()) {
      console.log(this.userPool.getCurrentUser())
      this.userPool.getCurrentUser().signOut()
      this.removeUserState()
    }
  }

  // 認証ずみかどうか
  isAuthenticated() {
    this.currentUser = this.userPool.getCurrentUser()
    return new Promise((resolve, reject) => {
      if (this.currentUser === null) {
        reject(this.currentUser)
      }
      this.currentUser.getSession((err, session) => {
        if (err) {
          reject(err)
        } else {
          if (!session.isValid()) {
            reject(session)
          } else {
            this.setUserState(null, session.idToken.jwtToken)
            resolve(session)
          }
        }
      })
    })
  }

  // 属性の取得
  getAttribute() {
    return new Promise((resolve, reject) => {
      this.currentUser.getUserAttributes((err, result) => {
        if (err) {
          reject(err)
        } else {
          resolve(result)
        }
      })
    })
  }

  // コードの再送
  resentCode() {
    return new Promise((resolve, reject) => {
      this.currentUser.getAttributeVerificationCode('email', {
        onSuccess: (result) => {
          console.log('success getAttributeVerificationCode')
          resolve(result)
        },
        onFailure: (err) => {
          console.log(
            'failed getAttributeVerificationCode: ' + JSON.stringify(err)
          )
          reject(err)
        }
      })
    })
  }

  // Eメールアドレス変更後 emailを有効可する
  verifyAttribute(confirmationCode) {
    return new Promise((resolve, reject) => {
      this.currentUser.verifyAttribute('email', confirmationCode, {
        onSuccess: (result) => {
          console.log('email verification success')
          var user = store.getters.user
          user['email_verified'] = 'true'
          this.setUserState(user, user.token)
          resolve(result)
        },
        onFailure: (err) => {
          console.log('email verification failed')
          reject(err)
        }
      })
    })
  }

  // Eメールアドレスの更新
  updateEmailAddress(email) {
    let attributes = {
      email: email,
      name: email
    }
    return new Promise((resolve, reject) => {
      this.updateAttributes(attributes)
        .then((result) => {
          // eslint-disable-line
          resolve(result)
          var user = store.getters.user
          user['email_verified'] = 'false'
          this.setUserState(user, user.token)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  // パスワード更新
  updatePassword(oldPassword, newPassword) {
    return new Promise((resolve, reject) => {
      this.currentUser.changePassword(
        oldPassword,
        newPassword,
        (err, result) => {
          if (err) {
            reject(err)
          } else {
            resolve(result)
          }
        }
      )
    })
  }

  // パスワード忘れメール送信
  forgetPassword(username) {
    const userData = {
      Username: username,
      Pool: this.userPool,
      Storage: new CookieStorage(this.cookieStorage)
    }
    const cognitoUser = new CognitoUser(userData)
    return new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: (result) => {
          console.log('email verification success')
          resolve(result)
        },
        onFailure: (err) => {
          console.log('email verification failed')
          reject(err)
        }
      })
    })
  }

  // パスワードリセット
  resetPassword(username, newPassword, code) {
    const userData = {
      Username: username,
      Pool: this.userPool,
      Storage: new CookieStorage(this.cookieStorage)
    }
    const cognitoUser = new CognitoUser(userData)
    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(code, newPassword, {
        onSuccess: (result) => {
          console.log('password reset success')
          resolve(result)
        },
        onFailure: (err) => {
          console.log('password reset failed')
          reject(err)
        }
      })
    })
  }

  // プロフィール更新
  updateAttributes(attributes) {
    const attributeList = []
    for (var key in attributes) {
      const attribute = { Name: key, Value: attributes[key] }
      attributeList.push(new CognitoUserAttribute(attribute))
    }
    return new Promise((resolve, reject) => {
      if (this.currentUser === null) {
        reject(this.currentUser)
      }

      // update attributes
      this.currentUser.updateAttributes(attributeList, (err, result) => {
        if (err) {
          reject(err)
        } else {
          var user = store.getters.user
          for (var key in attributes) {
            user[key] = attributes[key]
          }
          this.setUserState(user, user.token)
          resolve(result)
        }
      })
    })
  }

  setUserState(user, idToken) {
    if (idToken != null) {
      this.tswTools.setTswCookie(idToken)
      // console.log('setting token', idToken)
    }
    if (user != null) {
      store.commit('setUser', user)
      // console.log('setting user', user)
    }
  }

  removeUserState() {
    if (
      this.existObject(this.currentUser) &&
      this.existObject(this.currentUser.storage)
    ) {
      this.currentUser.storage.clear()
    }
    if (
      this.existObject(this.userPool) &&
      this.existObject(this.userPool.storage)
    ) {
      this.userPool.storage.clear()
    }
    store.commit('setUser', null)
    this.tswTools.removeTswCookie()
  }

  existObject(obj) {
    if (obj != undefined && obj != null) {
      return true
    }
    return false
  }
}
