<template>
  <div>
    <section class="wrapper login-content common-height common-content">
      <div class="wrapper title-block">
        <h2 class="common-title">
          ページが見つかりませんでした
        </h2>
      </div>
    </section>
  </div>
</template>

<script>
import Common from '@/plugin/Common'

export default {
  mixins: [Common],
  name: 'NotFound',
  created() {
    setTimeout(this.commonHeight, 10)
    // ロード時、リサイズ時にcommon-heightの高さを調整する
    window.addEventListener('load', this.commonHeight)
    window.addEventListener('resize', this.commonHeight)
    setTimeout(this.loadChatbotGeneral, 1000)
  }
}
</script>

<style lang="scss"></style>
