<template>
  <div>
    <section class="wrapper login-content common-height common-content">
      <div class="news-block">
        <div class="content-inner">
          <div class="title">
            オーナーサービスご利用にあたっての特定商取引法に基づく表記
          </div>
          <div class="header">事業者の名称</div>
          <div class="text">株式会社タリホー</div>
          <div class="header">代表者</div>
          <div class="text">村岡正和</div>
          <div class="header">所在地</div>
          <div class="text">
            〒103-0027 東京都中央区日本橋2-1-17 丹生ビル2階
          </div>
          <div class="header">電話番号</div>
          <div class="text">03-3864-8210</div>
          <div class="header">メールアドレス</div>
          <div class="text">contact@tallyho.jp</div>
          <div class="header">URL</div>
          <div class="text">
            <a href="https://www.tsunaguwa-kaigo.com/"
              >https://www.tsunaguwa-kaigo.com/</a
            >
          </div>
          <div class="header">販売価格</div>
          <div class="text">オーナーサービス: 月額3,300円(税込)</div>
          <div class="header">販売価格以外の必要料金</div>
          <div class="text">
            <p>
              ウェブページの閲覧、商品のご購入、コンテンツダウンロード等に必要となるインターネット接続料金、通信料金等は、お客様のご負担となります。
              それぞれの料金は、お客様がご利用のインターネットプロバイダ、携帯電話会社等にお問い合わせください。<br />
              その他必要となる料金は以下の通りです。
            </p>
            <ul>
              <li>● 消費税</li>
              <li>
                ●
                コンビニ後払い(ミライバライ)によるお支払いの場合、コンビニ後払い(ミライバライ)手数料として500円(税込)
              </li>
            </ul>
          </div>
          <div class="header">お支払方法</div>
          <div class="text">
            <ul>
              <li>● クレジットカード</li>
              <li>● 銀行振込</li>
              <li>● コンビニ後払い(ミライバライ)</li>
            </ul>
            <p>
              ※コンビニ後払い(ミライバライ)とは<br />
              ●商品を受け取った後にコンビニ・電子決済からお支払い頂くことができる便利なサービスです。<br />
              ●ご利用代金明細書兼払込票は、商品を受け取ってから5日程度でAGミライバライ（株）より別送でお送りいたします。<br />
              ●請求書発行日より14日以内にお支払いをお願いいたします。<br />
              ●払込受領書は、領収書としてご利用いただけますので大切に保管してください（再発行不可）。<br />
              ●商品代金、送料とは別に後払い手数料として500円（税込）がかかります。<br />
              ●ご利用限度額は累計残高で55,000円（税込）迄です。<br />
              ●ご注文後の住所変更・転送は承れませんので予めご了承ください。<br />
              ●審査に必要な範囲でお客様情報をAGミライバライ（株）へ提供します。<br />
              ●ご本人様確認のため、AGミライバライ（株）からSMSやお電話をさせていただく場合がございます。<br />
              ●利用者ご本人様が未成年の場合、法定代理人（親権者）の利用同意を得てご利用ください。<br />
              ●お客様は、以下の利用規約に記載の内容をご確認・ご承諾のうえ本サービスに申込むものとします。<br />
              お支払可能なコンビニエンスストア・電子決済につきましては、利用規約に記載しております。<br />
              ▼ミライバライ利用規約<br />
              <a
                href="https://www.mirai-barai.co.jp/kiyaku/atobarai"
                target="_blank"
                >https://www.mirai-barai.co.jp/kiyaku/atobarai</a
              >
            </p>
          </div>
          <div class="header">販売価格の支払時期</div>
          <div class="text">
            当月利用分を前月末日に請求します。<br />
            クレジットカードの場合の支払時期は、各カード会社の引き落とし日です。<br />
            銀行振込の場合の支払時期は、当月の請求書到着日から当月末日までです。
            なお、当月末日が金融機関の営業日ではない場合、当該金融機関の前営業日までとします。<br />
            コンビニ後払い(ミライバライ)の場合の支払時期は、AGミライバライ株式会社より別途送付される請求書の到着日から請求書に記載された日時までです。<br />
          </div>
          <div class="header">サービスの提供時期</div>
          <div class="text">
            当月1日～当月末日とします。
            解約のお申し出がない場合、以降毎月自動で継続されます。<br />
          </div>
          <div class="header">キャンセルに関する特約</div>
          <div class="text">
            オーナーサービスは、月途中のご利用キャンセルをお受けできません。<br />
            また、オーナーサービス解約の申し出の期限は前月20日までとします。<br />
          </div>
          <div class="header">動作環境</div>
          <div class="text">
            <ul>
              <li>
                PC
                <ul>
                  <li>
                    &nbsp;推奨OS
                    <dl>
                      <dd>&nbsp;&nbsp;Windows 8.1 / 10 / 11</dd>
                    </dl>
                    <dl>
                      <dd>&nbsp;&nbsp;MacOSX 10.9 以上</dd>
                    </dl>
                  </li>
                  <li>
                    &nbsp;推奨ブラウザ
                    <dl>
                      <dd>&nbsp;&nbsp;Edge（最新版）</dd>
                    </dl>
                    <dl>
                      <dd>&nbsp;&nbsp;Chrome（最新版）</dd>
                    </dl>
                    <dl>
                      <dd>&nbsp;&nbsp;Firefox（最新版）</dd>
                    </dl>
                    <dl>
                      <dd>&nbsp;&nbsp;Safari（最新版）</dd>
                    </dl>
                  </li>
                </ul>
              </li>
              <li>
                タブレット
                <ul>
                  <li>
                    &nbsp;推奨OS
                    <dl>
                      <dd>&nbsp;&nbsp;iOS 10以降</dd>
                    </dl>
                    <dl>
                      <dd>&nbsp;&nbsp;Android OS 8以降</dd>
                    </dl>
                  </li>
                  <li>
                    &nbsp;推奨ブラウザ
                    <dl>
                      <dd>&nbsp;&nbsp;iOS: Safari （最新版）</dd>
                    </dl>
                    <dl>
                      <dd>&nbsp;&nbsp;Android: Chrome （最新版）</dd>
                    </dl>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Common from '@/plugin/Common'

export default {
  mixins: [Common],
  name: 'TradeLaw',
  created() {
    setTimeout(this.commonHeight, 10)
    // ロード時、リサイズ時にcommon-heightの高さを調整する
    window.addEventListener('load', this.commonHeight)
    window.addEventListener('resize', this.commonHeight)
    setTimeout(this.loadChatbotGeneral, 1000)
  }
}
</script>

<style lang="scss"></style>
