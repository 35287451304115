<template>
  <div>
    <section class="wrapper login-content common-height common-content">
      <div class="wrapper title-block">
        <h2 class="common-title">
          M &amp; A
        </h2>
      </div>
      <div class="banner-btns-block align-center">
        <AdsBox
          :adPositionCode="adPositionCode"
          :withCaption="false"
          boxClass="banner-btns-list-2"
        />
        <br />
        <a href="#" onclick="javascript:window.history.back(-1);return false;">
          <v-btn color="#F7B57F">
            <v-icon left dark>
              {{ icons.mdiArrowLeftCircle }}
            </v-icon>
            戻る
          </v-btn>
        </a>
      </div>
    </section>
  </div>
</template>

<script>
import AdsBox from '@/components/common/AdsBox'
import Common from '@/plugin/Common'
import { mdiArrowLeftCircle } from '@mdi/js'

export default {
  mixins: [Common],
  name: 'MAndA',
  components: {
    AdsBox
  },
  data: function() {
    return {
      icons: {
        mdiArrowLeftCircle
      },
      adPositionCode: '150' // 広告枠  オーナーサービス ＞ M&A
    }
  },
  created() {
    setTimeout(this.commonHeight, 10)
    // ロード時、リサイズ時にcommon-heightの高さを調整する
    window.addEventListener('load', this.commonHeight)
    window.addEventListener('resize', this.commonHeight)
    setTimeout(this.loadChatbotGeneral, 1000)
  },
  methods: {}
}
</script>

<style lang="scss"></style>
