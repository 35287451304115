"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * iMatch Api
 * iMatch Api
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: info@talllyho.jp
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConsultsApi = exports.ConsultsApiFactory = exports.ConsultsApiFp = exports.ConsultsApiAxiosParamCreator = exports.CarehomesApi = exports.CarehomesApiFactory = exports.CarehomesApiFp = exports.CarehomesApiAxiosParamCreator = exports.AuthApi = exports.AuthApiFactory = exports.AuthApiFp = exports.AuthApiAxiosParamCreator = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("./common");
// @ts-ignore
var base_1 = require("./base");
/**
 * AuthApi - axios parameter creator
 * @export
 */
exports.AuthApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary ログイン情報取得
         * @param {IMatchLoginInfoRequest} iMatchLoginInfoRequest ログイン情報取得リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoginInfo: function (iMatchLoginInfoRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'iMatchLoginInfoRequest' is not null or undefined
                    common_1.assertParamExists('getLoginInfo', 'iMatchLoginInfoRequest', iMatchLoginInfoRequest);
                    localVarPath = "/auth";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(iMatchLoginInfoRequest, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * AuthApi - functional programming interface
 * @export
 */
exports.AuthApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.AuthApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary ログイン情報取得
         * @param {IMatchLoginInfoRequest} iMatchLoginInfoRequest ログイン情報取得リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoginInfo: function (iMatchLoginInfoRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getLoginInfo(iMatchLoginInfoRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * AuthApi - factory interface
 * @export
 */
exports.AuthApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.AuthApiFp(configuration);
    return {
        /**
         *
         * @summary ログイン情報取得
         * @param {IMatchLoginInfoRequest} iMatchLoginInfoRequest ログイン情報取得リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLoginInfo: function (iMatchLoginInfoRequest, options) {
            return localVarFp.getLoginInfo(iMatchLoginInfoRequest, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
var AuthApi = /** @class */ (function (_super) {
    __extends(AuthApi, _super);
    function AuthApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary ログイン情報取得
     * @param {IMatchLoginInfoRequest} iMatchLoginInfoRequest ログイン情報取得リクエスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    AuthApi.prototype.getLoginInfo = function (iMatchLoginInfoRequest, options) {
        var _this = this;
        return exports.AuthApiFp(this.configuration).getLoginInfo(iMatchLoginInfoRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return AuthApi;
}(base_1.BaseAPI));
exports.AuthApi = AuthApi;
/**
 * CarehomesApi - axios parameter creator
 * @export
 */
exports.CarehomesApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary 施設合計数取得
         * @param {CarehomeSummaryInfoRequest} [carehomeSummaryInfoRequest] 施設数取得リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCarehomesSummary: function (carehomeSummaryInfoRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/carehomes/summary";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(carehomeSummaryInfoRequest, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * CarehomesApi - functional programming interface
 * @export
 */
exports.CarehomesApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.CarehomesApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary 施設合計数取得
         * @param {CarehomeSummaryInfoRequest} [carehomeSummaryInfoRequest] 施設数取得リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCarehomesSummary: function (carehomeSummaryInfoRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getCarehomesSummary(carehomeSummaryInfoRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * CarehomesApi - factory interface
 * @export
 */
exports.CarehomesApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.CarehomesApiFp(configuration);
    return {
        /**
         *
         * @summary 施設合計数取得
         * @param {CarehomeSummaryInfoRequest} [carehomeSummaryInfoRequest] 施設数取得リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCarehomesSummary: function (carehomeSummaryInfoRequest, options) {
            return localVarFp.getCarehomesSummary(carehomeSummaryInfoRequest, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * CarehomesApi - object-oriented interface
 * @export
 * @class CarehomesApi
 * @extends {BaseAPI}
 */
var CarehomesApi = /** @class */ (function (_super) {
    __extends(CarehomesApi, _super);
    function CarehomesApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary 施設合計数取得
     * @param {CarehomeSummaryInfoRequest} [carehomeSummaryInfoRequest] 施設数取得リクエスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CarehomesApi
     */
    CarehomesApi.prototype.getCarehomesSummary = function (carehomeSummaryInfoRequest, options) {
        var _this = this;
        return exports.CarehomesApiFp(this.configuration).getCarehomesSummary(carehomeSummaryInfoRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return CarehomesApi;
}(base_1.BaseAPI));
exports.CarehomesApi = CarehomesApi;
/**
 * ConsultsApi - axios parameter creator
 * @export
 */
exports.ConsultsApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary 入居相談一覧
         * @param {ConsultsMoveinResidentListRequest} consultsMoveinResidentListRequest 入居相談一覧取得リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMoveinResidentList: function (consultsMoveinResidentListRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'consultsMoveinResidentListRequest' is not null or undefined
                    common_1.assertParamExists('getMoveinResidentList', 'consultsMoveinResidentListRequest', consultsMoveinResidentListRequest);
                    localVarPath = "/consults/movein_resident_list";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(consultsMoveinResidentListRequest, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary 新着入居相談取得
         * @param {ConsultsMoveinResidentNewRequest} consultsMoveinResidentNewRequest 新着入居相談取得リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewMoveinResident: function (consultsMoveinResidentNewRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'consultsMoveinResidentNewRequest' is not null or undefined
                    common_1.assertParamExists('getNewMoveinResident', 'consultsMoveinResidentNewRequest', consultsMoveinResidentNewRequest);
                    localVarPath = "/consults/new_movein_resident";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(consultsMoveinResidentNewRequest, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary 入居者リクエスト
         * @param {ConsultsSendResidentConsultRequest} consultsSendResidentConsultRequest 入居者リクエスト情報リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registResidentConsult: function (consultsSendResidentConsultRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'consultsSendResidentConsultRequest' is not null or undefined
                    common_1.assertParamExists('registResidentConsult', 'consultsSendResidentConsultRequest', consultsSendResidentConsultRequest);
                    localVarPath = "/consults/send_resident_consult";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(consultsSendResidentConsultRequest, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary 取次申込依頼送信
         * @param {ConsultsSendIntermediateConsultRequest} consultsSendIntermediateConsultRequest 取次申込リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendIntermediateConsult: function (consultsSendIntermediateConsultRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'consultsSendIntermediateConsultRequest' is not null or undefined
                    common_1.assertParamExists('sendIntermediateConsult', 'consultsSendIntermediateConsultRequest', consultsSendIntermediateConsultRequest);
                    localVarPath = "/consults/send_intermediate_consult";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(consultsSendIntermediateConsultRequest, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary 退去相談依頼送信
         * @param {ConsultsSendMoveoutConsultRequest} consultsSendMoveoutConsultRequest 退去相談依リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMoveoutConsult: function (consultsSendMoveoutConsultRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'consultsSendMoveoutConsultRequest' is not null or undefined
                    common_1.assertParamExists('sendMoveoutConsult', 'consultsSendMoveoutConsultRequest', consultsSendMoveoutConsultRequest);
                    localVarPath = "/consults/send_moveout_consult";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(consultsSendMoveoutConsultRequest, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary 入居相談更新
         * @param {ConsultsMoveinResidentUpdateRequest} consultsMoveinResidentUpdateRequest 入居相談更新リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMoveinResident: function (consultsMoveinResidentUpdateRequest, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'consultsMoveinResidentUpdateRequest' is not null or undefined
                    common_1.assertParamExists('updateMoveinResident', 'consultsMoveinResidentUpdateRequest', consultsMoveinResidentUpdateRequest);
                    localVarPath = "/consults/update_movein_resident";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = common_1.serializeDataIfNeeded(consultsMoveinResidentUpdateRequest, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary 相談者情報アップロード
         * @param {string} [infoType] ファイル情報種別
         * @param {string} [careHomeId] 施設ID
         * @param {string} [entryId] 相談者ID
         * @param {any} [file] 相談者情報ファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFileInfo: function (infoType, careHomeId, entryId, file, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, localVarFormParams, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/consults/uploadfile";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
                    if (infoType !== undefined) {
                        localVarQueryParameter['info_type'] = infoType;
                    }
                    if (careHomeId !== undefined) {
                        localVarQueryParameter['care_home_id'] = careHomeId;
                    }
                    if (entryId !== undefined) {
                        localVarQueryParameter['entry_id'] = entryId;
                    }
                    if (file !== undefined) {
                        localVarFormParams.append('file', file);
                    }
                    localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
                    common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = localVarFormParams;
                    return [2 /*return*/, {
                            url: common_1.toPathString(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
/**
 * ConsultsApi - functional programming interface
 * @export
 */
exports.ConsultsApiFp = function (configuration) {
    var localVarAxiosParamCreator = exports.ConsultsApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary 入居相談一覧
         * @param {ConsultsMoveinResidentListRequest} consultsMoveinResidentListRequest 入居相談一覧取得リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMoveinResidentList: function (consultsMoveinResidentListRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getMoveinResidentList(consultsMoveinResidentListRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary 新着入居相談取得
         * @param {ConsultsMoveinResidentNewRequest} consultsMoveinResidentNewRequest 新着入居相談取得リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewMoveinResident: function (consultsMoveinResidentNewRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.getNewMoveinResident(consultsMoveinResidentNewRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary 入居者リクエスト
         * @param {ConsultsSendResidentConsultRequest} consultsSendResidentConsultRequest 入居者リクエスト情報リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registResidentConsult: function (consultsSendResidentConsultRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.registResidentConsult(consultsSendResidentConsultRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary 取次申込依頼送信
         * @param {ConsultsSendIntermediateConsultRequest} consultsSendIntermediateConsultRequest 取次申込リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendIntermediateConsult: function (consultsSendIntermediateConsultRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.sendIntermediateConsult(consultsSendIntermediateConsultRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary 退去相談依頼送信
         * @param {ConsultsSendMoveoutConsultRequest} consultsSendMoveoutConsultRequest 退去相談依リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMoveoutConsult: function (consultsSendMoveoutConsultRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.sendMoveoutConsult(consultsSendMoveoutConsultRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary 入居相談更新
         * @param {ConsultsMoveinResidentUpdateRequest} consultsMoveinResidentUpdateRequest 入居相談更新リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMoveinResident: function (consultsMoveinResidentUpdateRequest, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.updateMoveinResident(consultsMoveinResidentUpdateRequest, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary 相談者情報アップロード
         * @param {string} [infoType] ファイル情報種別
         * @param {string} [careHomeId] 施設ID
         * @param {string} [entryId] 相談者ID
         * @param {any} [file] 相談者情報ファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFileInfo: function (infoType, careHomeId, entryId, file, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.uploadFileInfo(infoType, careHomeId, entryId, file, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
/**
 * ConsultsApi - factory interface
 * @export
 */
exports.ConsultsApiFactory = function (configuration, basePath, axios) {
    var localVarFp = exports.ConsultsApiFp(configuration);
    return {
        /**
         *
         * @summary 入居相談一覧
         * @param {ConsultsMoveinResidentListRequest} consultsMoveinResidentListRequest 入居相談一覧取得リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMoveinResidentList: function (consultsMoveinResidentListRequest, options) {
            return localVarFp.getMoveinResidentList(consultsMoveinResidentListRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary 新着入居相談取得
         * @param {ConsultsMoveinResidentNewRequest} consultsMoveinResidentNewRequest 新着入居相談取得リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewMoveinResident: function (consultsMoveinResidentNewRequest, options) {
            return localVarFp.getNewMoveinResident(consultsMoveinResidentNewRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary 入居者リクエスト
         * @param {ConsultsSendResidentConsultRequest} consultsSendResidentConsultRequest 入居者リクエスト情報リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registResidentConsult: function (consultsSendResidentConsultRequest, options) {
            return localVarFp.registResidentConsult(consultsSendResidentConsultRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary 取次申込依頼送信
         * @param {ConsultsSendIntermediateConsultRequest} consultsSendIntermediateConsultRequest 取次申込リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendIntermediateConsult: function (consultsSendIntermediateConsultRequest, options) {
            return localVarFp.sendIntermediateConsult(consultsSendIntermediateConsultRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary 退去相談依頼送信
         * @param {ConsultsSendMoveoutConsultRequest} consultsSendMoveoutConsultRequest 退去相談依リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendMoveoutConsult: function (consultsSendMoveoutConsultRequest, options) {
            return localVarFp.sendMoveoutConsult(consultsSendMoveoutConsultRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary 入居相談更新
         * @param {ConsultsMoveinResidentUpdateRequest} consultsMoveinResidentUpdateRequest 入居相談更新リクエスト
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMoveinResident: function (consultsMoveinResidentUpdateRequest, options) {
            return localVarFp.updateMoveinResident(consultsMoveinResidentUpdateRequest, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary 相談者情報アップロード
         * @param {string} [infoType] ファイル情報種別
         * @param {string} [careHomeId] 施設ID
         * @param {string} [entryId] 相談者ID
         * @param {any} [file] 相談者情報ファイル
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFileInfo: function (infoType, careHomeId, entryId, file, options) {
            return localVarFp.uploadFileInfo(infoType, careHomeId, entryId, file, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
/**
 * ConsultsApi - object-oriented interface
 * @export
 * @class ConsultsApi
 * @extends {BaseAPI}
 */
var ConsultsApi = /** @class */ (function (_super) {
    __extends(ConsultsApi, _super);
    function ConsultsApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary 入居相談一覧
     * @param {ConsultsMoveinResidentListRequest} consultsMoveinResidentListRequest 入居相談一覧取得リクエスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultsApi
     */
    ConsultsApi.prototype.getMoveinResidentList = function (consultsMoveinResidentListRequest, options) {
        var _this = this;
        return exports.ConsultsApiFp(this.configuration).getMoveinResidentList(consultsMoveinResidentListRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary 新着入居相談取得
     * @param {ConsultsMoveinResidentNewRequest} consultsMoveinResidentNewRequest 新着入居相談取得リクエスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultsApi
     */
    ConsultsApi.prototype.getNewMoveinResident = function (consultsMoveinResidentNewRequest, options) {
        var _this = this;
        return exports.ConsultsApiFp(this.configuration).getNewMoveinResident(consultsMoveinResidentNewRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary 入居者リクエスト
     * @param {ConsultsSendResidentConsultRequest} consultsSendResidentConsultRequest 入居者リクエスト情報リクエスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultsApi
     */
    ConsultsApi.prototype.registResidentConsult = function (consultsSendResidentConsultRequest, options) {
        var _this = this;
        return exports.ConsultsApiFp(this.configuration).registResidentConsult(consultsSendResidentConsultRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary 取次申込依頼送信
     * @param {ConsultsSendIntermediateConsultRequest} consultsSendIntermediateConsultRequest 取次申込リクエスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultsApi
     */
    ConsultsApi.prototype.sendIntermediateConsult = function (consultsSendIntermediateConsultRequest, options) {
        var _this = this;
        return exports.ConsultsApiFp(this.configuration).sendIntermediateConsult(consultsSendIntermediateConsultRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary 退去相談依頼送信
     * @param {ConsultsSendMoveoutConsultRequest} consultsSendMoveoutConsultRequest 退去相談依リクエスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultsApi
     */
    ConsultsApi.prototype.sendMoveoutConsult = function (consultsSendMoveoutConsultRequest, options) {
        var _this = this;
        return exports.ConsultsApiFp(this.configuration).sendMoveoutConsult(consultsSendMoveoutConsultRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary 入居相談更新
     * @param {ConsultsMoveinResidentUpdateRequest} consultsMoveinResidentUpdateRequest 入居相談更新リクエスト
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultsApi
     */
    ConsultsApi.prototype.updateMoveinResident = function (consultsMoveinResidentUpdateRequest, options) {
        var _this = this;
        return exports.ConsultsApiFp(this.configuration).updateMoveinResident(consultsMoveinResidentUpdateRequest, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary 相談者情報アップロード
     * @param {string} [infoType] ファイル情報種別
     * @param {string} [careHomeId] 施設ID
     * @param {string} [entryId] 相談者ID
     * @param {any} [file] 相談者情報ファイル
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConsultsApi
     */
    ConsultsApi.prototype.uploadFileInfo = function (infoType, careHomeId, entryId, file, options) {
        var _this = this;
        return exports.ConsultsApiFp(this.configuration).uploadFileInfo(infoType, careHomeId, entryId, file, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return ConsultsApi;
}(base_1.BaseAPI));
exports.ConsultsApi = ConsultsApi;
