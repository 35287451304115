<template>
  <div>
    <section class="wrapper login-content common-height common-content">
      <div class="news-block">
        <div class="main-content content-inner">
          <div class="title">
            推奨環境
          </div>
          <table class="recommend-table">
            <tr>
              <td class="type" rowspan="2">PC</td>
              <td class="recommend">推奨OS</td>
              <td class="items">
                <ul>
                  <li>Windows 10 / 11</li>
                  <li>MacOSX 10.9 以上</li>
                  <li>Android 8 以上</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td class="recommend">推奨ブラウザ</td>
              <td class="items">
                <ul>
                  <li>Microsoft Edge（最新版）</li>
                  <li>Google Chrome（最新版）</li>
                  <li>Mozilla Firefox（最新版）</li>
                  <li>Safari（最新版）</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td class="type" rowspan="2">タブレット・スマートフォン</td>
              <td class="recommend">推奨OS</td>
              <td class="items">
                <ul>
                  <li>Android OS 8以降</li>
                  <li>iOS 10以降</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td class="recommend">推奨ブラウザ</td>
              <td class="items">
                <ul>
                  <li>Android：Chrome （最新版）</li>
                  <li>iOS：Safari （最新版）</li>
                </ul>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Common from '@/plugin/Common'

export default {
  mixins: [Common],
  name: 'RecommendedSystemRequirements',
  created() {
    setTimeout(this.commonHeight, 10)
    // ロード時、リサイズ時にcommon-heightの高さを調整する
    window.addEventListener('load', this.commonHeight)
    window.addEventListener('resize', this.commonHeight)
    setTimeout(this.loadChatbotGeneral, 1000)
  }
}
</script>

<style lang="scss"></style>
