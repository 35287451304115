<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <picture>
        <source
          srcset="../../../assets/img/introduce-resident-service/agency_information.avif"
          type="image/avif"
        />
        <source
          srcset="../../../assets/img/introduce-resident-service/agency_information.webp"
          type="image/webp"
        />
        <img
          src="../../../assets/img/introduce-resident-service/agency_information.png"
          alt="取次申込"
          v-bind="attrs"
          v-on="on"
        />
      </picture>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">取次申込</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-card class="pa-1" outlined tile>
                <v-select
                  :items="careHomeItems"
                  v-model="selectCareHome"
                  item-text="care_home_name"
                  item-value="care_home_id"
                  label="施設id"
                  dense
                  outlined
                  hide-details
                  @change="changeCareHomeInfo"
                ></v-select>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                v-model="requestInfo.consult_info_content"
                label="取次申込内容"
                persistent-hint
                required
                background-color="amber lighten-4"
                color="orange orange-darken-4"
                counter="255"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-file-input
                v-model="consultInfoFile"
                label="取次情報関連ファイル(5MbまでのPDFもしくは画像)"
                accept=".png,.jpg,.jpeg,.pdf"
                outlined
                dense
                :prepend-icon="icons.mdiCloudUpload"
              ></v-file-input>
              <v-checkbox
                label="取次情報を紹介のプロに送信し関わる事を関係者に了承を得ている"
                color="primary"
                value="primary"
                hide-details
                v-model="isApproved"
                @change="checkApproved"
              ></v-checkbox>
              <v-btn color="blue darken-1" text @click="goToPrivacy">
                個人情報の取扱いについて
              </v-btn>
              <v-checkbox
                label="株式会社i.ホールディングスと契約済"
                color="primary"
                value="primary"
                hide-details
                v-model="isIHContract"
                @change="checkApproved"
              ></v-checkbox>
              <v-card>
                取次申込のため別途、株式会社i.ホールディングスと契約が必要です。
              </v-card>
              <v-btn color="blue darken-1" text @click="goToContactIHoldings">
                問い合わせはこちら
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDialog">
          キャンセル
          <v-icon right dark>
            {{ icons.mdiCloseCircle }}
          </v-icon>
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          @click="confirmSendMail"
          :disabled="!isSendEnabled"
        >
          取次申込依頼送信
          <v-icon right dark>
            {{ icons.mdiEmailSendOutline }}
          </v-icon>
        </v-btn>
        <v-progress-circular
          indeterminate
          color="primary"
          v-if="isSending"
        ></v-progress-circular>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Common from '@/plugin/Common'
import { mdiEmailSendOutline, mdiCloseCircle, mdiCloudUpload } from '@mdi/js'
import * as apiclient from '@/apiclient'

export default {
  mixins: [Common],
  data: () => ({
    dialog: false,
    icons: {
      mdiEmailSendOutline,
      mdiCloseCircle,
      mdiCloudUpload
    },
    selectCareHome: '0',
    requestInfo: {
      consult_info_content: '',
      remarks: '',
      file_key: ''
    },
    consultInfoFile: null,
    isSendEnabled: false,
    isSending: false,
    isApproved: false,
    isIHContract: false
  }),
  props: {
    careHomeItems: null,
    careHome: {}
  },
  updated() {
    this.selectCareHome = this.careHome.care_home_id
  },
  methods: {
    changeCareHomeInfo: function() {
      try {
        console.log('this.selectCareHome' + this.selectCareHome)
        if (this.isEmpty(this.selectCareHome)) {
          return
        }
        let filterValue = this.selectCareHome
        let filterResult = this.careHomeItems.filter(function(e) {
          return e.care_home_id == filterValue
        })
        console.log('this.filterResult' + this.filterResult)
        this.careHome = filterResult[0]
      } catch (error) {
        throw error.response
      }
    },
    confirmSendMail() {
      if (confirm('取次申込の依頼を紹介のプロの担当者に送信しますか？')) {
        this.isSendEnabled = false
        this.isSending = true
        this.uploadFile()
      }
    },
    uploadFile: function() {
      try {
        if (this.consultInfoFile != null) {
          var uploadparams = {
            infoType: '003',
            careHomeId: this.careHome.care_home_id,
            entryId: ''
          }
          apiclient.IMatchConsultsApi.uploadFileInfo(
            uploadparams,
            this.consultInfoFile,
            this.sendMail
          )
        } else {
          this.sendMail({ payload: { key: '' } })
        }
      } catch (error) {
        console.error(error)
        this.alertFailSend()
        this.closeDialog()
      }
    },
    sendMail: function(uploadResponseData) {
      try {
        if (uploadResponseData == null) {
          this.alertFailSend()
          return
        }
        let fileKey = uploadResponseData.payload.key
        this.requestInfo.file_key = fileKey

        var params = {
          care_home_info: this.careHome,
          intermediate_consult_info: this.requestInfo
        }
        apiclient.IMatchConsultsApi.sendIntermediateConsult(
          params,
          this.sendMailComplete
        )
      } catch (error) {
        console.error(error)
        this.alertFailSend()
        this.closeDialog()
      }
    },
    sendMailComplete: function(responseData) {
      console.log('sendMailComplete=' + responseData)
      if (responseData != null) {
        if (responseData.common.code == 0) {
          alert('取次申込の依頼が送信されました')
        } else {
          this.alertFailSend()
        }
      } else {
        this.alertFailSend()
      }
      this.closeDialog()
    },
    alertFailSend() {
      alert('送信に失敗しました。しばらく待機してから再送信してください。')
    },
    resetDialog() {
      this.consultInfoFile = null
      this.requestInfo = {}
      this.isApproved = false
      this.isIHContract = false
    },
    closeDialog() {
      this.isSending = false
      this.dialog = false
      this.isSendEnabled = false
      this.resetDialog()
    },
    checkApproved: function(event) {
      this.isSendEnabled = this.isApproved && this.isIHContract
    },
    goToPrivacy() {
      window.open('https://introduce-kaigo.com/privacy/', '_blank')
    },
    goToContactIHoldings() {
      window.open('https://introduce-kaigo.com/contact/', '_blank')
    }
  }
}

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
}
</script>
