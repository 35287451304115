<script>
import moment from 'moment'

export default {
  filters: {
    moment(date) {
      return moment(date).format('YYYY.MM.DD')
    },
    momentDetail(date) {
      return moment(date).format('YYYY.MM.DD h:mm')
    },
    truncate(data, length) {
      data = data.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '')
      if (data.length > length) {
        return data.substring(0, length) + '...'
      } else {
        return data
      }
    }
  }
}
</script>
