<template>
  <div>
    <v-app>
      <section class="wrapper login-content common-height common-content">
        <div class="wrapper title-block">
          <h2 class="common-title">
            入居者情報（詳細）
          </h2>
        </div>
        <div class="dxservices-block">
          <v-container class="grey lighten-4">
            <v-row no-gutters>
              <v-col>
                <v-card class="pa-1" outlined tile>
                  <v-select
                    :items="careHomesItems"
                    v-model="residentInfo.care_home_id"
                    item-text="care_home_name"
                    item-value="care_home_id"
                    label="施設id"
                    no-data-text="施設が登録されていません"
                    dense
                    outlined
                    hide-details
                  ></v-select>
                </v-card>
              </v-col>
            </v-row>
            <!-- 状況 -->
            <v-row no-gutters>
              <v-col><v-card color="primary">■状況</v-card></v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="4">
                <v-card class="pa-1" outlined tile>
                  <v-select
                    :items="statusItems"
                    v-model="residentInfo.status_code"
                    item-text="label"
                    item-value="value"
                    label="対応状況"
                    dense
                    outlined
                    hide-details
                  ></v-select>
                </v-card>
              </v-col>
              <v-col cols="8">
                <v-row no-gutters>
                  <v-col>
                    <v-card class="pa-1" outlined tile>
                      入居者id
                    </v-card>
                  </v-col>
                  <v-col>
                    <v-card class="pa-1" outlined tile>
                      登録日
                    </v-card>
                  </v-col>
                  <v-col>
                    <v-card class="pa-1" outlined tile>
                      最終更新日
                    </v-card>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col>
                    <v-card class="pa-1" outlined tile>
                      {{ residentInfo.resident_id }}
                    </v-card>
                  </v-col>
                  <v-col>
                    <v-card class="pa-1" outlined tile>
                      {{ residentInfo.create_date }}
                    </v-card>
                  </v-col>
                  <v-col>
                    <v-card class="pa-1" outlined tile>
                      {{ residentInfo.update_date }}
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="3">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="residentInfo.room_no"
                    required
                    outlined
                    hide-details
                    dense
                    label="部屋番号"
                    max="8"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
              <v-col cols="3">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="residentInfo.expected_move_in_date"
                    required
                    outlined
                    hide-details
                    dense
                    label="入居予定日"
                    max="8"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
              <v-col cols="3">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="residentInfo.move_in_date"
                    required
                    outlined
                    hide-details
                    dense
                    label="入居日（契約日）"
                    max="8"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
              <v-col cols="3">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="residentInfo.expected_move_out_date"
                    required
                    outlined
                    hide-details
                    dense
                    label="退去予定日"
                    max="8"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="3">
                <v-card></v-card>
              </v-col>
              <v-col cols="3">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="residentInfo.move_out_date"
                    required
                    outlined
                    hide-details
                    dense
                    label="退去日"
                    max="8"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
              <v-col cols="3">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="residentInfo.hospitalization_date"
                    required
                    outlined
                    hide-details
                    dense
                    label="入院日"
                    max="8"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
              <v-col cols="3">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="residentInfo.discharged_date"
                    required
                    outlined
                    hide-details
                    dense
                    label="退院日"
                    max="8"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>
            <!-- 基本情報 -->
            <v-row no-gutters>
              <v-col><v-card color="primary">■基本情報</v-card></v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="3">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="residentInfo.family_name"
                    required
                    outlined
                    hide-details
                    dense
                    label="姓"
                    max="8"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
              <v-col cols="3">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="residentInfo.first_name"
                    required
                    outlined
                    hide-details
                    dense
                    label="名"
                    max="8"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
              <v-col cols="3">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="residentInfo.family_name_kana"
                    required
                    outlined
                    hide-details
                    dense
                    label="姓（かな）"
                    max="8"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
              <v-col cols="3">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="residentInfo.first_name_kana"
                    required
                    outlined
                    hide-details
                    dense
                    label="名（かな）"
                    max="8"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="3">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="residentInfo.birthday"
                    required
                    outlined
                    hide-details
                    dense
                    label="生年月日"
                    max="8"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
              <v-col cols="3">
                <v-card class="pa-1" outlined tile>
                  <v-card class="pa-1" outlined tile>
                    年齢:{{ residentAge }}
                  </v-card>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card class="pa-1" outlined tile>
                  <v-radio-group
                    v-model="residentInfo.sex_code"
                    label="性別"
                    row
                  >
                    <v-radio
                      label="男性"
                      color="primary"
                      value="010"
                      hide-details
                    ></v-radio>
                    <v-radio
                      label="女性"
                      color="primary"
                      value="020"
                      hide-details
                    ></v-radio>
                  </v-radio-group>
                </v-card>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="2">
                <v-card class="pa-1" outlined tile>
                  住所
                </v-card>
              </v-col>
              <v-col cols="2">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="residentInfo.zip_code"
                    required
                    outlined
                    hide-details
                    dense
                    label="郵便番号"
                    max="8"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card class="pa-1" outlined tile>
                  <v-select
                    :items="prefecturesItems"
                    v-model="residentInfo.prefecture_code"
                    item-text="label"
                    item-value="value"
                    label="都道府県"
                    dense
                    outlined
                    hide-details
                    @change="getCities"
                  ></v-select>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card class="pa-1" outlined tile>
                  <v-select
                    :items="citiesItems"
                    v-model="residentInfo.city_code"
                    item-text="label"
                    item-value="value"
                    label="市区町村"
                    dense
                    outlined
                    hide-details
                  ></v-select>
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="2"> </v-col>
              <v-col cols="10">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="residentInfo.address1"
                    required
                    outlined
                    hide-details
                    dense
                    label="町名・番地"
                    max="50"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="2"> </v-col>
              <v-col cols="10">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="residentInfo.address2"
                    required
                    outlined
                    hide-details
                    dense
                    label="建物名・部屋番号"
                    max="50"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="2"> </v-col>
              <v-col cols="4">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="residentInfo.tel"
                    required
                    outlined
                    hide-details
                    dense
                    label="電話番号（携帯電話）"
                    max="50"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
              <v-col cols="3">
                <v-card class="pa-1" outlined tile>
                  <v-select
                    :items="imcomeCategories"
                    v-model="residentInfo.imcome_category_code"
                    item-text="label"
                    item-value="value"
                    label="収入区分"
                    dense
                    outlined
                    hide-details
                  ></v-select>
                </v-card>
              </v-col>
              <v-col cols="3">
                <v-card class="pa-1" outlined tile>
                  <v-select
                    :items="careLevels"
                    v-model="residentInfo.care_level_code"
                    item-text="label"
                    item-value="value"
                    label="介護度"
                    dense
                    outlined
                    hide-details
                  ></v-select>
                </v-card>
              </v-col>
            </v-row>
            <!-- ■福祉事務所情報 -->
            <v-row no-gutters>
              <v-col
                ><v-card color="primary"
                  >■福祉事務所情報（※収入区分が生活保護の場合）</v-card
                ></v-col
              >
            </v-row>
            <v-row no-gutters>
              <v-col cols="6">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="residentInfo.city_hall"
                    required
                    outlined
                    hide-details
                    dense
                    label="担当役所"
                    max="50"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="residentInfo.caseworker_name"
                    required
                    outlined
                    hide-details
                    dense
                    label="担当ケースワーカー名"
                    max="50"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>

            <!-- ■■キーパーソン情報 -->
            <v-row no-gutters>
              <v-col
                ><v-card color="primary"
                  >■キーパーソン情報（緊急連絡先）</v-card
                ></v-col
              >
            </v-row>
            <v-row no-gutters>
              <v-col>
                キーパーソン情報①
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="5">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="residentInfo.key_person01_tel"
                    required
                    outlined
                    hide-details
                    dense
                    label="電話番号"
                    max="50"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
              <v-col cols="5">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="residentInfo.key_person01_name"
                    required
                    outlined
                    hide-details
                    dense
                    label="氏名"
                    max="50"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
              <v-col cols="2">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="residentInfo.key_person01_relationship"
                    required
                    outlined
                    hide-details
                    dense
                    label="続柄"
                    max="50"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                キーパーソン情報②
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="5">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="residentInfo.key_person02_tel"
                    required
                    outlined
                    hide-details
                    dense
                    label="電話番号"
                    max="50"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
              <v-col cols="5">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="residentInfo.key_person02_name"
                    required
                    outlined
                    hide-details
                    dense
                    label="氏名"
                    max="50"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
              <v-col cols="2">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="residentInfo.key_person02_relationship"
                    required
                    outlined
                    hide-details
                    dense
                    label="続柄"
                    max="50"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>

            <!-- 介護保険情報 -->
            <v-row no-gutters>
              <v-col><v-card color="primary">■介護保険情報</v-card></v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="5">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="residentInfo.care_insurance_no"
                    required
                    outlined
                    hide-details
                    dense
                    label="被保険者番号"
                    max="50"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
              <v-col cols="3">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="residentInfo.care_expiry_date_from"
                    required
                    outlined
                    hide-details
                    dense
                    label="有効期限（開始）"
                    max="50"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
              <v-col cols="3">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="residentInfo.care_expiry_date_to"
                    required
                    outlined
                    hide-details
                    dense
                    label="有効期限（終了）"
                    max="50"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="5">
                <v-card class="pa-1" outlined tile>
                  <v-select
                    :items="careBurdenRatios"
                    v-model="residentInfo.care_burden_ratio_code"
                    item-text="label"
                    item-value="value"
                    label="介護保険負担割合"
                    dense
                    outlined
                    hide-details
                  ></v-select>
                </v-card>
              </v-col>
            </v-row>

            <!-- 医療保険情報 -->
            <v-row no-gutters>
              <v-col><v-card color="primary">■医療保険情報</v-card></v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                ※後期高齢者医療被保険者証の方
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="3">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="residentInfo.elderly_insurance_Insured_number"
                    required
                    outlined
                    hide-details
                    dense
                    label="被保険者番号"
                    max="50"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
              <v-col cols="3">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="residentInfo.elderly_insurance_expiry_date"
                    required
                    outlined
                    hide-details
                    dense
                    label="有効期限"
                    max="50"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
              <v-col cols="3">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="residentInfo.elderly_insurance_insurance_no"
                    required
                    outlined
                    hide-details
                    dense
                    label="保険者番号"
                    max="50"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
              <v-col cols="3">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="residentInfo.elderly_insurance_Insurance_union"
                    required
                    outlined
                    hide-details
                    dense
                    label="保険社名（都道府県）"
                    max="50"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                ※上記以外の方
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="3">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="residentInfo.medical_insurance_kigou"
                    required
                    outlined
                    hide-details
                    dense
                    label="記号"
                    max="50"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
              <v-col cols="3">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="residentInfo.medical_insurance_bangou"
                    required
                    outlined
                    hide-details
                    dense
                    label="番号"
                    max="50"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
              <v-col cols="3">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="residentInfo.medical_qualification_date"
                    required
                    outlined
                    hide-details
                    dense
                    label="資格取得年月日"
                    max="50"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
              <v-col cols="3">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="residentInfo.medical_insurance_no"
                    required
                    outlined
                    hide-details
                    dense
                    label="保険者番号"
                    max="50"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="5">
                <v-card class="pa-1" outlined tile>
                  <v-select
                    :items="medicalBurdenRatios"
                    v-model="residentInfo.medical_burden_ratio_code"
                    item-text="label"
                    item-value="value"
                    label="医療保険負担割合"
                    dense
                    outlined
                    hide-details
                  ></v-select>
                </v-card>
              </v-col>
            </v-row>

            <!-- 関係書類 -->
            <v-row no-gutters>
              <v-col><v-card color="primary">■関係書類</v-card></v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="3">
                <v-card class="pa-1" outlined tile>
                  <v-select
                    :items="fileUploadCategories"
                    v-model="fileUploadCategoryCode"
                    item-text="label"
                    item-value="value"
                    label="書類カテゴリ"
                    dense
                    outlined
                    hide-details
                  ></v-select>
                </v-card>
              </v-col>
              <v-col cols="7">
                <v-card class="pa-1" outlined tile>
                  <v-file-input
                    v-model="residentInfoFile"
                    label="関係書類(5MbまでのPDFもしくは画像)"
                    accept=".png,.jpg,.jpeg,.pdf"
                    outlined
                    dense
                    hide-details
                  ></v-file-input>
                </v-card>
              </v-col>
              <v-col cols="2">
                <v-card class="pa-1" outlined tile @click="uploadFile">
                  <v-btn color="upload">
                    <v-icon left dark>
                      {{ icons.mdiCloudUpload }}
                    </v-icon>
                    アップロード
                  </v-btn>
                </v-card>
              </v-col>
            </v-row>
            <v-row
              no-gutters
              v-for="fileItem in residentInfoFileList"
              :key="fileItem.file_id"
            >
              <v-col cols="3">
                <v-card class="file-category">
                  <span> {{ fileItem.category_name }}</span>
                </v-card>
              </v-col>
              <v-col cols="7">
                <v-card v-if="fileItem.is_new">
                  <span class="file-isnew-icon">NEW</span>
                  <span class="file-isnew">{{ fileItem.file_name }}</span>
                </v-card>
                <v-card v-else>
                  <v-btn
                    color="download"
                    text
                    small
                    @click="downloadFile(fileItem)"
                  >
                    <v-icon left>
                      {{ icons.mdiDownloadCircle }}
                    </v-icon>
                    {{ fileItem.file_name }}
                  </v-btn>
                </v-card>
              </v-col>
              <v-col cols="2">
                <v-card outlined tile>
                  <v-btn color="secondary" small @click="deleteFile(fileItem)">
                    <v-icon left>
                      {{ icons.mdiDeleteForever }}
                    </v-icon>
                    削除
                  </v-btn>
                </v-card>
              </v-col>
            </v-row>

            <!-- 訪問主治医 -->
            <v-row no-gutters>
              <v-col><v-card color="primary">■訪問主治医</v-card></v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="6">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="residentInfo.primary_doctor_hospital"
                    required
                    outlined
                    hide-details
                    dense
                    label="病院名"
                    max="50"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="residentInfo.primary_doctor_name"
                    required
                    outlined
                    hide-details
                    dense
                    label="担当医師"
                    max="50"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="4">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="residentInfo.primary_doctor_tel"
                    required
                    outlined
                    hide-details
                    dense
                    label="電話番号"
                    max="50"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="residentInfo.primary_doctor_fax"
                    required
                    outlined
                    hide-details
                    dense
                    label="FAX番号"
                    max="50"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="residentInfo.primary_doctor_emergency_tel"
                    required
                    outlined
                    hide-details
                    dense
                    label="夜間連絡先"
                    max="50"
                    min="1"
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>

            <!-- 備考情報 -->
            <v-row no-gutters>
              <v-col><v-card color="primary">■備考</v-card></v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12">
                <v-card class="pa-1" outlined tile>
                  <v-textarea
                    v-model="residentInfo.memo"
                    label="備考"
                    persistent-hint
                    outlined
                    counter="255"
                  ></v-textarea>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
          <br />
          <CommonDialog
            :name="updateButtonName"
            :title="'入居者情報' + updateButtonName"
            :message="'入居者情報を' + updateButtonName + 'しますか？'"
            color="#94c43c"
            class-name="request-btn"
            v-on:acceptAction="updateResidentInfo"
          ></CommonDialog>
          <router-link to="/dx-services/dx-redidents-list">
            <v-btn color="primary">
              <v-icon left dark>
                {{ icons.mdiArrowLeftCircle }}
              </v-icon>
              戻る
            </v-btn>
          </router-link>
        </div>
      </section>
    </v-app>
  </div>
</template>

<script>
import Common from '@/plugin/Common'
import * as apiclient from '@/apiclient'
import CommonDialog from '@/components/common/CommonDialog'
import {
  mdiArrowLeftCircle,
  mdiCloudUpload,
  mdiDeleteForever,
  mdiDownloadCircle
} from '@mdi/js'
import moment from 'moment'

export default {
  mixins: [Common],
  name: 'DxRedidentsEdit',
  components: {
    CommonDialog
  },
  created() {
    setTimeout(this.loadChatbotGeneral, 1000)
    this.getPrefectures()
    this.getAllCodes()
    this.getLoginInfo()
  },
  props: ['residentid'],
  data: function() {
    return {
      careHomesItems: [],
      statusItems: [],
      imcomeCategories: [],
      careLevels: [],
      careBurdenRatios: [],
      medicalBurdenRatios: [],
      fileUploadCategories: [],
      fileUploadCategoryCode: null,
      residentInfoFile: null,
      residentInfoFileList: [],
      residentInfo: {
        resident_id: '',
        resident_name: '',
        care_home_id: '',
        status_code: '010',
        room_no: '',
        expected_move_in_date: '',
        move_in_date: '',
        expected_move_out_date: '',
        move_out_date: '',
        hospitalization_date: '',
        discharged_date: '',
        family_name: '',
        first_name: '',
        family_name_kana: '',
        first_name_kana: '',
        birthday: '',
        sex_code: '',
        zip_code: '',
        prefecture_code: '',
        city_code: '',
        address1: '',
        address2: '',
        tel: '',
        imcome_category_code: '',
        care_level_code: '',
        city_hall: '',
        caseworker_name: '',
        key_person01_tel: '',
        key_person01_name: '',
        key_person01_relationship: '',
        key_person02_tel: '',
        key_person02_name: '',
        key_person02_relationship: '',
        care_insurance_no: '',
        care_expiry_date_from: '',
        care_expiry_date_to: '',
        care_burden_ratio_code: '',
        elderly_insurance_Insured_number: '',
        elderly_insurance_expiry_date: '',
        elderly_insurance_insurance_no: '',
        elderly_insurance_Insurance_union: '',
        medical_insurance_kigou: '',
        medical_insurance_bangou: '',
        medical_qualification_date: '',
        medical_insurance_no: '',
        medical_burden_ratio_code: '',
        primary_doctor_hospital: '',
        primary_doctor_name: '',
        primary_doctor_tel: '',
        primary_doctor_fax: '',
        primary_doctor_emergency_tel: '',
        create_date: '',
        update_date: '',
        memo: '',
        file_id_list: []
      },
      prefecturesItems: [],
      citiesItems: [],
      icons: {
        mdiArrowLeftCircle,
        mdiCloudUpload,
        mdiDeleteForever,
        mdiDownloadCircle
      },
      isAllLevelsChecked: false
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    isNew() {
      if (this.isEmpty(this.residentid)) {
        return true
      }
      return false
    },
    updateButtonName() {
      if (this.isNew) {
        return '新規登録'
      }
      return '更新'
    },
    residentAge() {
      try {
        var age = moment().diff(
          moment(new Date(this.residentInfo.birthday)),
          'years'
        )
        if (age == null || isNaN(age) || age <= 0) {
          return ''
        }
        return age
      } catch (error) {
        return ''
      }
    }
  },
  methods: {
    getLoginInfo: function() {
      try {
        apiclient.TsunaguwaAuthApi.getLoginInfo((responseData) => {
          this.careHomesItems = responseData.payload.user_care_homes
          if (this.careHomesItems.length > 0) {
            this.getResidentInfo()
          }
        })
      } catch (error) {
        throw error.response
      }
    },
    getResidentInfo: function() {
      try {
        if (this.isNew) {
          this.residentInfo.care_home_id = this.careHomesItems[0].care_home_id
          return
        }
        var params = {
          resident_id: this.residentid
        }
        apiclient.TsunaguwaDxCarehomesApi.getResident((responseData) => {
          this.residentInfo = responseData.payload
          this.getCities()
          this.getResidentInfoFiles()
        }, params)
      } catch (error) {
        throw error.response
      }
    },
    getPrefectures: function() {
      try {
        apiclient.TsunaguwaCommonApi.getPrefectures((responseData) => {
          this.prefecturesItems = responseData.payload.items
        })
      } catch (error) {
        throw error.response
      }
    },
    getCities: function() {
      try {
        if (this.isEmpty(this.residentInfo.prefecture_code)) {
          return
        }
        var prefectureCode = this.residentInfo.prefecture_code
        apiclient.TsunaguwaCommonApi.getCities((responseData) => {
          this.citiesItems = responseData.payload.items
        }, prefectureCode)
      } catch (error) {
        throw error.response
      }
    },
    getAllCodes: function() {
      try {
        // status_code '状況コード(3010)'
        var mkey = '3010'
        apiclient.TsunaguwaCommonApi.getMisc((responseData) => {
          this.statusItems = responseData.payload.items
        }, mkey)
        // imcome_category_code '収入区分コード(3030)'
        mkey = '3030'
        apiclient.TsunaguwaCommonApi.getMisc((responseData) => {
          this.imcomeCategories = responseData.payload.items
        }, mkey)
        // care_level_code '介護度コード(3050)'
        mkey = '3050'
        apiclient.TsunaguwaCommonApi.getMisc((responseData) => {
          this.careLevels = responseData.payload.items
        }, mkey)
        // care_burden_ratio_code '介護保険_負担割合コード(3060)'
        mkey = '3060'
        apiclient.TsunaguwaCommonApi.getMisc((responseData) => {
          this.careBurdenRatios = responseData.payload.items
        }, mkey)
        // medical_burden_ratio_code '医療保険_負担割合コード(3070)'
        mkey = '3070'
        apiclient.TsunaguwaCommonApi.getMisc((responseData) => {
          this.medicalBurdenRatios = responseData.payload.items
        }, mkey)
        // fileUploadCategoryCode '関係書類カテゴリコード(3080)'
        mkey = '3080'
        apiclient.TsunaguwaCommonApi.getMisc((responseData) => {
          this.fileUploadCategories = responseData.payload.items
        }, mkey)
      } catch (error) {
        throw error.response
      }
    },
    updateResidentInfo: function() {
      if (
        this.isEmpty(this.residentInfo.care_home_id) ||
        this.residentInfo.care_home_id == '0'
      ) {
        alert(
          'DXサービスの利用には施設情報の事前登録が必要です。管理者にお問い合わせください。'
        )
        return
      }
      let fileIdList = []
      if (!this.isEmpty(this.residentInfoFileList)) {
        this.residentInfoFileList.forEach((fileItem) => {
          fileIdList.push(fileItem.file_id)
        })
      }
      this.residentInfo.file_id_list = fileIdList

      var params = {
        resident: this.residentInfo
      }
      if (this.isNew) {
        apiclient.TsunaguwaDxCarehomesApi.registResident((responseData) => {
          if (responseData != null) {
            alert('入居者情報を新規登録しました。')
            this.residentid = responseData.payload.resident_id
            this.$router.push({
              name: 'DxRedidentsEdit',
              params: { residentid: responseData.payload.resident_id }
            })
            this.getResidentInfo()
          } else {
            alert('新規登録に失敗しました。')
          }
        }, params)
      } else {
        apiclient.TsunaguwaDxCarehomesApi.updateResident((responseData) => {
          if (responseData != null) {
            alert('入居者情報を更新しました。')
            this.getResidentInfoFiles()
          } else {
            alert('更新に失敗しました。')
          }
        }, params)
      }
    },
    uploadFile: function() {
      if (
        this.residentInfoFile != null &&
        !this.isEmpty(this.fileUploadCategoryCode)
      ) {
        var uploadparams = {
          infoType: 'F002',
          careHomeId: this.residentInfo.care_home_id,
          categoryCode: this.fileUploadCategoryCode
        }
        apiclient.TsunaguwaDxCarehomesApi.uploadResidentFileInfo(
          uploadparams,
          this.residentInfoFile,
          this.reloadResidentInfoFiles
        )
      }
    },
    reloadResidentInfoFiles: function(uploadResponseData) {
      if (uploadResponseData == null) {
        this.alertFailSend()
        return
      }
      this.fileUploadCategoryCode = null
      this.residentInfoFile = null
      let newFileItem = uploadResponseData.payload.file_info
      newFileItem.is_new = true
      this.residentInfoFileList.push(newFileItem)
    },
    getResidentInfoFiles: function() {
      var params = {
        resident_id: this.residentid
      }
      apiclient.TsunaguwaDxCarehomesApi.getResidentInfoFiles((responseData) => {
        if (responseData != null) {
          this.residentInfoFileList = responseData.payload.files_list
        } else {
          this.residentInfoFileList = []
        }
      }, params)
    },
    downloadFile: function(fileItem) {
      if (!this.isEmpty(fileItem.file_key)) {
        window.open(fileItem.file_key, '_blank')
      }
    },
    deleteFile: function(fileItem) {
      if (!this.isEmpty(this.residentInfoFileList)) {
        this.residentInfoFileList = this.residentInfoFileList.filter(function(
          e
        ) {
          return e.file_id != fileItem.file_id
        })
      }
    },
    alertFailSend() {
      alert(
        'アップロードに失敗しました。しばらく待機してから再送信してください。'
      )
    }
  }
}
</script>

<style lang="scss">
.file-category {
  text-align: center;
  padding: 2px;
  margin-left: 24px;
  border-radius: 10px;
}
.file-isnew-icon {
  font-size: 0.625rem;
  width: 20px;
  text-align: center;
  background-color: $red;
  padding: 2px;
  margin-left: 5px;
  color: $white;
  border-radius: 5px;
}
.file-isnew {
  font-size: 0.625rem;
  text-align: left;
  padding: 2px;
  margin-left: 5px;
  color: $black;
}
</style>
