<template>
  <div>
    <v-app>
      <section
        class="consults-wrapper login-content common-height common-content"
      >
        <div class="wrapper title-block">
          <h2 class="common-title">
            入居相談
          </h2>
        </div>
        <div class="consults-block">
          <v-card class="pa-1" outlined tile>
            <v-select
              :items="careHomeItems"
              v-model="selectCareHome"
              item-text="care_home_name"
              item-value="care_home_id"
              label="施設id"
              dense
              outlined
              hide-details
              @change="changeCareHomeInfo"
            ></v-select>
          </v-card>

          <table
            class="consults-table"
            v-if="residentsList != null && residentsList.length > 0"
          >
            <tr>
              <th></th>
              <th>性別</th>
              <th>年齢</th>
              <th>介護度</th>
              <th>収入区分</th>
              <th>現居住先</th>
              <th>相談日</th>
              <th>
                相談者情報 <br />
                ダウンロード
              </th>
              <th>対応状況</th>
            </tr>
            <tr v-for="item in residentsList" :key="item.name">
              <td class="new">
                <span v-if="item.consult_flag == '1'" class="consult-isnew">
                  NEW
                </span>
                <span v-else> </span>
              </td>
              <td class="name">
                {{ item.gender }}
              </td>
              <td class="name">
                {{ item.age }}
              </td>
              <td class="name">
                {{ item.nursing_care_level_name }}
              </td>
              <td>
                {{ item.pension }}
              </td>
              <td class="name">
                {{ item.actual_living }}
              </td>
              <td class="date">
                {{ item.consult_date }}
              </td>
              <td class="downloads">
                <div class="btn-group">
                  <v-btn
                    v-if="item.status != '2'"
                    color="download"
                    class="action-btn"
                    elevation="3"
                    @click="downloadPdf(item)"
                  >
                    PDF
                    <v-icon right dark>
                      {{ icons.mdiDownloadCircle }}
                    </v-icon>
                  </v-btn>
                </div>
              </td>
              <td class="actions">
                <div v-if="item.status == '0'" class="btn-group">
                  <v-btn
                    color="primary"
                    class="action-btn"
                    elevation="3"
                    @click="updateSuport(item, '1')"
                  >
                    対応可
                    <v-icon right dark>
                      {{ icons.mdiCheckUnderlineCircle }}
                    </v-icon>
                  </v-btn>
                  <v-btn
                    color="accent"
                    class="action-btn"
                    elevation="3"
                    @click="updateSuport(item, '2')"
                  >
                    対応不可
                    <v-icon right dark>
                      {{ icons.mdiCloseCircle }}
                    </v-icon>
                  </v-btn>
                </div>
                <div v-if="item.status != '0'" class="btn-group">
                  <v-btn
                    v-if="item.status == '1'"
                    color="disabled"
                    class="action-btn"
                    elevation="3"
                    disabled
                  >
                    相談中
                  </v-btn>
                  <v-btn
                    v-if="item.status == '2'"
                    color="disabled"
                    class="action-btn"
                    elevation="3"
                    disabled
                  >
                    他で決定済
                  </v-btn>
                  <!--
                  <v-btn color="error" class="action-btn" elevation="3">
                    取り消し
                  </v-btn>
                  -->
                </div>
              </td>
            </tr>
          </table>

          <table class="consults-table" v-else>
            <tr>
              <th></th>
              <th>性別</th>
              <th>年齢</th>
              <th>介護度</th>
              <th>収入区分</th>
              <th>現居住先</th>
              <th>相談日</th>
              <th>
                相談者情報 <br />
                ダウンロード
              </th>
              <th>対応状況</th>
            </tr>
            <tr>
              <td colspan="9" v-if="isLoaded">
                現在、新規の入居相談はありません。紹介のプロから相談依頼ある場合はこちらで表示されます
              </td>
              <td colspan="9" v-else>
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </td>
            </tr>
          </table>

          <br />
          <router-link to="/introduce-resident-service/">
            <v-btn color="primary">
              <v-icon left dark>
                {{ icons.mdiArrowLeftCircle }}
              </v-icon>
              戻る
            </v-btn>
          </router-link>
        </div>
      </section>
    </v-app>
  </div>
</template>

<script>
import Common from '@/plugin/Common'
import * as apiclient from '@/apiclient'
import {
  mdiDownloadCircle,
  mdiCheckUnderlineCircle,
  mdiCloseCircle,
  mdiArrowLeftCircle
} from '@mdi/js'

export default {
  mixins: [Common],
  name: 'MoveinResidentConsultList',
  created() {
    setTimeout(this.loadChatbotGeneral, 1000)
    this.getLoginInfo()
  },
  data: function() {
    return {
      icons: {
        mdiCheckUnderlineCircle,
        mdiDownloadCircle,
        mdiCloseCircle,
        mdiArrowLeftCircle
      },
      careHomeInfo: {},
      careHomeItems: [],
      selectCareHome: '',
      residentsList: [
        {
          consult_id: 0,
          imm_care_home_id: 0,
          entry_id: 0,
          consult_date: '',
          consult_flag: '',
          support_flag: '',
          status: '',
          remarks: '',
          gender: '',
          age: '',
          nursing_care_level_name: '',
          pension: '',
          actual_living: '',
          pdf_link: ''
        }
      ],
      isLoaded: false
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  },
  methods: {
    getLoginInfo: function() {
      try {
        this.residentsList = {}
        apiclient.TsunaguwaAuthApi.getLoginInfo((responseData) => {
          this.careHomeItems = responseData.payload.user_care_homes
          if (this.careHomeItems.length > 0) {
            this.selectCareHome = this.careHomeItems[0].care_home_id
            this.careHomeInfo = this.careHomeItems[0]
          }
          this.getResidentsList()
        })
      } catch (error) {
        throw error.response
      }
    },
    getResidentsList: function() {
      try {
        var params = { imm_care_home_id: this.careHomeInfo.imm_care_home_id }
        apiclient.IMatchConsultsApi.getMoveinResidentList(
          params,
          (responseData) => {
            this.residentsList = responseData.payload.residents_list
            this.isLoaded = true
          }
        )
      } catch (error) {
        throw error.response
      }
    },
    changeCareHomeInfo: function() {
      try {
        if (this.isEmpty(this.selectCareHome)) {
          return
        }
        this.isLoaded = false
        this.residentsList = {}
        let filterValue = this.selectCareHome
        let filterResult = this.careHomeItems.filter(function(e) {
          return e.care_home_id == filterValue
        })
        this.careHomeInfo = filterResult[0]
        this.getResidentsList()
      } catch (error) {
        throw error.response
      }
    },
    downloadPdf(item) {
      window.open(item.pdf_link, '_blank')
    },
    updateSuport(item, supportFlag) {
      let message = ''
      console.log('item=' + item)
      if (supportFlag == '1') {
        message =
          '対応可能として紹介のプロの担当者に送信します。よろしいでしょうか？'
      } else if (supportFlag == '2') {
        message =
          '対応不可の場合、この一覧から表示されなくなります。よろしいでしょうか？'
      }
      if (confirm(message)) {
        item.support_flag = supportFlag
        this.updateResidentInfo(item)
      }
    },
    updateResidentInfo: function(item) {
      try {
        var params = {
          care_home_info: this.careHomeInfo,
          resident_info: item
        }
        apiclient.IMatchConsultsApi.updateMoveinResident(
          params,
          (responseData) => {
            if (responseData == null) {
              alert(
                'サーバーが混雑しています。しばらく時間を置いてから再度お試しください。'
              )
              return
            }
            this.getResidentsList()
          }
        )
      } catch (error) {
        throw error.response
      }
    }
  }
}
</script>

<style lang="scss"></style>
