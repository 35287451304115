<template>
  <div>
    <v-app>
      <section
        class="dxservice-wrapper login-content common-height common-content"
      >
        <div class="wrapper title-block">
          <h2 class="common-title">
            DXサービス
          </h2>
        </div>
        <div class="dxservices-block">
          <table class="detailtable">
            <tbody>
              <tr>
                <td>
                  <v-checkbox
                    v-model="showMoveInDx"
                    label="入居予定"
                    color="primary"
                    hide-details
                  ></v-checkbox>
                </td>
                <td>
                  <v-checkbox
                    v-model="showMoveOutDx"
                    label="退去予定"
                    color="primary"
                    hide-details
                  ></v-checkbox>
                </td>
                <td>
                  <v-checkbox
                    v-model="showMoveSummaryDx"
                    label="現在の入居者情報"
                    color="primary"
                    hide-details
                  ></v-checkbox>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <div class="dxservices-block">
          <v-row v-if="isShowAny">
            <v-col
              cols="4"
              v-for="item in careHomesItems"
              :key="item.care_home_id"
            >
              <v-row>
                <v-card class="pa-2" outlined tile>
                  <v-card>
                    {{ item.care_home_name }}
                  </v-card>
                  <v-card class="pa-2"> </v-card>
                  <v-card class="pa-2">
                    <MoveInSchedule
                      :carehome-id="item.care_home_id"
                      v-if="showMoveInDx"
                    />
                  </v-card>
                  <v-card class="pa-2">
                    <MoveOutSchedule
                      :carehome-id="item.care_home_id"
                      v-if="showMoveOutDx"
                    />
                  </v-card>
                  <v-card class="pa-2">
                    <MoveInInfo
                      :carehome-id="item.care_home_id"
                      v-if="showMoveSummaryDx"
                    />
                  </v-card>
                </v-card>
              </v-row>
            </v-col>
          </v-row>
          <br /><br />
          <router-link to="/dx-services">
            <v-btn color="primary">
              <v-icon left dark>
                {{ icons.mdiArrowLeftCircle }}
              </v-icon>
              戻る
            </v-btn>
          </router-link>
          <br /><br />
        </div>
      </section>
    </v-app>
  </div>
</template>

<script>
import Common from '@/plugin/Common'
import * as apiclient from '@/apiclient'
import MoveInSchedule from '@/components/top/MoveInSchedule'
import MoveOutSchedule from '@/components/top/MoveOutSchedule'
import MoveInInfo from '@/components/top/MoveInInfo'

import {
  mdiDownloadCircle,
  mdiCloudUpload,
  mdiCheckUnderlineCircle,
  mdiCloseCircle,
  mdiExitToApp,
  mdiArrowLeftCircle,
  mdiMagnify,
  mdiArrowCollapseLeft,
  mdiArrowCollapseRight,
  mdiMinus,
  mdiPlus,
  mdiChevronDown,
  mdiChevronLeft,
  mdiChevronRight
} from '@mdi/js'

export default {
  mixins: [Common],
  name: 'DxServiceForOwner',
  components: {
    MoveInSchedule,
    MoveOutSchedule,
    MoveInInfo
  },
  created() {
    setTimeout(this.loadChatbotGeneral, 1000)
    this.getLoginInfo()
  },
  data: function() {
    return {
      icons: {
        mdiCheckUnderlineCircle,
        mdiDownloadCircle,
        mdiCloudUpload,
        mdiCloseCircle,
        mdiExitToApp,
        mdiArrowLeftCircle,
        mdiMagnify,
        mdiArrowCollapseLeft,
        mdiArrowCollapseRight,
        mdiMinus,
        mdiPlus,
        mdiChevronDown,
        mdiChevronLeft,
        mdiChevronRight
      },
      showMoveInDx: true,
      showMoveOutDx: true,
      showMoveSummaryDx: true,
      careHomesItems: []
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    isShowAny() {
      return this.showMoveInDx || this.showMoveOutDx || this.showMoveSummaryDx
    }
  },
  methods: {
    getLoginInfo: function() {
      try {
        apiclient.TsunaguwaAuthApi.getLoginInfo((responseData) => {
          this.careHomesItems = responseData.payload.user_care_homes
        })
      } catch (error) {
        throw error.response
      }
    }
  }
}
</script>

<style lang="scss"></style>
