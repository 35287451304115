<template>
  <div>
    <section class="wrapper login-content common-height common-content">
      <div class="news-block">
        <div class="content-inner">
          <div class="title">
            免責事項
          </div>
          <div class="header">リンク先サイトについて</div>
          <div class="text">
            tsunagnuwaを介したサイトの閲覧、ご利用はお客様の責任で行っていただきますようお願いいたします。
            tsunagnuwaからリンクされているサイトは、それぞれの運営者の責任によって運営されており、また、tsunagnuwaに掲載されている広告からリンクされているサイトは広告主の責任により運営されています。
            tsunagnuwaはこれらのサイトについて、違法なものでないこと、内容が正確であること、不快な内容を含まないものであること、利用者が意図していない情報を含まないものであることなどを一切保証いたしません。<br />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Common from '@/plugin/Common'

export default {
  mixins: [Common],
  name: 'Disclaimer',
  created() {
    setTimeout(this.commonHeight, 10)
    // ロード時、リサイズ時にcommon-heightの高さを調整する
    window.addEventListener('load', this.commonHeight)
    window.addEventListener('resize', this.commonHeight)
    setTimeout(this.loadChatbotGeneral, 1000)
  }
}
</script>

<style lang="scss"></style>
