import {
  Configuration,
  // 共通API
  CommonApi,
  CommonMiscRequest,
  CommonMiscResponse,
  CommonPrefecturesRequest,
  CommonPrefecturesResponse,
  CommonCitiesRequest,
  CommonCitiesResponse,
  // 認証API
  AuthApi,
  TsunaguwaLoginInfoRequest,
  TsunaguwaLoginInfoResponse,
  // DXサービスAPI(施設向け)
  DxCarehomesApi,
  DxCarehomeRoomsInfoRequest,
  DxCarehomeRoomsInfoResponse,
  DxCarehomeResidentsListRequest,
  DxCarehomeResidentsListResponse,
  DxCarehomeResidentsSummaryInfoRequest,
  DxCarehomeResidentsSummaryInfoResponse,
  DxCarehomeCarehomeGetInfoRequest,
  DxCarehomeCarehomeGetInfoResponse,
  DxCarehomeCarehomeUpdateRequest,
  DxCarehomeCarehomeUpdateResponse,
  DxCarehomeResidentsGetInfoRequest,
  DxCarehomeResidentsGetInfoResponse,
  DxCarehomeResidentsUpdateRequest,
  DxCarehomeResidentsUpdateResponse,
  DxCarehomeResidentsRegisterRequest,
  DxCarehomeResidentsRegisterResponse,
  DxCarehomeSummaryInfoRequest,
  DxCarehomeSummaryInfoResponse,
  DxCarehomeResidentsGetInfoFilesRequest,
  DxCarehomeResidentsGetInfoFilesResponse,
  DxCarehomeResidentUploadFileInfoResponse,
  // 広告API
  AdApi,
  AdInfoRequest,
  AdInfoResponse,
  AdClickRequest
} from 'tsunaguwa-api-client'
import store from '../store'
import { Tools, ToolsConfiguration } from 'tsunaguwa-tools'
import config from './../config'

const userData: any = store.state.user
const apiBaseUrl = process.env.VUE_APP_TSUNAGUWA_API_URL
const tsunaguwaApiConfig: Configuration = new Configuration()
tsunaguwaApiConfig.basePath = apiBaseUrl
const tswToolsConfig = new ToolsConfiguration()
tswToolsConfig.tswConf = config.TsunaguwaConfig
const tswTools = new Tools(tswToolsConfig)

export class TsunaguwaCommonApi {
  constructor() {}
  static commonApi: CommonApi = new CommonApi(tsunaguwaApiConfig)

  static getMisc(callback: Function, mkey: string) {
    this.getMiscAsync(mkey)
      .then((responseData: CommonMiscResponse) => {
        callback.call(this, responseData)
      })
      .catch((err) => {
        console.error(err)
      })
  }
  static async getMiscAsync(mkey: string) {
    const commonMiscRequest: CommonMiscRequest = {
      mkey: mkey
    }
    const response = await this.commonApi.getMisc(
      commonMiscRequest,
      TsunaguwaApiUtil.getCommonOptions()
    )
    return response.data
  }

  static getPrefectures(callback: Function) {
    this.getPrefecturesAsync()
      .then((responseData: CommonPrefecturesResponse) => {
        callback.call(this, responseData)
      })
      .catch((err) => {
        console.error(err)
      })
  }
  static async getPrefecturesAsync() {
    const commonPrefecturesRequest: CommonPrefecturesRequest = {}
    const response = await this.commonApi.getPrefectures(
      commonPrefecturesRequest,
      TsunaguwaApiUtil.getCommonOptions()
    )
    return response.data
  }

  static getCities(callback: Function, prefectureCode: string) {
    this.getCitiesAsync(prefectureCode)
      .then((responseData: CommonCitiesResponse) => {
        callback.call(this, responseData)
      })
      .catch((err) => {
        console.error(err)
      })
  }
  static async getCitiesAsync(prefectureCode: string) {
    const commonCitiesRequest: CommonCitiesRequest = {
      prefecture_code: prefectureCode
    }
    const response = await this.commonApi.getCities(
      commonCitiesRequest,
      TsunaguwaApiUtil.getCommonOptions()
    )
    return response.data
  }
}

export class TsunaguwaAuthApi {
  constructor() {}
  static authApi: AuthApi = new AuthApi(tsunaguwaApiConfig)

  static getLoginInfo(callback: Function) {
    this.getLoginInfoAsync()
      .then((responseData: TsunaguwaLoginInfoResponse) => {
        callback.call(this, responseData)
      })
      .catch((err) => {
        console.error(err)
      })
  }
  static async getLoginInfoAsync() {
    const tsunaguwaLoginInfoRequest: TsunaguwaLoginInfoRequest = {}
    const response = await this.authApi.getLoginInfo(
      tsunaguwaLoginInfoRequest,
      TsunaguwaApiUtil.getCommonOptions()
    )
    return response.data
  }
}

export class TsunaguwaDxCarehomesApi {
  constructor() {}
  static dxCarehomesApi: DxCarehomesApi = new DxCarehomesApi(tsunaguwaApiConfig)

  static getRooms(callback: Function, params: any) {
    this.getRoomsAsync(params)
      .then((responseData: DxCarehomeRoomsInfoResponse) => {
        callback.call(this, responseData)
      })
      .catch((err) => {
        console.error(err)
      })
  }
  static async getRoomsAsync(params: any) {
    const dxCarehomeRoomsInfoRequest: DxCarehomeRoomsInfoRequest = {
      care_home_id: params.care_home_id
    }
    const response = await this.dxCarehomesApi.getRooms(
      dxCarehomeRoomsInfoRequest,
      TsunaguwaApiUtil.getCommonOptions()
    )
    return response.data
  }

  static getCarehomesSummary(callback: Function) {
    this.getCarehomesSummaryAsync()
      .then((responseData: DxCarehomeSummaryInfoResponse) => {
        callback.call(this, responseData)
      })
      .catch((err) => {
        console.error(err)
      })
  }
  static async getCarehomesSummaryAsync() {
    const dxCarehomeSummaryInfoRequest: DxCarehomeSummaryInfoRequest = {}
    const response = await this.dxCarehomesApi.getCarehomesSummary(
      dxCarehomeSummaryInfoRequest,
      TsunaguwaApiUtil.getCommonOptions()
    )
    return response.data
  }

  static getResidentsList(callback: Function, params: any) {
    this.getResidentsListAsync(params)
      .then((responseData: DxCarehomeResidentsListResponse) => {
        callback.call(this, responseData)
      })
      .catch((err) => {
        console.error(err)
      })
  }
  static async getResidentsListAsync(params: any) {
    const dxCarehomeResidentsListRequest: DxCarehomeResidentsListRequest = {
      care_home_id: params.care_home_id,
      status_code: params.status
    }
    const response = await this.dxCarehomesApi.getResidentsList(
      dxCarehomeResidentsListRequest,
      TsunaguwaApiUtil.getCommonOptions()
    )
    return response.data
  }

  static getCarehome(callback: Function, params: any) {
    this.getCarehomeAsync(params)
      .then((responseData: DxCarehomeCarehomeGetInfoResponse) => {
        callback.call(this, responseData)
      })
      .catch((err) => {
        console.error(err)
      })
  }
  static async getCarehomeAsync(params: any) {
    const dxCarehomeResidentsListRequest: DxCarehomeCarehomeGetInfoRequest = {
      care_home_id: params.care_home_id
    }
    const response = await this.dxCarehomesApi.getCarehome(
      dxCarehomeResidentsListRequest,
      TsunaguwaApiUtil.getCommonOptions()
    )
    return response.data
  }

  static getResidentsSummary(callback: Function, params: any) {
    this.getResidentsSummaryAsync(params)
      .then((responseData: DxCarehomeResidentsSummaryInfoResponse) => {
        callback.call(this, responseData)
      })
      .catch((err) => {
        console.error(err)
      })
  }
  static async getResidentsSummaryAsync(params: any) {
    const dxCarehomeResidentsSummaryInfoRequest: DxCarehomeResidentsSummaryInfoRequest = {
      care_home_id: params.care_home_id
    }
    const response = await this.dxCarehomesApi.getResidentsSummary(
      dxCarehomeResidentsSummaryInfoRequest,
      TsunaguwaApiUtil.getCommonOptions()
    )
    return response.data
  }

  static updateCarehome(callback: Function, params: any) {
    this.updateCarehomeAsync(params)
      .then((responseData: DxCarehomeCarehomeUpdateResponse) => {
        callback.call(this, responseData)
      })
      .catch((err) => {
        console.error(err)
      })
  }
  static async updateCarehomeAsync(params: any) {
    const dxCarehomeCarehomeUpdateRequest: DxCarehomeCarehomeUpdateRequest = params
    const response = await this.dxCarehomesApi.updateCarehome(
      dxCarehomeCarehomeUpdateRequest,
      TsunaguwaApiUtil.getCommonOptions()
    )
    return response.data
  }

  static getResident(callback: Function, params: any) {
    this.getResidentAsync(params)
      .then((responseData: DxCarehomeResidentsGetInfoResponse) => {
        callback.call(this, responseData)
      })
      .catch((err) => {
        console.error(err)
      })
  }
  static async getResidentAsync(params: any) {
    const dxCarehomeResidentsListRequest: DxCarehomeResidentsGetInfoRequest = {
      resident_id: params.resident_id
    }
    const response = await this.dxCarehomesApi.getResident(
      dxCarehomeResidentsListRequest,
      TsunaguwaApiUtil.getCommonOptions()
    )
    return response.data
  }

  static updateResident(callback: Function, params: any) {
    this.updateResidentAsync(params)
      .then((responseData: DxCarehomeResidentsUpdateResponse) => {
        callback.call(this, responseData)
      })
      .catch((err) => {
        console.error(err)
        callback.call(this, null)
      })
  }
  static async updateResidentAsync(params: any) {
    const dxCarehomeResidentsUpdateRequest: DxCarehomeResidentsUpdateRequest = params
    const response = await this.dxCarehomesApi.updateResident(
      dxCarehomeResidentsUpdateRequest,
      TsunaguwaApiUtil.getCommonOptions()
    )
    return response.data
  }

  static registResident(callback: Function, params: any) {
    this.registResidentAsync(params)
      .then((responseData: DxCarehomeResidentsRegisterResponse) => {
        callback.call(this, responseData)
      })
      .catch((err) => {
        console.error(err)
        callback.call(this, null)
      })
  }
  static async registResidentAsync(params: any) {
    const dxCarehomeResidentsUpdateRequest: DxCarehomeResidentsRegisterRequest = params
    const response = await this.dxCarehomesApi.registResident(
      dxCarehomeResidentsUpdateRequest,
      TsunaguwaApiUtil.getCommonOptions()
    )
    return response.data
  }

  static getResidentInfoFiles(callback: Function, params: any) {
    this.getResidentInfoFilesAsync(params)
      .then((responseData: DxCarehomeResidentsGetInfoFilesResponse) => {
        callback.call(this, responseData)
      })
      .catch((err) => {
        console.error(err)
      })
  }
  static async getResidentInfoFilesAsync(params: any) {
    const dxCarehomeApiRequest: DxCarehomeResidentsGetInfoFilesRequest = {
      resident_id: params.resident_id
    }
    const response = await this.dxCarehomesApi.getResidentInfoFiles(
      dxCarehomeApiRequest,
      TsunaguwaApiUtil.getCommonOptions()
    )
    return response.data
  }

  static uploadResidentFileInfo(
    params: any,
    fileName: any,
    callback: Function
  ) {
    this.uploadResidentFileInfoAsync(params, fileName)
      .then((responseData: DxCarehomeResidentUploadFileInfoResponse) => {
        callback.call(this, responseData)
      })
      .catch((err) => {
        console.error(err)
        callback.call(this, null)
      })
  }
  static async uploadResidentFileInfoAsync(params: any, fileName?: any) {
    const response = await this.dxCarehomesApi.uploadResidentFileInfo(
      params.infoType,
      params.careHomeId,
      params.residentId,
      params.categoryCode,
      fileName,
      TsunaguwaApiUtil.getCommonOptions()
    )
    return response.data
  }
}

export class TsunaguwaAdApi {
  constructor() {}
  static adApi: AdApi = new AdApi(tsunaguwaApiConfig)

  static getAdList(adPositionCode: string, callback: Function) {
    this.getAdListAsync(adPositionCode)
      .then((responseData: AdInfoResponse) => {
        callback.call(this, responseData)
      })
      .catch((err) => {
        console.error(err)
      })
  }
  static async getAdListAsync(adPositionCode: string) {
    const dxCarehomeRoomsInfoRequest: AdInfoRequest = {
      ad_position_code: adPositionCode
    }
    const response = await this.adApi.getAdList(
      dxCarehomeRoomsInfoRequest,
      TsunaguwaApiUtil.getCommonOptions()
    )
    return response.data
  }

  static registerAdClick(advertiserId: string, callback: Function) {
    this.registerAdClickAsync(advertiserId)
      .then((responseData: AdInfoResponse) => {
        callback.call(this, responseData)
      })
      .catch((err) => {
        console.error(err)
      })
  }
  static async registerAdClickAsync(advertiserId: string) {
    const dxCarehomeRoomsInfoRequest: AdClickRequest = {
      advertiser_id: advertiserId
    }
    const response = await this.adApi.registerAdClick(
      dxCarehomeRoomsInfoRequest,
      TsunaguwaApiUtil.getCommonOptions()
    )
    return response.data
  }
}

class TsunaguwaApiUtil {
  static getCommonOptions() {
    const tswCookie = tswTools.getTswCookie()
    if (tswCookie === undefined || tswCookie == null) {
      return {}
    }
    const key = config.TsunaguwaConfig.UserInfoCookie.Key
    let commonOptions = {
      headers: {
        [key]: tswCookie
      }
    }
    return commonOptions
  }
}
