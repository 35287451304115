<template>
  <div>
    <section class="sdgs-header">
      <div class="wrapper">
        <picture>
          <source
            srcset="../assets/img/sdgs/header_logo.avif"
            type="image/avif"
          />
          <source
            srcset="../assets/img/sdgs/header_logo.webp"
            type="image/webp"
          />
          <img src="../assets/img/sdgs/header_logo.png" alt="ヘッダーロゴ" />
        </picture>
      </div>
    </section>
    <div class="sdgs-main">
      <div class="sdgs-main-content common-content wrapper">
        <div class="logo">
          <picture>
            <source srcset="../assets/img/sdgs/logo.avif" type="image/avif" />
            <source srcset="../assets/img/sdgs/logo.webp" type="image/webp" />
            <img src="../assets/img/sdgs/logo.png" alt="SDGsロゴ" />
          </picture>
        </div>
        <div class="sdgs-icon-grid">
          <div>
            <picture>
              <source srcset="../assets/img/sdgs/1.avif" type="image/avif" />
              <source srcset="../assets/img/sdgs/1.webp" type="image/webp" />
              <img src="../assets/img/sdgs/1.png" alt="SDGs目標1" />
            </picture>
          </div>
          <div>
            <picture>
              <source srcset="../assets/img/sdgs/2.avif" type="image/avif" />
              <source srcset="../assets/img/sdgs/2.webp" type="image/webp" />
              <img src="../assets/img/sdgs/2.png" alt="SDGs目標2" />
            </picture>
          </div>
          <div>
            <picture>
              <source srcset="../assets/img/sdgs/3.avif" type="image/avif" />
              <source srcset="../assets/img/sdgs/3.webp" type="image/webp" />
              <img src="../assets/img/sdgs/3.png" alt="SDGs目標3" />
            </picture>
          </div>
          <div>
            <picture>
              <source srcset="../assets/img/sdgs/4.avif" type="image/avif" />
              <source srcset="../assets/img/sdgs/4.webp" type="image/webp" />
              <img src="../assets/img/sdgs/4.png" alt="SDGs目標4" />
            </picture>
          </div>
          <div>
            <picture>
              <source srcset="../assets/img/sdgs/5.avif" type="image/avif" />
              <source srcset="../assets/img/sdgs/5.webp" type="image/webp" />
              <img src="../assets/img/sdgs/5.png" alt="SDGs目標5" />
            </picture>
          </div>
          <div>
            <picture>
              <source srcset="../assets/img/sdgs/6.avif" type="image/avif" />
              <source srcset="../assets/img/sdgs/6.webp" type="image/webp" />
              <img src="../assets/img/sdgs/6.png" alt="SDGs目標6" />
            </picture>
          </div>
          <div>
            <picture>
              <source srcset="../assets/img/sdgs/7.avif" type="image/avif" />
              <source srcset="../assets/img/sdgs/7.webp" type="image/webp" />
              <img src="../assets/img/sdgs/7.png" alt="SDGs目標7" />
            </picture>
          </div>
          <div>
            <picture>
              <source srcset="../assets/img/sdgs/8.avif" type="image/avif" />
              <source srcset="../assets/img/sdgs/8.webp" type="image/webp" />
              <img src="../assets/img/sdgs/8.png" alt="SDGs目標8" />
            </picture>
          </div>
          <div>
            <picture>
              <source srcset="../assets/img/sdgs/9.avif" type="image/avif" />
              <source srcset="../assets/img/sdgs/9.webp" type="image/webp" />
              <img src="../assets/img/sdgs/9.png" alt="SDGs目標9" />
            </picture>
          </div>
          <div>
            <picture>
              <source srcset="../assets/img/sdgs/10.avif" type="image/avif" />
              <source srcset="../assets/img/sdgs/10.webp" type="image/webp" />
              <img src="../assets/img/sdgs/10.png" alt="SDGs目標10" />
            </picture>
          </div>
          <div>
            <picture>
              <source srcset="../assets/img/sdgs/11.avif" type="image/avif" />
              <source srcset="../assets/img/sdgs/11.webp" type="image/webp" />
              <img src="../assets/img/sdgs/11.png" alt="SDGs目標11" />
            </picture>
          </div>
          <div>
            <picture>
              <source srcset="../assets/img/sdgs/12.avif" type="image/avif" />
              <source srcset="../assets/img/sdgs/12.webp" type="image/webp" />
              <img src="../assets/img/sdgs/12.png" alt="SDGs目標12" />
            </picture>
          </div>
          <div>
            <picture>
              <source srcset="../assets/img/sdgs/13.avif" type="image/avif" />
              <source srcset="../assets/img/sdgs/13.webp" type="image/webp" />
              <img src="../assets/img/sdgs/13.png" alt="SDGs目標13" />
            </picture>
          </div>
          <div>
            <picture>
              <source srcset="../assets/img/sdgs/14.avif" type="image/avif" />
              <source srcset="../assets/img/sdgs/14.webp" type="image/webp" />
              <img src="../assets/img/sdgs/14.png" alt="SDGs目標14" />
            </picture>
          </div>
          <div>
            <picture>
              <source srcset="../assets/img/sdgs/15.avif" type="image/avif" />
              <source srcset="../assets/img/sdgs/15.webp" type="image/webp" />
              <img src="../assets/img/sdgs/15.png" alt="SDGs目標15" />
            </picture>
          </div>
          <div>
            <picture>
              <source srcset="../assets/img/sdgs/16.avif" type="image/avif" />
              <source srcset="../assets/img/sdgs/16.webp" type="image/webp" />
              <img src="../assets/img/sdgs/16.png" alt="SDGs目標16" />
            </picture>
          </div>
          <div>
            <picture>
              <source srcset="../assets/img/sdgs/17.avif" type="image/avif" />
              <source srcset="../assets/img/sdgs/17.webp" type="image/webp" />
              <img src="../assets/img/sdgs/17.png" alt="SDGs目標17" />
            </picture>
          </div>
          <div>
            <picture>
              <source srcset="../assets/img/sdgs/18.avif" type="image/avif" />
              <source srcset="../assets/img/sdgs/18.webp" type="image/webp" />
              <img src="../assets/img/sdgs/18.png" alt="SDGs目標18" />
            </picture>
          </div>
        </div>
      </div>
    </div>
    <div class="sdgs-about">
      <div class="sdgs-about-content common-content wrapper">
        <div class="sdgs-about-blcok">
          <h2 class="about-title">
            持続可能な開発目標(SDGs)とは
          </h2>
          <p class="about-text">
            持続可能な開発目標(SGDs)、通称「グローバル・ゴールズ」は、貧困に終止符を打ち、地球を保護し、すべての人が平和と豊かさを享受できるようにすることを目指す普遍的な行動を呼びかけています。<br />
            <br />
            これら17の目標は、ミレニアム開発目標(MDGs)の成功を土台としつつ、気候変動や経済的不平等、イノベーション、持続可能名消費、平和と正義などの新たな分野を優先課題として盛り込んでいます。ある目標を達成するためには、むしろ別の目標と広く関連づけられる問題にも取り組まねばならないことが多いという点で、目行はすべて相互接続的といえます。<br />
            <br />
            SDGsは、パートナーシップと実用主義の精神に基づき、いま正しい選択をすることで、将来の世代の暮らしを持続可能な形で改善することを目指します。すべての国がそれぞれの優先課題や、全世界的な環境課題に応じて採用できる明確なガイドラインやターゲットも設けられています。<br />
            SDGsは包摂的なアジェンダとして、貧困の根本的な原因に取り組むとともに、人間と地球の両方にとってプラスとなる変化の実現に向け、私たちを団結させるものとなっています。アヒム・シュタイナーUNDP総裁は「2030アジェンダの支援は、UNDPにとって最優先課題のひとつです。SDGsは貧困、気候変動、紛争など、私たちの世界が抱える喫緊の課題のいくつかに取り組むための共通の計画とアジェンダを私たちに提供しています。UNDPには、前身の原動力として、各国が持続可能な開発に向けた道を歩むための支援ができる経験とノウハウがあります。」と呼びかけます。<br />
          </p>
        </div>
      </div>
    </div>
    <div class="sdgs-mission">
      <div class="sdgs-mission-content common-content wrapper">
        <div class="mission-title">
          <picture>
            <source
              srcset="../assets/img/sdgs/mission-title.avif"
              type="image/avif"
            />
            <source
              srcset="../assets/img/sdgs/mission-title.webp"
              type="image/webp"
            />
            <img
              src="../assets/img/sdgs/mission-title.png"
              alt="私たちはツナグワポータルを通して、高齢者が抱える社会課題を重点的に取り組みます。"
            />
          </picture>
        </div>
        <div class="mission-image">
          <picture>
            <source
              srcset="../assets/img/sdgs/mission-image.avif"
              type="image/avif"
            />
            <source
              srcset="../assets/img/sdgs/mission-image.webp"
              type="image/webp"
            />
            <img
              src="../assets/img/sdgs/mission-image.png"
              alt="SDGsミッション"
            />
          </picture>
        </div>
      </div>
    </div>
    <div class="sdgs-mission2">
      <div class="sdgs-mission2-content common-content wrapper">
        <div class="mission2-flex">
          <div class="item-image">
            <picture>
              <source srcset="../assets/img/sdgs/1.avif" type="image/avif" />
              <source srcset="../assets/img/sdgs/1.webp" type="image/webp" />
              <img src="../assets/img/sdgs/1.png" alt="SDGs目標1" />
            </picture>
          </div>
          <div class="item-text">
            <h3 class="item-title title-1">1. 貧困をなくそう</h3>
            <p class="description">
              私たちが住んでいる日本では、貧困を目の当たりにする機会はそうそうないでしょう。<br />
              日本は超高齢化社会の中で、高齢者の「貧困」も大きな問題になってきています。<br />
              豊かな国だと思われる日本でも、貧困の中で暮らしている事実は決して見過ごせる問題ではありません。<br />
              「1.貧困をなくそう」を達成するためには、包括的な取り組みが重要です。<br />
              弊社はこの問題を真正面から受け止め会社全体で取り組んでいきます。
            </p>
            <div class="attempt-block">
              <p class="attempt-title">【取り組み】</p>
              <ol class="attempt-list">
                <li>
                  Tsunaguwaポータル内のさまざまなサービスを通じ、経営効率を高める事で低価格でも施設運営できる体制づくりを支援する。
                </li>
                <li>
                  介護無料相談 民間包括支援センター「みんほう」運営
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div class="mission2-flex">
          <div class="item-image">
            <picture>
              <source srcset="../assets/img/sdgs/3.avif" type="image/avif" />
              <source srcset="../assets/img/sdgs/3.webp" type="image/webp" />
              <img src="../assets/img/sdgs/3.png" alt="SDGs目標3" />
            </picture>
          </div>
          <div class="item-text">
            <h3 class="item-title title-2">3.すべての人に健康と福祉を</h3>
            <p class="description">
              目標やターゲットをみると、実現できるの？と思うくらいスケールが大きなものですが、<br />
              この問題をわたしたちは高齢者に絞って食を通し、より元気に充実した生活と健康を維持することに取り組みます。
            </p>
            <div class="attempt-block">
              <p class="attempt-title">【取り組み】</p>
              <ol class="attempt-list">
                <li>
                  1日3食
                  2000kcalを推奨し、メニュー開発、医学的根拠へのアプローチ
                </li>
                <li>
                  さまざまなリハビリメニューの開発
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div class="mission2-flex">
          <div class="item-image">
            <picture>
              <source srcset="../assets/img/sdgs/12.avif" type="image/avif" />
              <source srcset="../assets/img/sdgs/12.webp" type="image/webp" />
              <img src="../assets/img/sdgs/12.png" alt="SDGs目標12" />
            </picture>
          </div>
          <div class="item-text">
            <h3 class="item-title title-3">12.つくる責任、つかう責任</h3>
            <p class="item-title-above">
              <span class="bold"
                >「持続可能な消費と生産のパターンを確保する」ということがテーマ</span
              >です。 <br />
              私たちは積極的にリユースやリサイクルを促進していきます。
            </p>
            <p class="description">
              具体的には<br />
              <span class="bold">「つくる責任」</span>として、<span class="bold"
                >生産者側</span
              >には<br />
              ・<span class="bold">製品のリユースやリサイクルできる仕組</span
              ><br />
              <span class="bold">「つかう責任」</span>として、<span class="bold"
                >消費者には</span
              ><br />
              ・<span class="bold">リユースやリサイクル</span><br />
              ・<span class="bold"
                >生産者から提供された資源を最大限有効活用していくこと</span
              ><br />
              さらには、生産者・消費者といった立場だけでなく、行政機関、自治体などの地域共同体などが参加し、<span
                class="bold"
                >すべての人々による持続可能な生産・消費形態の実現</span
              >を目指しています。
            </p>
            <div class="attempt-block">
              <p class="attempt-title">【取り組み】</p>
              <ol class="attempt-list">
                <li>
                  介護・医療でもベット開発・車いす開発・家具・衣服等リユースの仕組みづくり。
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SDGs',
  components: {}
}
</script>

<style lang="scss"></style>
