<template>
  <div>
    <v-app>
      <section class="mypage-wrapper common-height common-content">
        <div class="wrapper title-block">
          <h2 class="common-title">
            登録情報
          </h2>
        </div>
        <div class="mypage-block">
          <v-container class="grey lighten-4">
            <v-row no-gutters>
              <v-col cols="2">
                <v-card class="pa-1" outlined tile>
                  ユーザーID
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card class="pa-1" outlined tile>
                  {{ userdata.username }}
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-row no-gutters>
                  <v-col>
                    <v-card class="pa-1" outlined tile>
                      登録日
                    </v-card>
                  </v-col>
                  <v-col>
                    <v-card class="pa-1" outlined tile>
                      最終更新日
                    </v-card>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col>
                    <v-card class="pa-1" outlined tile>
                      {{ userdata.created_At | moment }}
                    </v-card>
                  </v-col>
                  <v-col>
                    <v-card class="pa-1" outlined tile>
                      {{ userdata.updated_At | moment }}
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                ■基本情報
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="2">
                <v-card class="pa-1" outlined tile>
                  姓
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="userdata.family_name"
                    :rules="[rules.required, rules.counter127]"
                    prepend-icon="mdi-asterisk"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-card>
              </v-col>
              <v-col cols="2">
                <v-card class="pa-1" outlined tile>
                  名
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="userdata.given_name"
                    :rules="[rules.required, rules.counter127]"
                    prepend-icon="mdi-asterisk"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="2">
                <v-card class="pa-1" outlined tile>
                  姓(カナ)
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="userdata.family_name_kana"
                    :rules="[rules.counter255]"
                    outlined
                    dense
                  ></v-text-field>
                </v-card>
              </v-col>
              <v-col cols="2">
                <v-card class="pa-1" outlined tile>
                  名(カナ)
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="userdata.given_name_kana"
                    :rules="[rules.counter255]"
                    outlined
                    dense
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="2">
                <v-card class="pa-1" outlined tile>
                  施設名
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="userdata.facility_name"
                    :rules="[rules.counter127]"
                    outlined
                    dense
                  ></v-text-field>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card class="pa-1" outlined tile>
                  <v-select
                    v-model="userdata.facility_type"
                    :items="facility_types"
                    label="施設種別"
                    outlined
                    dense
                  ></v-select>
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="2">
                <v-card class="pa-1" outlined tile>
                  会社名
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="userdata.company_name"
                    :rules="[rules.counter127]"
                    outlined
                    dense
                  ></v-text-field>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card class="pa-1" outlined tile>
                  <v-select
                    v-model="userdata.main_industry"
                    :items="main_industries"
                    label="主な業種"
                    outlined
                    dense
                  ></v-select>
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="2">
                <v-card class="pa-1" outlined tile>
                  住所
                </v-card>
              </v-col>
              <v-col cols="2">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="userdata.postal_code"
                    :rules="[rules.required, rules.postalcode]"
                    prepend-icon="mdi-asterisk"
                    label="郵便番号"
                    hint="ハイフンは付けても付けなくてもOK"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card class="pa-1" outlined tile>
                  <v-select
                    v-model="userdata.address_prefecture"
                    :items="prefectures"
                    label="都道府県"
                    required
                    outlined
                    dense
                  ></v-select>
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="userdata.address_city"
                    :rules="[rules.required, rules.counter127, rules.city]"
                    prepend-icon="mdi-asterisk"
                    label="市区町村"
                    hint="市区町村まで、政令指定都市の区は含めないでください"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="2"> </v-col>
              <v-col cols="10">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="userdata.address_street"
                    :rules="[rules.required, rules.counter127]"
                    prepend-icon="mdi-asterisk"
                    label="町名・番地"
                    hint="番地は正確に、半角で入力"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="2"> </v-col>
              <v-col cols="10">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="userdata.address_building"
                    :rules="rules.counter127"
                    label="建物名・部屋番号"
                    outlined
                    dense
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="2"> </v-col>
              <v-col cols="5">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="userdata.phone_number"
                    :rules="[rules.required, rules.phonenumber]"
                    prepend-icon="mdi-asterisk"
                    label="電話番号"
                    hint="先頭のゼロを「+81」で置き換え、半角で入力"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-card>
              </v-col>
              <v-col cols="5">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="userdata.fax"
                    :rules="[rules.fax]"
                    label="FAX番号"
                    hint="先頭のゼロを「+81」で置き換え、半角で入力"
                    outlined
                    dense
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="2">
                <v-card class="pa-1" outlined tile>
                  メールアドレス
                </v-card>
              </v-col>
              <v-col cols="10">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="userdata.email"
                    :rules="[rules.required, rules.email]"
                    prepend-icon="mdi-email"
                    hint="半角で入力"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                ■その他の情報
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="2">
                <v-card class="pa-1" outlined tile>
                  年代
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card class="pa-1" outlined tile>
                  <v-select
                    :items="ages"
                    v-model="userdata.age"
                    outlined
                    dense
                  ></v-select>
                </v-card>
              </v-col>
              <v-col cols="2">
                <v-card class="pa-1" outlined tile>
                  役職
                </v-card>
              </v-col>
              <v-col cols="4">
                <v-card class="pa-1" outlined tile>
                  <v-select
                    v-model="userdata.job_name"
                    :items="jobs"
                    outlined
                    dense
                  ></v-select>
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                ■システム情報(変更不可)
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="2">
                <v-card class="pa-1" outlined tile>
                  代理店コード
                </v-card>
              </v-col>
              <v-col cols="10">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="userdata.agencycode"
                    outlined
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="2">
                <v-card class="pa-1" outlined tile>
                  所属先コード
                </v-card>
              </v-col>
              <v-col cols="10">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="userdata.parent_agencycode"
                    outlined
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="2">
                <v-card class="pa-1" outlined tile>
                  tsunaguwa ID
                </v-card>
              </v-col>
              <v-col cols="10">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="userdata.tsunaguwa_id"
                    outlined
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="2">
                <v-card class="pa-1" outlined tile>
                  権限
                </v-card>
              </v-col>
              <v-col cols="10">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="userdata.role"
                    outlined
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="2">
                <v-card class="pa-1" outlined tile>
                  username
                </v-card>
              </v-col>
              <v-col cols="10">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="userdata.username"
                    outlined
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="2">
                <v-card class="pa-1" outlined tile>
                  created_At
                </v-card>
              </v-col>
              <v-col cols="10">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="userdata.created_At"
                    outlined
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="2">
                <v-card class="pa-1" outlined tile>
                  contract_imm
                </v-card>
              </v-col>
              <v-col cols="10">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="userdata.contract_imm"
                    outlined
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="2">
                <v-card class="pa-1" outlined tile>
                  contract_shop
                </v-card>
              </v-col>
              <v-col cols="10">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="userdata.contract_shop"
                    outlined
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="2">
                <v-card class="pa-1" outlined tile>
                  status
                </v-card>
              </v-col>
              <v-col cols="10">
                <v-card class="pa-1" outlined tile>
                  <v-text-field
                    v-model="userdata.status"
                    outlined
                    dense
                    hide-details
                    readonly
                  ></v-text-field>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <CommonDialog
                  name="変更する"
                  title="ユーザー登録情報変更"
                  message="登録情報を変更しますか？"
                  color="#94c43c"
                  class-name="action-btn"
                  v-on:acceptAction="updateMyData"
                ></CommonDialog>
              </v-col>
            </v-row>
          </v-container>
          <br />
          <router-link to="/mypage">
            <v-btn color="primary">
              <v-icon left dark>
                {{ icons.mdiArrowLeftCircle }}
              </v-icon>
              戻る
            </v-btn>
          </router-link>
        </div>
      </section>
    </v-app>
  </div>
</template>

<script>
import CommonFilters from '@/plugin/CommonFilters'
import Common from '@/plugin/Common'
import CommonDialog from '@/components/common/CommonDialog'
import { mdiArrowLeftCircle, mdiAsterisk, mdiEmail } from '@mdi/js'

export default {
  name: 'MyData',
  components: {
    CommonDialog
  },
  mixins: [CommonFilters, Common],
  data: function() {
    return {
      // API Gatewayの設定パス
      get_path: process.env.VUE_APP_GETMYDATA_API_URL,
      update_path: process.env.VUE_APP_UPDATEMYDATA_API_URL,
      loading: false,
      userdata: {
        username: '',
        created_At: '',
        family_name: '',
        family_name_kana: '',
        given_name: '',
        given_name_kana: '',
        email: '',
        phone_number: '',
        fax: '',
        postal_code: '',
        address_prefecture: '',
        address_city: '',
        address_street: '',
        address_building: '',
        role: '',
        tsunaguwa_id: '',
        agencycode: '',
        parent_agencycode: '',
        facility_name: '',
        facility_type: '',
        company_name: '',
        main_industry: '',
        job_name: '',
        age: '',
        status: '',
        contract_imm: '',
        contract_shop: ''
      },
      facility_types: [
        '不明',
        '特別養護老人ホーム',
        '老人保健施設',
        '介護付き有料老人ホーム',
        '住宅型有料老人ホーム',
        'サービス付き高齢者向け住宅',
        'グループホーム',
        'デイサービス',
        '障害者施設',
        '病院',
        'その他'
      ],
      main_industries: [
        '不明',
        '不動産',
        '建築',
        'IT関係',
        '人材派遣',
        '老人福祉事業',
        '障がい者福祉事業',
        'その他'
      ],
      prefectures: [
        '北海道',
        '青森県',
        '岩手県',
        '宮城県',
        '秋田県',
        '山形県',
        '福島県',
        '茨城県',
        '栃木県',
        '群馬県',
        '埼玉県',
        '千葉県',
        '東京都',
        '神奈川県',
        '新潟県',
        '富山県',
        '石川県',
        '福井県',
        '山梨県',
        '長野県',
        '岐阜県',
        '静岡県',
        '愛知県',
        '三重県',
        '滋賀県',
        '京都府',
        '大阪府',
        '兵庫県',
        '奈良県',
        '和歌山県',
        '鳥取県',
        '島根県',
        '岡山県',
        '広島県',
        '山口県',
        '徳島県',
        '香川県',
        '愛媛県',
        '高知県',
        '福岡県',
        '佐賀県',
        '長崎県',
        '熊本県',
        '大分県',
        '宮崎県',
        '鹿児島県',
        '沖縄県'
      ],
      ages: [
        '不明',
        '20代',
        '30代',
        '40代',
        '50代',
        '60代',
        '70代',
        '80代以上'
      ],
      jobs: [
        '不明',
        '経営者',
        '役員',
        '施設長',
        '管理者',
        '総務、事務',
        '物品購入担当者'
      ],
      icons: {
        mdiArrowLeftCircle
      },
      rules: {
        required: (value) => !!value || '必須項目です',
        counter127: (value) => value.length <= 127 || '最大127文字です',
        counter255: (value) => value.length <= 255 || '最大255文字です',
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'メールアドレスの形式が正しくありません'
        },
        postalcode: (value) => {
          const pattern = /^\d{3}[-]?\d{4}$/
          return pattern.test(value) || '郵便番号の形式が正しくありません'
        },
        phonenumber: (value) => {
          const pattern = /(((^\+81(\d{1}[-(]?\d{4}|\d{2}[-(]?\d{3}|\d{3}[-(]?\d{2}|\d{4}[-(]?\d{1}|[5789]0[-(]?\d{4})[-)]?)|\d{1,4}-?)\d{4}|0120[-(]?\d{3}[-)]?\d{3})$/
          return pattern.test(value) || '電話番号の形式が正しくありません'
        },
        fax: (value) => {
          const pattern = /(((^\+81(\d{1}[-(]?\d{4}|\d{2}[-(]?\d{3}|\d{3}[-(]?\d{2}|\d{4}[-(]?\d{1}|[5789]0[-(]?\d{4})[-)]?)|\d{1,4}-?)\d{4}|0120[-(]?\d{3}[-)]?\d{3})$/
          return pattern.test(value) || 'FAX番号の形式が正しくありません'
        },
        city: (value) => {
          const pattern = /^((?:札幌|旭川|伊達|石狩|盛岡|奥州|仙台|田村|南相馬|那須塩原|さいたま|千葉|東村山|武蔵村山|羽村|横浜|川崎|相模原|新潟|十日町|上越|富山|野々市|大町|静岡|浜松|名古屋|蒲郡|四日市|京都|大阪|堺|神戸|姫路|大和郡山|岡山|広島|廿日市|下松|岩国|北九州|福岡|田川|大村|熊本|宮古|富良野|別府|佐伯|黒部|小諸|塩尻|玉野|周南)市|.+?郡(?:玉村|大町|.+?)[町村]|.+?[市区町村])$/
          return pattern.test(value) || '政令指定都市の区は含めないでください'
        }
      }
    }
  },
  methods: {
    async getLoginInfo() {
      try {
        const response = await this.$axios.get(
          this.get_path +
            '?username=' +
            this.$store.state.user['custom:agencycode']
        )
        console.log('response_data: ' + JSON.stringify(response.data))
        this.userdata = response.data.Items[0]
      } catch (error) {
        console.error(error)
        this.errored = true
      } finally {
        this.loading = false
      }
    },
    async updateMyData() {
      try {
        if (this.userdata.family_name_kana === undefined) {
          this.userdata.family_name_kana = ''
        }
        if (this.userdata.given_name_kana === undefined) {
          this.userdata.given_name_kana = ''
        }
        if (this.userdata.address_building === undefined) {
          this.userdata.address_building = ''
        }
        if (this.userdata.fax === undefined) {
          this.userdata.fax = ''
        }
        if (this.userdata.parent_agencycode === undefined) {
          this.userdata.parent_agencycode = ''
        }
        if (this.userdata.age === undefined) {
          this.userdata.age = ''
        }
        if (this.userdata.job_name === undefined) {
          this.userdata.job_name = ''
        }
        if (this.userdata.company_name === undefined) {
          this.userdata.company_name = ''
        }
        if (this.userdata.main_industry === undefined) {
          this.userdata.main_industry = ''
        }
        if (this.userdata.facility_name === undefined) {
          this.userdata.facility_name = ''
        }
        if (this.userdata.facility_type === undefined) {
          this.userdata.facility_type = ''
        }
        const params = {
          family_name: this.userdata.family_name,
          family_name_kana: this.userdata.family_name_kana,
          given_name: this.userdata.given_name,
          given_name_kana: this.userdata.given_name_kana,
          email: this.userdata.email,
          phone_number: this.userdata.phone_number,
          fax: this.userdata.fax,
          postal_code: this.userdata.postal_code,
          address_prefecture: this.userdata.address_prefecture,
          address_city: this.userdata.address_city,
          address_street: this.userdata.address_street,
          address_building: this.userdata.address_building,
          role: this.userdata.role,
          tsunaguwa_id: this.userdata.tsunaguwa_id,
          agencycode: this.userdata.agencycode,
          parent_agencycode: this.userdata.parent_agencycode,
          facility_name: this.userdata.facility_name,
          facility_type: this.userdata.facility_type,
          company_name: this.userdata.company_name,
          main_industry: this.userdata.main_industry,
          job_name: this.userdata.job_name,
          age: this.userdata.age,
          username: this.userdata.username,
          created_At: this.userdata.created_At,
          contract_imm: this.userdata.contract_imm,
          contract_shop: this.userdata.contract_shop,
          status: this.userdata.status
        }
        const message =
          '以下の情報で更新します。\r\nよろしければ「OK」ボタンを押してください\r\n' +
          '\r\n姓名: ' +
          params.family_name +
          params.given_name +
          '\r\n姓名(カナ): ' +
          params.family_name_kana +
          params.given_name_kana +
          '\r\n施設名: ' +
          params.facility_name +
          '\r\n施設種別: ' +
          params.facility_type +
          '\r\n会社名: ' +
          params.company_name +
          '\r\n業種: ' +
          params.main_industry +
          '\r\n郵便番号: ' +
          params.postal_code +
          '\r\n住所: ' +
          params.address_prefecture +
          params.address_city +
          params.address_street +
          params.address_building +
          '\r\n電話番号: ' +
          params.phone_number +
          '\r\nFAX番号: ' +
          params.fax +
          '\r\nEメール: ' +
          params.email +
          '\r\n年代: ' +
          params.age +
          '\r\n役職: ' +
          params.job_name
        const headers = {
          accept: 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
        console.log(JSON.stringify(params))
        if (confirm(message)) {
          const response = await this.$axios.post(this.update_path, params, {
            headers: headers
          })
          console.log('response_data: ' + JSON.stringify(response.data))
          if (response.data.result == 1) {
            alert('ご登録情報を更新しました。')
            location.href = '/mypage/mydata'
          } else {
            alert('更新に失敗しました。')
          }
        }
      } catch (error) {
        console.error(error)
      }
    }
  },
  created() {
    setTimeout(this.commonHeight, 10)
    this.getLoginInfo()
    // ロード時、リサイズ時にcommon-heightの高さを調整する
    window.addEventListener('load', this.commonHeight)
    window.addEventListener('resize', this.commonHeight)
    setTimeout(this.loadChatbotGeneral, 1000)
  }
}
</script>

<style lang="scss"></style>
