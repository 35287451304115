<template>
  <div>
    <section class="wrapper login-content common-height common-content">
      <div class="news-block">
        <div class="content-inner">
          <div class="title">
            プライバシーポリシー
          </div>
          <div class="text">
            株式会社タリホー（以下「当社」といいます）は、お客様（利用会員、出品者、及び下記「本ウェブサイト等」の閲覧者を総称するものです）の個人を識別しうる情報を適切に保護することが社会的責務として重要と考え、下記のプライバシーポリシー（以下「本プライバシーポリシー」といいます）に基づき、個人情報の保護に関する法律その他の法令・ガイドライン等を遵守して、お客様の個人情報を取得、利用、第三者に預託または提供させていただきます。
            お客様が、当社が運営するウェブサイト「介護・医療総合プラットフォーム
            ツナグワ（以下「本サービス」といいます）」その他当社が運営するウェブサイト（以下総称して「本ウェブサイト等」といいます）のご利用をされるためには、本プライバシーポリシーを熟読のうえ、内容に同意していただく必要があります。
            同意いただけない場合は、本ウェブサイト等へのアクセス及び本サービスのご利用はお断りします。
          </div>
          <div class="header">1. 取得する情報について</div>
          <div class="text">
            当社が「３．取得情報の利用目的について」に定める目的を達成するために取得する情報には、次のものが含まれます（以下（１）および（２）を合わせて「取得情報」といいます）。
            <ol class="kakko">
              <li>
                個人情報について
                <p>
                  個人情報とは、個人情報の保護に関する法律（平成１５年法律第５７号、以下「個人情報保護法」といいます）２条１項に定める個人情報をいい、下記を含むものとします。
                </p>
                <ul class="disc">
                  <li>
                    お客様のお名前、生年月日、性別、その他の特定の個人を識別することができる情報
                  </li>
                  <li>
                    特定の個人情報に結びついて使用されるご住所、電話番号、所属する会社その他の組織の名称、所属する部署の名称、ユーザーID、ニックネーム、電子メールアドレス、パスワード等の会員に関する情報
                  </li>
                  <li>
                    クレジットカード番号、その他のクレジットカードに関する情報、銀行口座番号
                  </li>
                  <li>お客様の本人確認に関する情報</li>
                </ul>
              </li>
              <li>
                お客様情報について
                <p>
                  当社は、個人情報に該当するか否かにかかわらず、本ウェブサイト等の利用に関するお客様の情報を取得します。
                </p>
                <ul class="disc">
                  <li>
                    お客様がご利用になった本ウェブサイト等の内容、ご利用日時、ご利用回数などのご利用内容・ご利用履歴に関する情報（これらの情報にはお客様が利用されるURL、ブラウザや携帯電話の種類やIPアドレス、クッキー情報などの情報を含みます。）
                  </li>
                  <li>お客様のご利用に関連した決済状況に関する情報</li>
                </ul>
              </li>
            </ol>
          </div>
          <div class="header">2. 取得情報の収集について</div>
          <div class="text">
            <p>
              当社は、本ウェブサイト等をご利用になるお客様の取得情報を、以下の方法により収集します。
            </p>
            <ol class="kakko">
              <li>本ウェブサイト等上でお客様自身に直接入力いただく方法</li>
              <li>
                お客様から当社に対し、電子メール、メッセージ送受信アプリ、郵便、書面、電話、オンライン会議システム等の手段によりご提供いただく方法（当社はお客様との電話応対時またはオンライン会議システム応対時、品質向上等を目的とし、通話の録音または画面の録画をさせていただく場合があります）
              </li>
              <li>お客様が本ウェブサイト等へアクセスされた際に収集する方法</li>
            </ol>
          </div>
          <div class="header">3. 取得情報の利用目的について</div>
          <div class="text">
            <p>
              当社は、取得情報を取得・収集する際にお客様にお知らせした利用目的または以下の目的のために、利用させていただきます。
              これらの利用目的以外には、下記「４．取得情報の第三者への預託、提供について」に記載する場合または事前にお客様に同意をいただいた場合を除き、利用致しません。
              なお、お客様は、お客様に対する情報提供について、会員登録時及び登録後に、情報提供を断ることができます。
              但し、本サービス運営上必要となる情報提供については、情報提供を断った場合には本ウェブサイト等をご利用になれませんので、あらかじめご了承ください。
            </p>
            <ol class="kakko">
              <li>
                本ウェブサイト等で安全かつ確実に取引をして頂くために利用者に電子メール等で取引の状態や重要な事項を連絡させて頂くため
              </li>
              <li>
                本取引（本ウェブサイト等においてお客様間でサービスや商品の購入に関する取引を行うことをいい、以下同様とします）の購入確認をするため
              </li>
              <li>
                電子メール配信サービスのお申し込みの確認やメールを配信するため
              </li>
              <li>
                お客様による本サービスへの登録の確認やお客様に対して本サービスを提供し、安全かつ確実に本取引を行って頂くため
              </li>
              <li>本取引・本サービスのご請求、お支払いとその確認をするため</li>
              <li>
                本取引について、後払いでの決済手段を利用する場合に、安全かつ確実に利用して頂く目的でお客様の与信審査を行うため
              </li>
              <li>ご協力いただいた調査等に対して謝礼等をお送りするため</li>
              <li>
                懸賞等のご案内を行い、ご応募いただいた懸賞等などに対する景品等をお送りするため
              </li>
              <li>本ウェブサイト等運営上のトラブルの解決のため</li>
              <li>
                本サービスの不正利用・トラブルの防止及び不正利用の調査のため
              </li>
              <li>
                本取引・本サービス、本ウェブサイト等などの内容をお客様がよりご満足いただけるよう改良するため
              </li>
              <li>
                お客様が注文された情報や本取引などに関する満足度を調査するため
              </li>
              <li>
                個人情報を統計的に処理した情報を集約し調査結果として公表するため
              </li>
              <li>
                お客様のご利用状況を把握し、本サービスの改善や新機能、新サービスの開発に役立てるため
              </li>
              <li>
                各種会員制サービス、その他各種サービスのご案内をお届けするため
              </li>
              <li>当社又は第三者による広告の配信・表示のため</li>
              <li>
                アンケート等の調査のご協力や各種イベントへのご参加の依頼、またはその結果等をご報告するため
              </li>
              <li>
                本取引、本サービス、本ウェブサイト等などの利用状況を把握し、当社をはじめお客様に有益と思われる企業/団体の情報や調査などをお届けするため
              </li>
              <li>
                上記（１）から（１８）の利用目的の達成のために、お客様の個人情報や個人データを第三者に提供するため。
              </li>
            </ol>
          </div>
          <div class="header">4. 取得情報の第三者への預託、提供について</div>
          <div class="text">
            <ol class="kakko">
              <li>
                当社は、以下に定める場合を除き、事前にお客様の同意を得ることなしで取得情報を第三者に預託、提供しません。
                <ul class="kana">
                  <li>
                    本ウェブサイト等で本サービスを提供するために当社が必要と判断した場合の、本ウェブサイト等上での情報の開示や共有公開された取引相手の情報は、当該取引のためにのみ利用するものとし、お客様の事前の同意なく、これを第三者に開示いたしません。
                    またお客様の本ウェブサイト等利用履歴や一部の公開用の取得情報（施設種別、購入した商品名等）は、本ウェブサイト等上で当社が定める期間、公開されます。
                  </li>
                  <li>
                    業務委託先への預託・提供<br />
                    当社は、「3.
                    取得情報の利用目的について」に規定される利用目的を達成するために必要な範囲において、当社が信頼に足ると判断し個人情報の守秘義務契約を結んだ企業に、業務の全部または一部を委託する場合があり、この場合、当社の厳正な管理下のもと、本ウェブサイト等の利用目的に必要な範囲、かつ業務を行うのに必要最小限の範囲で、当該業務委託先企業に取得情報を預託、提供させていただくことがあります。
                    なお、当社は委託先における情報管理が適切に行われていることを適宜確認し、取得情報が適正に管理されるように確保します。
                  </li>
                  <li>
                    事業の譲渡に伴う事業売却先への提供<br />
                    当社は、企業として、合併や事業の売却をさせていただく場合があります。
                    その場合、本プライバシーポリシーと同等の方針に従うことを条件に、事業売却先の第三者に取得情報を提供させていただくことがあります。
                  </li>
                  <li>
                    法令等に基づく提供<br />
                    当社は、裁判所、行政機関、監督官庁その他の公的機関から取得情報を提供するよう求められた場合、提供を拒否する合理的な理由がない場合には、取得情報を提供させていただくことがあります。
                  </li>
                </ul>
              </li>
              <li>
                当社は、前項のいずれの場合にも該当しない場合であっても、個人情報保護委員会に届け出た上で、次の範囲内で、お客様ご本人の個人データ（要配慮個人情報を除きます。以下、本項において同じです。）を第三者（外国にある第三者を除きます。以下、本項において同じです。）に提供することがあります。
                <ul class="maru_number">
                  <li>
                    <span>&#9312;</span>
                    本項に基づき、第三者に提供される個人データの項目<br />
                    お客様ご本人から特に申し入れがある場合を除いて、お客様ご本人の全ての個人情報
                  </li>
                  <li>
                    <span>&#9313;</span>
                    本項に基づく、第三者への提供の方法<br />
                    紙、電子データの伝送、外部記録媒体の形式での交付、サーバーへのアクセスのいずれかの方法
                  </li>
                  <li>
                    <span>&#9314;</span>
                    本項に基づく提供停止のお求めの方法<br />
                    お客様ご本人より当社に対し、本項に基づく第三者への提供を停止するようお求めがあったときは、法令の定めに基づき遅滞なくお客様ご本人が識別される個人データの当該第三者への提供を停止いたします。
                    第三者提供の停止請求方法については、下記１２.記載の「お問い合わせ窓口」までご送付ください。
                  </li>
                </ul>
              </li>
            </ol>
          </div>
          <div class="header">5. クッキーの使用について</div>
          <div class="text">
            <p>
              本ウェブサイト等は、お客様のコンピュータの記憶装置に、「クッキー」と呼ばれるテキストファイルを送付し、保存・利用させていただくことがございます。
              クッキーの利用目的はお客様のサービスログイン時の必要情報の自動入力することや興味をお持ちであろう分野のコンテンツを表示するなどの利便性向上のために使用します。
              なお、お客様は、「クッキー」を受け取る前にブラウザが警告を出す様に設定しておく事により、お客様の判断で「クッキー」を受け取る事を拒否できますが、本ウェブサイト等の機能または本サービスがご利用になれない場合があります。
              また、本ウェブサイト等では、ウェブサイト又はアプリケーションを改善する目的でGoogle
              Analyticsを利用し、それに伴いかかる目的でお客様の情報を利用しています。Google
              Analyticsは、クッキーを利用してお客様の情報を収集します。Google
              Analyticsの利用規約及びプライバシーポリシーに関する説明については、以下のGoogle
              Analyticsのサイトをご覧ください。
            </p>
            <ul class="disc">
              <li>
                Googleのサービスを使用するサイトやアプリから収集した情報のGoogleによる使用
                <a
                  href="https://policies.google.com/technologies/partner-sites"
                  target="_blank"
                  >https://policies.google.com/technologies/partner-sites</a
                >
              </li>
              <li>
                Googleアナリティクス オプトアウト ブラウザ アドオン
                <a
                  href="https://tools.google.com/dlpage/gaoptout?hl=jp"
                  target="_blank"
                  >https://tools.google.com/dlpage/gaoptout?hl=jp</a
                >
              </li>
              <li>
                Googleアナリティクスの広告向け機能について
                <a
                  href="https://support.google.com/analytics/answer/3450482?hl=ja"
                  target="_blank"
                  >https://support.google.com/analytics/answer/3450482?hl=ja</a
                >
              </li>
            </ul>
          </div>
          <div class="header">6. 当社による取得情報の訂正について</div>
          <div class="text">
            ご登録いただいた取得情報のうち、市町村などの名称および郵便番号、金融機関の名称あるいはクレジットカードの有効期限など、ご請求を行う上で支障がある情報に変更があった場合には、当社が登録されている取得情報を変更させていただく場合があります。
          </div>
          <div class="header">7. 取得情報の管理およびセキュリティについて</div>
          <div class="text">
            当社では、収集させていただいた取得情報を、一般の利用者がアクセスできない環境下にある、ファイヤーウォールで保護されたサーバーにて安全に保管し、不正アクセス・紛失・破壊・改ざんまたは漏洩の防止その他の個人データの安全管理のために必要かつ適切な措置を講じます。
            なお、当社では、当社が信頼に足ると判断し個人情報の守秘義務契約を結んだ企業に、個人情報の取り扱いを委託する場合がありますが、この場合にも、取得情報は本プライバシーポリシーのもとで保護されます。
          </div>
          <div class="header">8. 免責・注意事項について</div>
          <div class="text">
            当社は、本ウェブサイト等のウェブページにリンクされている他の事業者または個人のウェブサイトにおけるお客様の個人情報等の保護について、責任を負うものではありません。
            お客様ご自身にて当該ウェブサイトの内容をよくご確認の上で、閲覧およびご利用をお願いいたします。<br />
            当社は、お客様の個人情報を不正なアクセスから守るため､データの保護が必要であると判断した場合、データ転送にSSL(Secure
            Socket Layer)という業界標準の暗号方式を使用しています。
            SSL非対応ブラウザによる本ウェブサイト等上での登録、申込もしくは注文、または電子メールもしくは郵便により取得情報をご提供いただく場合は、当社への情報到達過程にて、当社の管理外の第三者に閲覧、窃取される危険性が高まりますので、これらの危険性をご了解の上これらの手段をご利用ください。<br />
            万一、当社によるセキュリティ施策にもかかわらず、ハッカー等による不当な行為により、お客様および第三者に損害が生じた場合については、当社は責任を負いかねますのでご了承ください。
            お客様ご本人を確認するための情報につきましては、お客様にて、紛失、忘失または第三者に知られることのないよう厳重な管理をお願いいたします。
          </div>
          <div class="header">9. 海外からのアクセスについて</div>
          <div class="text">
            本ウェブサイト等の利用、管理、運用方法および掲載内容が違法または不適切とされる国または地域からの本ウェブサイト等へのアクセスはご遠慮を願います。
          </div>
          <div class="header">
            10.
            本プライバシーポリシーの改善および最新の本プライバシーポリシーの確認について
          </div>
          <div class="text">
            当社は本プライバシーポリシーについて、適宜その改善に努めていきます。
            最新の本プライバシーポリシーは、当社のウェブサイトでご覧になれます。
            お客様は、本ウェブサイト等にアクセスするか当社の本サービスをご利用になる前に、必ず最新の本プライバシーポリシーをご確認ください。
            お客様が本ウェブサイト等にアクセスされるか当社の本サービスをご利用になられた場合は、最新の本プライバシーポリシーの内容に同意されたものとみなします。
          </div>
          <div class="header">
            11. 取得情報の開示・訂正・利用停止等の手続について
          </div>
          <div class="text">
            当社は、お客様ご本人から、法令等に定める理由によって、取得情報の内容の訂正、追加もしくは削除、第三者提供記録の確認又は第三者提供の停止を求められた場合には、他の法令の規定により特別の手続きが定められている場合を除き、利用目的の達成に必要な範囲内において、遅滞なく必要な調査を行い、その結果に基づき、取得情報の内容の訂正、追加または削除等法令上必要な措置を行い、その旨ご本人に通知します。
          </div>
          <div class="header">12. お問い合わせ窓口</div>
          <div class="text">
            本プライバシーポリシーに関するお問い合わせは、下記のお問い合わせ先までお願いいたします。<br />
            〒103-0027<br />
            東京都中央区日本橋2-1-17 丹生ビル2階<br />
            株式会社タリホー 個人情報担当者宛<br />
            Mail: contact@tallyho.jp
          </div>
          <div class="text">2021年7月1日制定・施行</div>
          <div class="text">2022年3月31日改訂</div>
          <div class="text">2023年11月1日改訂</div>
          <div class="text">2024年3月1日改訂</div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Common from '@/plugin/Common'

export default {
  mixins: [Common],
  name: 'PrivacyPolicy',
  created() {
    setTimeout(this.commonHeight, 10)
    // ロード時、リサイズ時にcommon-heightの高さを調整する
    window.addEventListener('load', this.commonHeight)
    window.addEventListener('resize', this.commonHeight)
    setTimeout(this.loadChatbotGeneral, 1000)
  }
}
</script>

<style lang="scss"></style>
