<template>
  <div>
    <v-app>
      <section class="wrapper login-content common-height common-content">
        <div class="wrapper title-block">
          <h2 class="common-title">
            入居者リクエスト
          </h2>
        </div>
        <div class="consults-block">
          <span class="sub-title">施設情報</span>
          <table class="detailtable">
            <tbody>
              <tr class="trline">
                <th class="fw_normal x_center w20 pt5 pb5">施設名</th>
                <td>
                  <v-card class="pa-1" outlined tile>
                    <v-select
                      :items="careHomeItems"
                      v-model="selectCareHome"
                      item-text="care_home_name"
                      item-value="care_home_id"
                      label="施設id"
                      dense
                      outlined
                      hide-details
                      @change="getCareHomeInfo"
                    ></v-select>
                  </v-card>
                </td>
              </tr>
              <tr>
                <th class="fw_normal x_center w20 pt5 pb5">連絡先</th>
                <td>{{ careHomeInfo.tel }}</td>
              </tr>
            </tbody>
          </table>

          <span class="sub-title">入居者リクエスト情報</span>
          <table class="detailtable">
            <tbody>
              <tr class="trline">
                <th class="request_date">リクエスト日</th>
                <td>
                  {{ requestInfo.request_date }}
                </td>
              </tr>
              <tr>
                <th>性別</th>
                <td>
                  <v-row>
                    <v-col cols="5">
                      <v-checkbox
                        v-model="requestInfo.request_gender_1"
                        label="男性"
                        color="primary"
                        hide-details
                      ></v-checkbox>
                      <v-spacer></v-spacer>
                      <v-checkbox
                        v-model="requestInfo.request_gender_2"
                        label="女性"
                        color="primary"
                        hide-details
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </td>
              </tr>
              <tr>
                <th>要介護度</th>
                <td>
                  <div>
                    <v-checkbox
                      label="全て"
                      color="primary"
                      hide-details
                      v-model="isAllLevelsChecked"
                      @change="checkAllLevels"
                    ></v-checkbox>
                  </div>
                  <div>
                    <v-checkbox
                      v-model="requestInfo.level_independent"
                      label="自立"
                      color="primary"
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="requestInfo.level_support1"
                      label="要支援1"
                      color="primary"
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="requestInfo.level_support2"
                      label="要支援2"
                      color="primary"
                      hide-details
                    ></v-checkbox>
                  </div>
                  <div>
                    <v-checkbox
                      v-model="requestInfo.level_nursing_care1"
                      label="要介護1"
                      color="primary"
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="requestInfo.level_nursing_care2"
                      label="要介護2"
                      color="primary"
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="requestInfo.level_nursing_care3"
                      label="要介護3"
                      color="primary"
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="requestInfo.level_nursing_care4"
                      label="要介護4"
                      color="primary"
                      hide-details
                    ></v-checkbox>
                    <v-checkbox
                      v-model="requestInfo.level_nursing_care5"
                      label="要介護5"
                      color="primary"
                      hide-details
                    ></v-checkbox>
                  </div>
                </td>
              </tr>
              <tr>
                <th>空室情報</th>
                <td>
                  <div>
                    <v-text-field
                      v-model="requestInfo.number_of_vacancy"
                      persistent-hint
                      required
                      outlined
                      type="number"
                      label="空室数："
                      max="99"
                      min="1"
                    ></v-text-field>
                    <span class="small" style="color:red">(必須)</span>
                  </div>
                </td>
              </tr>
              <tr>
                <th>ファイル</th>
                <td>
                  <div>
                    <v-file-input
                      v-model="consultInfoFile"
                      label="関連ファイル(5MbまでのPDFもしくは画像)"
                      accept=".png,.jpg,.jpeg,.pdf"
                      outlined
                      dense
                      :prepend-icon="icons.mdiCloudUpload"
                    ></v-file-input>
                  </div>
                </td>
              </tr>
              <tr>
                <th>備考欄</th>
                <td>
                  <div>
                    <v-textarea
                      v-model="requestInfo.remarks"
                      label="キャンペーン情報等"
                      persistent-hint
                      outlined
                      counter="255"
                    ></v-textarea>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <CommonDialog
            name="リクエスト送信"
            title="リクエスト送信"
            message="入居者リクエスト情報を送信しますか？"
            color="#94c43c"
            class-name="request-btn"
            v-on:acceptAction="sendRequest"
          ></CommonDialog>
          <router-link to="/introduce-resident-service/">
            <v-btn color="primary">
              <v-icon left dark>
                {{ icons.mdiArrowLeftCircle }}
              </v-icon>
              戻る
            </v-btn>
          </router-link>
        </div>
      </section>
    </v-app>
  </div>
</template>

<script>
import Common from '@/plugin/Common'
import * as apiclient from '@/apiclient'
import CommonDialog from '@/components/common/CommonDialog'
import { mdiArrowLeftCircle } from '@mdi/js'
import moment from 'moment'

export default {
  mixins: [Common],
  name: 'VacantInfoRequest',
  components: {
    CommonDialog
  },
  created() {
    //setTimeout(this.loadChatbotGeneral, 1000)
    this.getLoginInfo()
    this.requestInfo.request_date = moment().format('YYYY年MM月DD日')
  },
  data: function() {
    return {
      careHomeItems: [],
      selectCareHome: '0',
      careHomeInfo: {},
      requestInfo: {
        request_gender_1: false,
        request_gender_2: false,
        level_independent: false,
        level_support1: false,
        level_support2: false,
        level_nursing_care1: false,
        level_nursing_care2: false,
        level_nursing_care3: false,
        level_nursing_care4: false,
        level_nursing_care5: false,
        number_of_vacancy: '',
        remarks: '',
        file_key: ''
      },
      consultInfoFile: null,
      icons: {
        mdiArrowLeftCircle
      },
      isAllLevelsChecked: false
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  },
  methods: {
    getLoginInfo: function() {
      try {
        apiclient.TsunaguwaAuthApi.getLoginInfo((responseData) => {
          this.careHomeItems = responseData.payload.user_care_homes
          if (this.careHomeItems.length > 0) {
            this.selectCareHome = this.careHomeItems[0].care_home_id
            this.careHomeInfo = this.careHomeItems[0]
          }
        })
      } catch (error) {
        throw error.response
      }
    },
    checkAllLevels: function(event) {
      this.requestInfo.level_independent = this.isAllLevelsChecked
      this.requestInfo.level_support1 = this.isAllLevelsChecked
      this.requestInfo.level_support2 = this.isAllLevelsChecked
      this.requestInfo.level_nursing_care1 = this.isAllLevelsChecked
      this.requestInfo.level_nursing_care2 = this.isAllLevelsChecked
      this.requestInfo.level_nursing_care3 = this.isAllLevelsChecked
      this.requestInfo.level_nursing_care4 = this.isAllLevelsChecked
      this.requestInfo.level_nursing_care5 = this.isAllLevelsChecked
    },
    getCareHomeInfo: function() {
      try {
        if (this.isEmpty(this.selectCareHome)) {
          return
        }
        var params = {
          care_home_id: this.selectCareHome
        }
        apiclient.TsunaguwaDxCarehomesApi.getCarehome((responseData) => {
          this.careHomeInfo = responseData.payload
        }, params)
      } catch (error) {
        throw error.response
      }
    },
    sendRequest: function() {
      try {
        if (this.isEmpty(this.requestInfo.number_of_vacancy)) {
          alert('空室数の入力は必須です')
          return
        }
        if (this.consultInfoFile != null) {
          var uploadparams = {
            infoType: '001',
            careHomeId: this.selectCareHome.care_home_id,
            entryId: ''
          }
          apiclient.IMatchConsultsApi.uploadFileInfo(
            uploadparams,
            this.consultInfoFile,
            this.sendMail
          )
        } else {
          this.sendMail({ payload: { key: '' } })
        }
      } catch (error) {
        throw error.response
      }
    },
    sendMail: function(uploadResponseData) {
      try {
        if (uploadResponseData == null) {
          this.alertFailSend()
          return
        }
        let fileKey = uploadResponseData.payload.key
        this.requestInfo.file_key = fileKey

        var params = {
          care_home_info: this.careHomeInfo,
          resident_consult_info: this.requestInfo
        }
        apiclient.IMatchConsultsApi.registResidentConsult(
          params,
          (responseData) => {
            if (responseData != null) {
              alert('入居者リクエストを送信しました。')
            } else {
              alert(
                '入居者リクエストに失敗しました。しばらく待機してから再送信してください。'
              )
            }
          }
        )
      } catch (error) {
        throw error.response
      }
    }
  }
}
</script>

<style lang="scss"></style>
