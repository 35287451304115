<template>
  <div>
    <section class="wrapper login-content common-height common-content">
      <div class="news-block">
        <div class="page-hierarchy">
          <router-link to="/">ホーム</router-link>
          <span class="arrow"></span>
          <router-link to="/news-list/">介護・医療ニュース</router-link>
        </div>
        <div class="news-detail-content">
          <ul class="category-tag-list">
            <li
              v-for="category in categories"
              :key="category"
              class="category-item"
            >
              {{ category }}
            </li>
            <li v-for="tag in tags" :key="tag" class="tag-item">
              <picture>
                <source
                  srcset="../assets/img/left-icon/tag.avif"
                  type="image/avif"
                />
                <source
                  srcset="../assets/img/left-icon/tag.webp"
                  type="image/webp"
                />
                <img src="../assets/img/left-icon/tag.png" alt="タグ" />
              </picture>
              <span class="name">{{ tag }}</span>
            </li>
          </ul>
          <div class="content-inner mb">
            <h2 class="title">{{ post.title.rendered }}</h2>
            <p class="date">{{ post.date | momentDetail }}<span>配信</span></p>
            <div class="text" v-html="post.content.rendered"></div>
            <div class="align-right">
              <a
                href="https://www.koureisha-jutaku.com/"
                class="provider-link"
                target="_blank"
                >提供:高齢者住宅新聞社</a
              >
            </div>
            <div class="attention">
              ※こちらの記事は株式会社高齢者住宅新聞社との契約に基づき許可を得て掲載しています。無許可の転載は固くお断りします。
            </div>
          </div>
          <router-link to="/news-list/" class="regular-btn align-center"
            >記事一覧</router-link
          >
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CommonFilters from '../plugin/CommonFilters'
import Common from '@/plugin/Common'

require('@/plugin/vue-awesome-swiper')

export default {
  name: 'CareMedicalNewsDetail',
  props: {
    id: { type: Number }
  },
  mixins: [CommonFilters, Common],
  computed: {
    ...mapGetters(['newsPostList'])
  },
  methods: {
    ...mapActions(['newsPostsAction'])
  },
  created() {
    this.$axios
      .get(process.env.VUE_APP_NEWS_API_URL + '/' + this.id)
      .then((response) => {
        this.post = response.data
        // タグ
        this.$axios
          .get(process.env.VUE_APP_NEWS_TAG_API_URL)
          .then((response) => {
            let allTags = response.data
            for (let tag of this.post.tags) {
              let target = allTags
                .filter(function(object) {
                  return object.id === tag
                })
                .shift()
              this.tags.push(target.name)
            }
          })
        // カテゴリー
        this.$axios
          .get(process.env.VUE_APP_NEWS_CAT_API_URL)
          .then((response) => {
            let allCategories = response.data
            for (let category of this.post.categories) {
              let target = allCategories
                .filter(function(object) {
                  return object.id === category
                })
                .shift()
              this.categories.push(target.name)
            }
          })
      })
    setTimeout(this.loadChatbotGeneral, 1000)
  },
  mounted() {
    // ロード時、リサイズ時にcommon-heightの高さを調整する
    setTimeout(this.commonHeight, 10)
    window.addEventListener('resize', this.commonHeight)
  },
  data: function() {
    return {
      post: {
        title: { rendered: '' },
        categories: [],
        tags: [],
        content: { rendered: '' }
      },
      categories: [],
      tags: []
    }
  }
}
</script>

<style lang="scss"></style>
