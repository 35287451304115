import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#F7B57F',
        download: '#618BDE',
        upload: '#57BA63',
        secondary: '#b0bec5',
        disabled: '#b0bec5',
        accent: '#8c9eff',
        error: '#b71c1c',
        mobilePrimary: '#FF8C2D',
        mobileSecondary: '#ff7300',
        mobileError: '#E8340D',
        mobileDang: '#ff2d00'
      }
    }
  },
  icons: {
    iconfont: 'mdiSvg'
  }
})
