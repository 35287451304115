<template>
  <div :class="boxClass">
    <a
      href="javascript:void(0)"
      v-for="item in adList"
      :key="item.id"
      v-on:click="adClickEvent(item)"
    >
      <div class="pr-img">
        <picture>
          <source
            :srcset="item.content_url.replace(/(gif|png|jpg)/, 'avif')"
            type="image/avif"
          />
          <source
            :srcset="item.content_url.replace(/(gif|png|jpg)/, 'webp')"
            type="image/webp"
          />
          <img :src="item.content_url" />
        </picture>
      </div>
      <div class="pr-info" v-if="showCaption">
        <p class="pr-title">{{ item.url_caption }}</p>
      </div>
    </a>
  </div>
</template>

<script>
import Common from '@/plugin/Common'
import * as apiclient from '@/apiclient'
import moment from 'moment'

export default {
  name: 'AdsBox',
  mixins: [Common],
  data() {
    return {
      adList: []
    }
  },
  computed: {
    showCaption() {
      if (this.withCaption !== undefined && this.withCaption !== null) {
        return this.withCaption
      }
      return false
    }
  },
  props: {
    boxClass: [String, Array],
    adPositionCode: { type: String },
    withCaption: { type: Boolean }
  },
  mounted() {
    this.getAdList()
  },
  methods: {
    getAdList: function() {
      try {
        apiclient.TsunaguwaAdApi.getAdList(
          this.adPositionCode,
          this.apiResposeCallback
        )
      } catch (error) {
        throw error.response
      }
    },
    apiResposeCallback: function(responseData) {
      this.adList = responseData.payload.ad_list
    },
    adClickEvent: function(item) {
      this.registerAdImpression(item)
      if (!this.isEmpty(item.url)) {
        window.open(item.url, '_blank')
      }
    },
    registerAdImpression: function(item) {
      apiclient.TsunaguwaAdApi.registerAdClick(item.id, function() {})
    }
  }
}
</script>
