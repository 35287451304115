<template>
  <section class="content status-content">
    <div class="content-type2">
      <h2 class="title">
        <picture>
          <source
            srcset="../../assets/img/new/main/movein-schedule-title.avif"
            type="image/avif"
          />
          <source
            srcset="../../assets/img/new/main/movein-schedule-title.webp"
            type="image/webp"
          />
          <img
            src="../../assets/img/new/main/movein-schedule-title.png"
            alt="入居予定"
          />
        </picture>
      </h2>
      <div class="inner-content" v-if="showResidentsList.length > 0">
        <table class="status-table" id="status-table-movein">
          <tr>
            <th>日付</th>
            <th>施設</th>
            <th>名前</th>
            <th>介護度</th>
          </tr>
          <tr
            v-for="(item, index) in showResidentsList"
            :key="item.name"
            v-bind:index="index"
          >
            <td class="date">
              {{ item.expected_move_in_date }}
            </td>
            <td class="facility">
              {{ item.care_home_name }}
            </td>
            <td class="name">
              {{ item.family_name + ' ' + item.first_name }}
            </td>
            <td>
              {{ item.care_level }}
            </td>
          </tr>
        </table>

        <div>
          <a
            href="#status-table-movein"
            class="status-table-btn"
            @click="updateMaxItems(10)"
            >＞10件表示</a
          >
          <a
            href="#status-table-movein"
            class="status-table-btn"
            @click="updateMaxItems(30)"
            >＞30件表示</a
          >
          <a
            href="#status-table-movein"
            class="status-table-btn"
            @click="updateMaxItems(50)"
            >＞50件表示</a
          >
          <a
            href="#status-table-movein"
            class="status-table-btn"
            @click="updateMaxItems(100)"
            >＞100件表示</a
          >
        </div>
      </div>
      <div class="inner-content" v-else>
        入居者情報がありません。<br />
        DXサービスで登録可能です。
      </div>
    </div>
  </section>
</template>

<script>
import Common from '@/plugin/Common'
import * as apiclient from '@/apiclient'

export default {
  mixins: [Common],
  props: {
    carehomeId: null
  },
  data: function() {
    return {
      residentsList: [],
      showMaxItems: 5
    }
  },
  computed: {
    showResidentsList() {
      if (this.isEmpty(this.residentsList)) {
        return []
      }
      return this.residentsList.slice(0, this.showMaxItems)
    }
  },
  components: {},
  created() {
    this.getResidentsList()
  },
  methods: {
    getResidentsList: function() {
      try {
        var params = {
          status: '010'
        }
        if (!this.isEmpty(this.carehomeId)) {
          params.care_home_id = this.carehomeId
        }
        apiclient.TsunaguwaDxCarehomesApi.getResidentsList(
          this.dxCarehomesApiResposeCallback,
          params
        )
      } catch (error) {
        throw error.response
      }
    },
    dxCarehomesApiResposeCallback: function(responseData) {
      this.residentsList = responseData.payload.residents_list
    },
    updateMaxItems(maxItems) {
      this.showMaxItems = maxItems
    }
  }
}
</script>
