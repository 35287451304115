var ToolsConfiguration = /** @class */ (function () {
    function ToolsConfiguration() {
    }
    return ToolsConfiguration;
}());
export { ToolsConfiguration };
export var TsunaguwaConfig = {
    //共通ツナグワ設定
    Common: {
        SecretKey: ''
    },
    Cookies: {
        Secure: false
    },
    //ユーザ情報クッキー設定
    UserInfoCookie: {
        Key: '',
        Domain: ''
    }
};
