<template>
  <div>
    <v-app>
      <section class="wrapper login-content common-height common-content">
        <div class="wrapper title-block">
          <h2 class="common-title">
            入居者紹介サービス
          </h2>
        </div>
        <div class="owner-btns-block">
          <ul class="introduceresidentservice-btns-list">
            <li>
              <router-link to="/introduce-services/movein-resident-consult">
                <div class="icon-img box-left">
                  <picture v-if="hasNewMoveinResidentConsults">
                    <source
                      srcset="../assets/img/introduce-resident-service/movein_consultation_new.avif"
                      type="image/avif"
                    />
                    <source
                      srcset="../assets/img/introduce-resident-service/movein_consultation_new.webp"
                      type="image/webp"
                    />
                    <img
                      src="../assets/img/introduce-resident-service/movein_consultation_new.png"
                      alt="入居相談"
                    />
                  </picture>
                  <picture v-else>
                    <source
                      srcset="../assets/img/introduce-resident-service/movein_consultation.avif"
                      type="image/avif"
                    />
                    <source
                      srcset="../assets/img/introduce-resident-service/movein_consultation.webp"
                      type="image/webp"
                    />
                    <img
                      src="../assets/img/introduce-resident-service/movein_consultation.png"
                      alt="入居相談"
                    />
                  </picture>
                </div>
              </router-link>
            </li>
            <li>
              <div class="icon-img box-right">
                <MouveoutInputDialog
                  :care-home="selectCareHome"
                  :care-home-items="careHomeItems"
                />
              </div>
            </li>
            <li>
              <router-link to="/introduce-services/vacant-info-request">
                <div class="icon-img box-left">
                  <picture>
                    <source
                      srcset="../assets/img/introduce-resident-service/vacancy_information.avif"
                      type="image/avif"
                    />
                    <source
                      srcset="../assets/img/introduce-resident-service/vacancy_information.webp"
                      type="image/webp"
                    />
                    <img
                      src="../assets/img/introduce-resident-service/vacancy_information.png"
                      alt="空室情報・入居者リクエスト"
                    />
                  </picture>
                </div>
              </router-link>
            </li>
            <li>
              <div class="icon-img box-right">
                <IntermediateRequestDialog
                  :care-home="selectCareHome"
                  :care-home-items="careHomeItems"
                />
              </div>
            </li>
          </ul>
        </div>
      </section>
    </v-app>
  </div>
</template>

<script>
import Common from '@/plugin/Common'
import * as apiclient from '@/apiclient'
import MouveoutInputDialog from './IntroduceServices/components/MouveoutInputDialog'
import IntermediateRequestDialog from './IntroduceServices/components/IntermediateRequestDialog'

export default {
  mixins: [Common],
  name: 'IntroduceResidentService',
  components: {
    MouveoutInputDialog,
    IntermediateRequestDialog
  },
  created() {
    setTimeout(this.loadChatbotGeneral, 1000)
    this.getLoginInfo()
  },
  data: function() {
    return {
      careHomeItems: [],
      selectCareHome: {},
      hasNewMoveinResidentConsults: false
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    }
  },
  methods: {
    getLoginInfo: function() {
      try {
        apiclient.TsunaguwaAuthApi.getLoginInfo((responseData) => {
          this.careHomeItems = responseData.payload.user_care_homes
          if (this.careHomeItems.length > 0) {
            this.selectCareHome = this.careHomeItems[0]
          }
          this.getNewResidentConsults()
        })
      } catch (error) {
        throw error.response
      }
    },
    getNewResidentConsults: function() {
      try {
        var params = { imm_care_home_id: this.selectCareHome.imm_care_home_id }
        apiclient.IMatchConsultsApi.getNewMoveinResident(
          params,
          (responseData) => {
            this.hasNewMoveinResidentConsults = responseData.payload.has_new
          }
        )
      } catch (error) {
        throw error.response
      }
    }
  }
}
</script>

<style lang="scss"></style>
