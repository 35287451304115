<template>
  <section class="content movein-info">
    <div class="content-type2">
      <h2 class="title">
        <picture>
          <source
            srcset="../../assets/img/new/main/movein-title.avif"
            type="image/avif"
          />
          <source
            srcset="../../assets/img/new/main/movein-title.webp"
            type="image/webp"
          />
          <img
            src="../../assets/img/new/main/movein-title.png"
            alt="入居予定"
          />
        </picture>
      </h2>
      <div class="info-block">
        <div class="empty-info">
          <div class="info-item-2">
            <dl>
              <dt>稼働率:</dt>
              <dd>{{ occupancy_rate }}％</dd>
            </dl>
          </div>
          <div class="info-item-2">
            <dl>
              <dt>入院者数:</dt>
              <dd>{{ inpatient_count }}名</dd>
            </dl>
          </div>
        </div>
        <div class="empty-info">
          <div class="info-item">
            <dl>
              <dt>空室情報</dt>
              <dd>{{ vacant_rooms }}空／</dd>
              <dt>全居室数</dt>
              <dd>{{ total_rooms }}空</dd>
            </dl>
          </div>
        </div>
        <div class="empty-info">
          <div class="info-item">
            <div>
              <span class="left">女性{{ female }}名/男性{{ male }}名</span>
              <span class="right">合計{{ total }}名</span>
            </div>
          </div>
          <div class="info-item">
            <dl>
              <dt>平均介護度:</dt>
              <dd>{{ nursing_care_level_average }}</dd>
            </dl>
          </div>
        </div>
      </div>
      <div class="legend-block" v-if="this.total > 0">
        <div class="inner">
          <div class="male">男性</div>
        </div>
        <div class="inner">
          <div class="female">女性</div>
        </div>
      </div>
      <div class="inner-content" v-if="this.total > 0">
        <pie-chart
          :chart-data="datacollection"
          :options="options"
          :height="height"
          :width="width"
        />
      </div>
      <div class="align-right info-last">
        <p class="">最終更新: {{ todayDate }} 時点</p>
      </div>
    </div>
  </section>
</template>

<script>
import Common from '@/plugin/Common'
import * as apiclient from '@/apiclient'
import PieChart from './PieChart'
import moment from 'moment'

export default {
  mixins: [Common],
  props: {
    carehomeId: null
  },
  components: {
    PieChart
  },
  created() {
    this.getResidentsSummary()
    this.getCarehomeRooms()
  },
  data() {
    return {
      datacollection: {},
      options: {
        legend: {
          display: false
        }
      },
      height: 200,
      width: 200,
      total: 0,
      male: 0,
      female: 0,
      nursing_care_level_average: 0,
      total_rooms: 0,
      vacant_rooms: 0,
      inpatient_count: 0,
      occupancy_rate: '0.0',
      todayDate: moment().format('YYYY/MM/DD')
    }
  },
  methods: {
    fillData() {
      this.datacollection = {
        labels: ['男性', '女性'],
        // 表示するデータ
        datasets: [
          {
            data: [this.male, this.female],
            backgroundColor: ['#BDDCF5', '#FFD2D0']
          }
        ]
      }
    },
    getResidentsSummary: function() {
      try {
        var params = {}
        if (!this.isEmpty(this.carehomeId)) {
          params.care_home_id = this.carehomeId
        }
        apiclient.TsunaguwaDxCarehomesApi.getResidentsSummary(
          this.dxCarehomesApiResposeCallback,
          params
        )
      } catch (error) {
        throw error.response
      }
    },
    dxCarehomesApiResposeCallback: function(responseData) {
      this.total = responseData.payload.total
      this.male = responseData.payload.man_total
      this.female = responseData.payload.women_total
      this.nursing_care_level_average =
        Math.floor(
          responseData.payload.nursing_care_level_average * Math.pow(10, 2)
        ) / Math.pow(10, 2)
      this.fillData()
    },
    getCarehomeRooms: function() {
      try {
        var params = {}
        if (!this.isEmpty(this.carehomeId)) {
          params.care_home_id = this.carehomeId
        }
        apiclient.TsunaguwaDxCarehomesApi.getRooms((responseData) => {
          this.vacant_rooms = responseData.payload.vacant_rooms
          this.total_rooms = responseData.payload.total_rooms
          this.inpatient_count = responseData.payload.inpatient_count
          if (!this.isEmpty(responseData.payload.occupancy_rate)) {
            this.occupancy_rate = responseData.payload.occupancy_rate
          }
        }, params)
      } catch (error) {
        throw error.response
      }
    }
  }
}
</script>
