import Vue from 'vue'
import App from './App.vue'
import router from './router'
import cognito from './cognito'
import store from './store'
//import apiclient from './apiclient'
import axios from 'axios'

//FontAwsome
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import vuetify from './plugins/vuetify'
import VueMeta from 'vue-meta'

library.add(fas, fab, far)

Vue.component('font-awesome-icon', FontAwesomeIcon)
//ここまで
Vue.config.productionTip = false

//css、scssの読み込み
require('@/assets/css/ress.min.css')
require('@/assets/sass/style.scss')

Vue.config.productionTip = false

Vue.prototype.$axios = axios

// filters
Vue.filter('limitChars', function(value, limit) {
  // console.log(value)
  if (!value) return ''
  value = value.toString()
  if (value.length > limit) {
    value = `${value.substring(0, limit)}...`
  }
  // console.log(value)
  return value
})

Vue.use(VueMeta)

// registering global components
Vue.component('h-space', require('./components/common/HSpace.vue').default)

new Vue({
  router,
  cognito,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app')
