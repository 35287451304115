<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind:color="color"
          dark
          v-bind="attrs"
          v-on="on"
          rounded
          height="60"
          v-bind:class="className"
        >
          {{ name }}
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ title }}
        </v-card-title>

        <v-card-text>
          {{ message }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="acceptAction">
            OK
          </v-btn>
          <v-btn color="second" text @click="dialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialog: false
    }
  },
  props: {
    name: { type: String },
    title: { type: String },
    color: { type: String },
    message: { type: String },
    className: { type: String }
  },
  methods: {
    acceptAction() {
      this.$emit('acceptAction', false)
      this.dialog = false
    }
  }
}
</script>
