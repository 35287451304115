<template>
  <div>
    <section class="wrapper login-content common-height common-content">
      <div class="wrapper title-block">
        <h2 class="common-title">
          M &amp; A
        </h2>
      </div>
      <div class="banner-btns-block align-center">
        <div class="banner-btns-list-2">
          <a href="https://forms.gle/W2nCB1VDjKCS137W8" target="_blank">
            <div class="pr-img">
              <picture>
                <source
                  srcset="https://static.tsunaguwa-kaigo.com/tsunaguwa/ads/m_and_a_sell.avif"
                  type="image/avif"
                />
                <source
                  srcset="https://static.tsunaguwa-kaigo.com/tsunaguwa/ads/m_and_a_sell.webp"
                  type="image/webp"
                />
                <img
                  src="https://static.tsunaguwa-kaigo.com/tsunaguwa/ads/m_and_a_sell.png"
                />
              </picture></div></a
          >&nbsp;
          <a href="https://forms.gle/W2nCB1VDjKCS137W8" target="_blank">
            <div class="pr-img">
              <picture>
                <source
                  srcset="https://static.tsunaguwa-kaigo.com/tsunaguwa/ads/m_and_a_buy.avif"
                  type="image/avif"
                />
                <source
                  srcset="https://static.tsunaguwa-kaigo.com/tsunaguwa/ads/m_and_a_buy.webp"
                  type="image/webp"
                />
                <img
                  src="https://static.tsunaguwa-kaigo.com/tsunaguwa/ads/m_and_a_buy.png"
                />
              </picture>
            </div>
          </a>
        </div>
        <br />
        <a href="#" onclick="javascript:window.history.back(-1);return false;">
          <v-btn color="#F7B57F">
            <v-icon left dark>
              {{ icons.mdiArrowLeftCircle }}
            </v-icon>
            戻る
          </v-btn>
        </a>
      </div>
    </section>
  </div>
</template>

<script>
import Common from '@/plugin/Common'
import { mdiArrowLeftCircle } from '@mdi/js'

export default {
  mixins: [Common],
  name: 'NonMemberMAndA',
  data: function() {
    return {
      icons: {
        mdiArrowLeftCircle
      }
    }
  },
  created() {
    setTimeout(this.commonHeight, 10)
    // ロード時、リサイズ時にcommon-heightの高さを調整する
    window.addEventListener('load', this.commonHeight)
    window.addEventListener('resize', this.commonHeight)
    setTimeout(this.loadChatbotGeneral, 1000)
  }
}
</script>

<style lang="scss"></style>
